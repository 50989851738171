import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import {
  Grid,
  Typography,
  InputAdornment,
  Box,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';

import { INVALID_BASE_INPUT } from 'constants/errorMessages';
import AutocompleteInput from 'commonComponents/AutocompleteInput';
import { validateBaseInput } from 'helpers/validations';
import CommonButton from 'commonComponents/CommonButton';
import CommonInput from 'commonComponents/CommonInput';
import CommonDialog from 'commonComponents/CommonDialog';

import styles from './styles';
import { validateFee } from '../../helpers/validators';

const ManageCompanyModal = ({
  classes,
  isOpen,
  handleModalToggle,
  formValues,
  handleFormValuesChange,
  isFormDataValid,
  handleSubmit,
  isEditModalMode,
  merchants,
  handleSelectMerchant,
  selectedMerchant,
  handleSelectStore,
  selectedStore,
  stores,
}) => {
  const { title, shopifyName, pathName, fee, merchant, isValid } = formValues;

  return (
    <CommonDialog isOpen={isOpen} onClose={handleModalToggle} style={classes.commonDialog}>
      <Box className={classes.cancelWrapper}>
        <CommonButton
          className={classes.closeButton}
          startIcon={<CloseIcon />}
          label=""
          variant="text"
          size="large"
          onClick={handleModalToggle}
        />
      </Box>
      <Typography variant="h2" align="center" className={classes.modalTitle}>
        {isEditModalMode ? 'Edit Company' : 'Create A Company'}
      </Typography>
      <Grid container spacing={4} className={classes.dialogWrapper}>
        <Grid item xs={12}>
          <CommonInput
            value={title}
            onChange={handleFormValuesChange}
            name="title"
            label="Company Name"
            autoFocus
            error={!validateBaseInput(title)}
            helperText={INVALID_BASE_INPUT}
          />
        </Grid>
        <Grid item xs={12}>
          <CommonInput
            value={pathName}
            onChange={handleFormValuesChange}
            name="pathName"
            label="Path Name"
            error={!validateBaseInput(pathName)}
            helperText={INVALID_BASE_INPUT}
          />
        </Grid>
        <Grid item xs={12}>
          <CommonInput
            value={fee}
            onChange={handleFormValuesChange}
            name="fee"
            label="Fee"
            error={!validateFee(fee)}
            helperText="Enter fee percentages from 0 to 100%"
            InputProps={{
              endAdornment: (
                <InputAdornment classes={{ root: classes.inputAdornment }}>%</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteInput
            autocompleteValue={selectedStore}
            inputValue={shopifyName}
            valuesList={stores}
            getOptionLabel={(option) => option.shopifyName}
            onAutocompleteChange={handleSelectStore}
            onInputChange={handleFormValuesChange}
            name="shopifyName"
            label="Store"
            error={isEmpty(selectedStore)}
            helperText={INVALID_BASE_INPUT}
          />
        </Grid>
        <Grid item xs={12}>
          <AutocompleteInput
            autocompleteValue={selectedMerchant}
            inputValue={merchant}
            valuesList={merchants}
            getOptionLabel={(option) => option.email}
            onAutocompleteChange={handleSelectMerchant}
            onInputChange={handleFormValuesChange}
            name="merchant"
            label="Merchant"
            error={isEmpty(selectedMerchant)}
            helperText={INVALID_BASE_INPUT}
          />
        </Grid>
        {isEditModalMode && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isValid}
                  onChange={handleFormValuesChange}
                  disableRipple
                  name="isValid"
                  color="primary"
                />
              }
              label="Active"
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.submitButton}>
          <CommonButton
            label="Submit"
            variant="contained"
            disabled={!isFormDataValid}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </CommonDialog>
  );
};

ManageCompanyModal.defaultProps = {
  selectedMerchant: {},
  selectedStore: {},
};

ManageCompanyModal.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  merchants: PropTypes.oneOfType([PropTypes.array]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  stores: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFormDataValid: PropTypes.bool.isRequired,
  isEditModalMode: PropTypes.bool.isRequired,
  handleModalToggle: PropTypes.func.isRequired,
  handleSelectStore: PropTypes.func.isRequired,
  selectedMerchant: PropTypes.oneOfType([PropTypes.object]),
  selectedStore: PropTypes.oneOfType([PropTypes.object]),
  handleSelectMerchant: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleFormValuesChange: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    title: PropTypes.string.isRequired,
    pathName: PropTypes.string.isRequired,
    shopifyName: PropTypes.string.isRequired,
    merchant: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    fee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default React.memo(withStyles(styles)(ManageCompanyModal));
