const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    main: black,
  },
  secondary: {
    main: '#F5F5F5',
  },
  error: {
    main: '#DC3B3B',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.25)',
    secondary: 'rgba(0, 0, 0, 0.5)',
    link: '#676f7a',
  },
  background: {
    primary: 'rgba(255, 255, 255, 0.9)',
    secondary: '#F5F5F5',
  },
  copyrightNotice: {
    textTransform: 'uppercase',
  },
};
