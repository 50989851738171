import { createAction } from 'redux-actions';

export const GET_ALL_ACTIVE_COMPANIES = createAction('GET_ALL_ACTIVE_COMPANIES_REQUEST');
export const GET_ALL_ACTIVE_COMPANIES_SUCCESS = createAction('GET_ALL_ACTIVE_COMPANIES_SUCCESS');
export const GET_ALL_ACTIVE_COMPANIES_FAIL = createAction('GET_ALL_ACTIVE_COMPANIES_FAIL');

export const UPDATE_LANDING_CONFIG = createAction('UPDATE_LANDING_CONFIG_REQUEST');
export const UPDATE_LANDING_CONFIG_SUCCESS = createAction('UPDATE_LANDING_CONFIG_SUCCESS');
export const UPDATE_LANDING_CONFIG_FAIL = createAction('UPDATE_LANDING_CONFIG_FAIL');

export const CLEAR_ADMIN_LANDING_TAB_STATUS_MESSAGE = createAction(
  'CLEAR_ADMIN_LANDING_TAB_STATUS_MESSAGE'
);
