import { handleActions } from 'redux-actions';
import { isEmpty } from 'lodash';

import * as actions from '../actions';
import { LINK_STRIPE_ACCOUNT_SUCCESS } from '../../PaymentSettingsTab/actions';
import { socialNetworkExtractor } from '../../../../../utils/socialNetworkExtractor';
import { sortMedia } from '../../../../../utils/sortMedia';

const defaultState = {
  form: {
    title: '',
    pathName: '',
    contacts: [{ name: '', email: '', title: '', phone: '' }],
    year: '',
    city: '',
    description: '',
    landingDescription: '',
    facebook: '',
    instagram: '',
    pinterest: '',
    twitter: '',
    shipping: '',
    returns: '',
    customerService: '',
    _id: '',
    companyTarget: [],
  },
  images: [],
  video: [],
  mediaToRemove: [],
  isLoading: false,
  isSuccess: false,
  message: null,
};

const companyTabReducer = handleActions(
  {
    [actions.CHANGE_FORM_FIELD]: (state, { payload }) => ({
      ...state,
      form: payload,
    }),
    [actions.CHANGE_IMAGES]: (state, { payload }) => ({
      ...state,
      images: payload,
    }),
    [actions.CHANGE_VIDEOS]: (state, { payload }) => ({
      ...state,
      video: payload,
    }),
    [actions.CHANGE_TARGETS]: (state, { payload }) => {
      return {
        ...state,
        form: {
          ...state.form,
          companyTarget: payload,
        },
      };
    },
    [actions.GET_COMPANY_INFO]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.GET_COMPANY_INFO_SUCCESS]: (state, { payload = {} }) => {
      if (payload.response) {
        const {
          _id,
          title,
          pathName,
          city,
          establishedYear,
          socialNetworks,
          serviceLinks,
          contacts,
          media,
          companyTarget,
          stripeId,
          description = '',
          landingDescription = '',
          isStripeOnboardingCompleted,
        } = payload.response;

        const extractedSocialNetworks = socialNetworkExtractor(socialNetworks);
        const extractedServiceLinks = socialNetworkExtractor(serviceLinks);
        const parsedMedia = sortMedia(media);

        return {
          ...state,
          isLoading: false,
          form: {
            _id,
            title,
            year: establishedYear,
            contacts: isEmpty(contacts) ? defaultState.form.contacts : contacts,
            city,
            pathName,
            description,
            landingDescription,
            instagram: extractedSocialNetworks.instagram,
            facebook: extractedSocialNetworks.facebook,
            pinterest: extractedSocialNetworks.pinterest,
            twitter: extractedSocialNetworks.twitter,
            shipping: extractedServiceLinks.shipping,
            returns: extractedServiceLinks.returns,
            customerService: extractedServiceLinks.customerService,
            companyTarget,
          },
          stripeId,
          isStripeOnboardingCompleted,
          images: parsedMedia.images,
          mediaToRemove: defaultState.mediaToRemove,
          video: parsedMedia.video,
        };
      }
    },
    [actions.GET_COMPANY_INFO_FAIL]: (state, { payload = {} }) => ({
      ...state,
      isLoading: false,
      message: payload.response,
    }),
    [actions.UPDATE_MERCHANT_COMPANY]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.UPDATE_MERCHANT_COMPANY_SUCCESS]: (state, { payload = {} }) => {
      if (payload.response) {
        const {
          title,
          city,
          establishedYear,
          pathName,
          contacts,
          description,
          landingDescription,
          socialNetworks,
          serviceLinks,
          companyTarget,
          _id,
        } = payload.response;

        const extractedSocialNetworks = socialNetworkExtractor(socialNetworks);
        const extractedServiceLinks = socialNetworkExtractor(serviceLinks);

        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          message: 'Company was successfully updated!',
          mediaToRemove: defaultState.mediaToRemove,
          form: {
            title,
            contacts,
            year: establishedYear,
            city,
            pathName,
            description,
            landingDescription,
            instagram: extractedSocialNetworks.instagram,
            facebook: extractedSocialNetworks.facebook,
            pinterest: extractedSocialNetworks.pinterest,
            twitter: extractedSocialNetworks.twitter,
            shipping: extractedServiceLinks.shipping,
            returns: extractedServiceLinks.returns,
            customerService: extractedServiceLinks.customerService,
            companyTarget,
            _id,
          },
        };
      }

      return {
        ...state,
      };
    },
    [actions.UPDATE_MERCHANT_COMPANY_FAIL]: (state, { payload = {} }) => ({
      ...state,
      isLoading: false,
      isSuccess: false,
      message: payload.response,
    }),
    [actions.SET_TO_REMOVE_LIST]: (state, { payload = {} }) => ({
      ...state,
      message: payload.response,
      mediaToRemove: [...state.mediaToRemove, payload],
    }),
    [LINK_STRIPE_ACCOUNT_SUCCESS]: (state, { payload = {} }) => ({
      ...state,
      stripeId: (payload.response && payload.response.stripeId) || '',
      isStripeOnboardingCompleted:
        (payload.response && payload.response.isStripeOnboardingCompleted) || '',
    }),
    [actions.CLEAR_COMPANY_TAB_MESSAGE]: (state) => ({
      ...state,
      message: null,
    }),
  },
  defaultState
);

export default companyTabReducer;
