const styles = (theme) => ({
  table: {
    '& .MuiTableCell-root': {
      color: theme.palette.black,
    },
  },
  menuContainer: {
    boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    padding: 20,
    minWidth: 300,
    minHeight: 300,
  },
  menuList: {
    '& .MuiListItem-button:hover': {
      color: theme.palette.black,
      backgroundColor: theme.palette.white,
    },
  },
  dateRangeFilter: {
    display: 'flex',
    marginBottom: 20,
  },
  rangePicker: {
    marginRight: 10,
  },

  statusContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: 20,
  },
  filterActions: {
    display: 'flex',
  },
  cancelButton: {
    width: '100%',
  },
  colorSecondary: {
    '&.Mui-checked': {
      color: theme.palette.black,
    },
  },
});

export default styles;
