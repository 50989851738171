import { handleActions, combineActions } from 'redux-actions';

import {
  GET_LANDING_CONFIG,
  GET_LANDING_CONFIG_SUCCESS,
  GET_LANDING_CONFIG_FAIL,
} from 'config/globalActions';
import * as actions from '../actions';

const defaultState = {
  config: [],
  companies: [],
  isLoading: false,
  successMessage: '',
  errors: '',
};

export default handleActions(
  {
    [combineActions(
      GET_LANDING_CONFIG,
      actions.GET_ALL_ACTIVE_COMPANIES,
      actions.UPDATE_LANDING_CONFIG
    )](state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    [actions.GET_ALL_ACTIVE_COMPANIES_SUCCESS]: (state, { payload = [] }) => ({
      ...state,
      companies: payload.response.companies,
      isLoading: false,
    }),
    [GET_LANDING_CONFIG_SUCCESS]: (state, { payload = [] }) => ({
      ...state,
      config: payload.response,
      isLoading: false,
    }),
    [actions.UPDATE_LANDING_CONFIG_SUCCESS]: (state, { payload = {} }) => {
      const updatedSection = payload.response;

      const configCopy = [...state.config];

      const indexOfUpdatedSection = configCopy.findIndex((item) => item._id === updatedSection._id);

      configCopy[indexOfUpdatedSection] = { ...updatedSection };

      return {
        ...state,
        config: configCopy,
        successMessage: 'Landing page was successfully updated',
        isLoading: false,
      };
    },
    [actions.CLEAR_ADMIN_LANDING_TAB_STATUS_MESSAGE]: (state) => ({
      ...state,
      errors: '',
      successMessage: '',
    }),
    [actions.UPDATE_LANDING_CONFIG_FAIL]: (state) => ({
      ...state,
      errors: 'Something bad happen',
      successMessage: '',
    }),
    [combineActions(GET_LANDING_CONFIG_FAIL, actions.GET_ALL_ACTIVE_COMPANIES_FAIL)](
      state,
      { payload }
    ) {
      return {
        ...state,
        errors: payload.response,
        isLoading: false,
      };
    },
  },
  defaultState
);
