import { handleActions, combineActions } from 'redux-actions';

import * as actions from '../actions';

const defaultState = {
  shippingInfo: [],
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  state: '',
  address: '',
  additionalAddress: '',
  city: '',
  taxes: [],
  zip: '',
  shipments: [],
  totalOrderPrice: {},
  isSuccess: false,
  messages: '',
  isLoading: false,
};

const checkout = handleActions(
  {
    [combineActions(actions.GET_SHIPPING_INFO_REQUEST, actions.CREATE_PAYMENT_INTENT)](state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    [actions.GET_SHIPPING_INFO_SUCCESS]: (state, { payload = {} }) => ({
      ...state,
      isLoading: false,
      shippingInfo: payload && payload.response && payload.response.data,
    }),
    [actions.GET_SHIPPING_INFO_FAIL]: (state, { payload = {} }) => ({
      ...state,
      isLoading: false,
      errors: payload.response,
    }),
    [actions.CHANGE_CHECKOUT_DATA]: (reduxState, { payload = {} }) => ({
      ...reduxState,
      ...payload,
    }),
    [actions.CHANGE_SHIPPING_OPTIONS_FIELDS]: (state, { payload = {} }) => {
      return {
        ...state,
        shipments: payload,
      };
    },
    [actions.SET_TOTAL_ORDER_PRICE]: (state, { payload = {} }) => {
      return {
        ...state,
        totalOrderPrice: payload,
      };
    },
    [actions.CREATE_PAYMENT_INTENT_SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        messages: payload.response.success,
      };
    },
    [actions.CREATE_PAYMENT_INTENT_FAIL]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        messages: payload.response,
      };
    },
    [actions.CLEAR_PAYMENT_RESPONSE_MESSAGES]: (state) => ({
      ...state,
      isSuccess: false,
      messages: '',
    }),
  },
  defaultState
);

export default checkout;
