import React from 'react';

import WorkIcon from '@material-ui/icons/Work';
// import ProductsIcon from '@material-ui/icons/LocalMall';
import OrdersIcon from '@material-ui/icons/Dashboard';
import PaymentIcon from '@material-ui/icons/Payment';
import GroupIcon from '@material-ui/icons/Group';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

import {
  OrdersTabContainer,
  // ProductsTab,
  CompanyTabContainer,
  PaymentSettingsContainer,
} from './components/Tabs/MerchantTabs';

import {
  ClientsTabContainer,
  ManageCompaniesTab,
  LandingTabContainer,
} from './components/Tabs/SuperAdminTabs/index';

export const AREA_WIDTH = 270;

export const MERCHANT_TAB_ROUTES = [
  {
    path: '/company',
    title: 'Company Settings',
    additionalInfo: 'Edit company information',
    Component: CompanyTabContainer,
    icon: <WorkIcon style={{ fill: '#000' }} />,
  },
  {
    path: '/paymentSettings',
    title: 'Payment Settings',
    additionalInfo: 'Setup stripe configuration',
    Component: PaymentSettingsContainer,
    icon: <PaymentIcon style={{ fill: '#000' }} />,
  },
  {
    path: '/orders',
    title: 'Orders',
    additionalInfo: 'Orders statistict',
    Component: OrdersTabContainer,
    icon: <OrdersIcon style={{ fill: '#000' }} />,
  },
  //   {
  //     path: '/products',
  //     title: 'Products',
  //     Component: ProductsTab,
  //     icon: <ProductsIcon style={{ fill: '#000' }} />,
  //   },
];

export const SUPER_ADMIN_TAB_ROUTES = [
  {
    path: '/merchants',
    title: 'Merchant Contacts',
    additionalInfo: 'Manage merchants',
    Component: ClientsTabContainer,
    icon: <GroupIcon style={{ fill: '#000' }} />,
  },
  {
    path: '/companies',
    title: 'Companies',
    additionalInfo: 'Manage companies',
    Component: ManageCompaniesTab,
    icon: <WorkIcon style={{ fill: '#000' }} />,
  },
  {
    path: '/orders',
    title: 'Orders',
    additionalInfo: 'Orders statistict',
    Component: OrdersTabContainer,
    icon: <OrdersIcon style={{ fill: '#000' }} />,
  },
  {
    path: '/landing',
    title: 'Landing',
    additionalInfo: 'Configure landing page',
    Component: LandingTabContainer,
    icon: <SettingsApplicationsIcon style={{ fill: '#000' }} />,
  },
];
