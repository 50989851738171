import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import ROUTES from 'routes';
import { CHANGE_PEOPLE_FILTER } from 'config/globalActions';
import { PEOPLE_CATEGORIES } from '../../../constants/peopleCategories';
import Header from '../components/Header';

const HeaderContainer = ({
  handleShowLoginModal,
  handleShowRegisterModal,
  searchInput,
  onChangeSearchInput,
  handleShowFinder,
  showFinder,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { isAuth } = useSelector((state) => state.user);
  const { firstName } = useSelector((state) => state.settings);
  const { orderList } = useSelector((state) => state.cart);
  const { companyInfo } = useSelector((state) => state.company);
  const { filterByPeopleCategory } = useSelector((state) => state.global);

  const handleClickProfileButton = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleOpenMainMenu = useCallback((isOpen) => {
    setIsFilterMenuOpen(isOpen);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleOpenPage = useCallback(
    (openLink) => {
      handleOpenMainMenu(false);
      const test = setTimeout(() => {
        openLink();
      }, 50);
      return () => clearTimeout(test);
    },
    [handleOpenMainMenu]
  );
  const showModalHandler = useCallback(
    (showModal) => {
      handleCloseMenu();
      if (showModal) {
        showModal();
      }
    },
    [handleCloseMenu]
  );
  const handlePeopleFilterChange = useCallback(
    (option) => {
      dispatch(CHANGE_PEOPLE_FILTER(option));
    },
    [dispatch]
  );

  const productsQuantity = useMemo(() => {
    if (orderList) {
      return Object.entries(orderList).flatMap((order) => order[1].products).length;
    }

    return 0;
  }, [orderList]);

  const isPath = useCallback((path) => location.pathname.includes(path), [location]);

  useEffect(() => {
    if (isPath(ROUTES.COMPANY)) {
      const { companyTarget = [] } = companyInfo;

      if (companyTarget.length === 1) {
        handlePeopleFilterChange(companyTarget[0]);
      }
    }
  }, [companyInfo, handlePeopleFilterChange, isPath]);

  const companyTarget = useMemo(() => {
    if (isPath(ROUTES.COMPANY)) {
      return companyInfo.companyTarget || [];
    }
    return [PEOPLE_CATEGORIES.WOMEN, PEOPLE_CATEGORIES.MEN];
  }, [companyInfo, isPath]);

  const withoutFavoritesIcon = useMemo(
    () => location.pathname === ROUTES.HOME || isPath(ROUTES.BRANDS_LIST) || isPath(ROUTES.COMPANY),
    [isPath, location]
  );

  return (
    <Header
      handleShowLoginModal={handleShowLoginModal}
      handleShowRegisterModal={handleShowRegisterModal}
      handleClickProfileButton={handleClickProfileButton}
      handleOpenMainMenu={handleOpenMainMenu}
      showFinder={showFinder}
      setShowFinder={handleShowFinder}
      productsQuantity={productsQuantity}
      isAuth={isAuth}
      firstName={firstName}
      anchorEl={anchorEl}
      handleCloseMenu={handleCloseMenu}
      showModalHandler={showModalHandler}
      isFilterMenuOpen={isFilterMenuOpen}
      handleOpenPage={handleOpenPage}
      searchInput={searchInput}
      onChange={onChangeSearchInput}
      filterByPeopleCategory={filterByPeopleCategory}
      handlePeopleFilterChange={handlePeopleFilterChange}
      companyTarget={companyTarget}
      withoutFavoritesIcon={withoutFavoritesIcon}
    />
  );
};

HeaderContainer.propTypes = {
  handleShowLoginModal: PropTypes.func.isRequired,
  handleShowRegisterModal: PropTypes.func.isRequired,
  handleShowFinder: PropTypes.func.isRequired,
  showFinder: PropTypes.bool.isRequired,
  searchInput: PropTypes.string.isRequired,
  onChangeSearchInput: PropTypes.func.isRequired,
};

export default React.memo(HeaderContainer);
