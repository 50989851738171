import { PEOPLE_CATEGORIES } from '../constants/peopleCategories';

export const filterTargetGenerator = (target = '') => {
  const { ALL } = PEOPLE_CATEGORIES;

  if (target === ALL) {
    const arrayOfTargets = Object.entries(PEOPLE_CATEGORIES).reduce((targets, category) => {
      if (category[1] !== ALL) {
        targets.push(category[1]);
      }

      return targets;
    }, []);

    return arrayOfTargets.join(',');
  }

  return target;
};
