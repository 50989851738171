const styles = (theme) => ({
  breadcrumbs: {
    marginBottom: 15,
  },
  actions: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('400')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  nextStepButton: {
    maxWidth: 250,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
    },
    [theme.breakpoints.down('400')]: {
      marginTop: 10,
    },
  },
  divider: {
    marginTop: 25,
    marginBottom: 20,
  },
  termsConditions: {
    marginLeft: 10,
  },
});

export default styles;
