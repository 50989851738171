import React, { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { startCase } from 'lodash';

import { validateBaseInput } from 'commonComponents/RegisterModal/helpers/validations';
import CommonButton from 'commonComponents/CommonButton';
import CommonSelect from 'commonComponents/CommonSelect';
import { COMPANY_POSITIONS } from '../../constants';

import styles from './styles';

const LandingSection = ({ classes, title, companies, config, handleSaveChanges }) => {
  const [formValues, setFormValues] = useState({
    firstPosition: '',
    secondPosition: '',
    thirdPosititon: '',
  });

  const getConfigByPosition = useCallback(
    (position) => {
      const foundedConfig = config.find((item) => item.position === position);

      return foundedConfig ? foundedConfig?.companyInfo?.title : '';
    },
    [config]
  );

  useEffect(() => {
    if (config.length > 0) {
      setFormValues({
        firstPosition: getConfigByPosition(COMPANY_POSITIONS.FIRST),
        secondPosition: getConfigByPosition(COMPANY_POSITIONS.SECOND),
        thirdPosititon: getConfigByPosition(COMPANY_POSITIONS.THIRD),
      });
    }
  }, [config, getConfigByPosition]);

  const isSectionValid = useMemo(
    () =>
      Object.keys(formValues).every(
        (key) => validateBaseInput(formValues[key]) && companies.includes(formValues[key])
      ),
    [formValues, companies]
  );

  const handleFormValuesChange = useCallback(
    (event) => setFormValues({ ...formValues, [event.target.name]: event.target.value }),
    [formValues]
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography align="center" variant="h2">
          {startCase(title)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CommonSelect
          name="firstPosition"
          label="First Position"
          value={formValues.firstPosition}
          onChange={handleFormValuesChange}
          items={companies}
        />
      </Grid>
      <Grid item xs={12}>
        <CommonSelect
          name="secondPosition"
          label="Second Position"
          value={formValues.secondPosition}
          onChange={handleFormValuesChange}
          items={companies}
        />
      </Grid>
      <Grid item xs={12}>
        <CommonSelect
          name="thirdPosititon"
          label="Third Position"
          value={formValues.thirdPosititon}
          onChange={handleFormValuesChange}
          items={companies}
        />
      </Grid>
      <Grid item xs={12}>
        <CommonButton
          label="Save Changes"
          variant="contained"
          onClick={() => handleSaveChanges(title, formValues)}
          isDisabled={!isSectionValid}
        />
      </Grid>
    </>
  );
};

LandingSection.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  companies: PropTypes.oneOfType([PropTypes.array]).isRequired,
  config: PropTypes.oneOfType([PropTypes.array]).isRequired,
  title: PropTypes.string.isRequired,
  handleSaveChanges: PropTypes.func.isRequired,
};

export default React.memo(withStyles(styles)(LandingSection));
