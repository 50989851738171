import { createAction } from 'redux-actions';

export const GET_FILTERED_PRODUCTS = createAction('GET_FILTERED_PRODUCTS_REQUEST');
export const GET_FILTERED_PRODUCTS_SUCCESS = createAction('GET_FILTERED_PRODUCTS_SUCCESS');
export const GET_FILTERED_PRODUCTS_FAIL = createAction('GET_FILTERED_PRODUCTS_FAIL');

export const GET_ALL_PRODUCTS_BY_CATEGORIES = createAction(
  'GET_ALL_PRODUCTS_BY_CATEGORIES_REQUEST'
);
export const GET_ALL_PRODUCTS_BY_CATEGORIES_SUCCESS = createAction(
  'GET_ALL_PRODUCTS_BY_CATEGORIES_SUCCESS'
);
export const GET_ALL_PRODUCTS_BY_CATEGORIES_FAIL = createAction(
  'GET_ALL_PRODUCTS_BY_CATEGORIES_FAIL'
);

export const SELECT_CATEGORY = createAction('SELECT_CATEGORY');
export const UPDATE_FILTERED_PRODUCTS = createAction('UPDATE_FILTERED_PRODUCTS');
export const UPDATE_COMPANY_GROUPED_PRODUCTS = createAction('UPDATE_COMPANY_GROUPED_PRODUCTS');
