import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import {
  Grid,
  Box,
  Tabs,
  Tab,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  withStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import ImageGallery from 'react-image-gallery';
import CloseIcon from '@material-ui/icons/Close';
import { kebabCase, isEmpty } from 'lodash';

import CommonSizePicker from 'commonComponents/CommonSizePicker';
import CommonDialog from 'commonComponents/CommonDialog';
import { changeImageSize } from 'helpers/changeImageSize';
import { stripHtml } from 'helpers/stripHtml';
import MobileBreadcrumbs from 'commonComponents/MobileBreadcrumbs';
import CommonLayoutContainer from 'commonComponents/layouts/CommonLayout/containers/CommonLayoutContainer';
import CommonCarousel from 'commonComponents/CommonCarousel';
import CommonButton from 'commonComponents/CommonButton';
import ProductCard from 'commonComponents/ProductCard/components/ProductCard';
import CommonColorPicker from 'commonComponents/CommonColorPicker';
import { useRouteToGo } from 'helpers/hooks/useRouteToGo';
import { ReactComponent as AddIcon } from 'static/images/add-to-cart.svg';
import ROUTES from 'routes';
import TabPanel from '../TabPanel';

import 'react-image-gallery/styles/css/image-gallery.css';
import styles from './styles.js';

const ProductPage = ({
  classes,
  formData,
  onChange,
  selectedTab,
  handleChangeSelectedTab,
  productInfo,
  isLoading,
  currentProductVariant,
  availableProductSizes,
  recentlyViewedProducts,
  handleClickProductCard,
  handleAddToCart,
}) => {
  const { colorRadio, sizeRadio } = formData;
  const { images = [], options = [] } = productInfo;

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const goToRecentlyViewedPage = useRouteToGo(ROUTES.RECENTLY_VIEWED_PAGE);

  const [isFullscreenMode, setFullscreenMode] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleOpenFullscreenImage = useCallback((index) => {
    setCurrentImageIndex(index);
    setFullscreenMode(true);
  }, []);

  return (
    <CommonLayoutContainer isLoading={isLoading}>
      <Grid container spacing={4}>
        {isMobileView && (
          <Grid item xs={12}>
            <MobileBreadcrumbs currentPage={productInfo.title} />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={6}>
          {!isMobileView ? (
            <ImageGallery
              items={images.map((image) => {
                return {
                  original: image.src,
                  thumbnail: changeImageSize(image.src, 100, 100),
                };
              })}
              showFullscreenButton={isMobileView}
              showPlayButton={false}
              showNav={false}
              showThumbnails={!isMobileView}
              slideDuration={0}
              thumbnailPosition="left"
              additionalClass={classes.customImageGallery}
            />
          ) : (
            <CommonCarousel style={classes.carousel}>
              {images.map((image, index) => (
                <img
                  src={image.src}
                  alt="productImage"
                  className={classes.productImage}
                  onClick={() => handleOpenFullscreenImage(index)}
                />
              ))}
            </CommonCarousel>
          )}
          <CommonDialog
            isOpen={isFullscreenMode}
            style={classes.commomDialog}
            fullScreen
            onClose={() => setFullscreenMode(false)}
          >
            <CommonButton
              className={classes.closeButton}
              startIcon={<CloseIcon />}
              label=""
              variant="text"
              size="large"
              onClick={() => setFullscreenMode(false)}
            />
            <Grid
              container
              xs={12}
              alignItems="center"
              className={classes.originalProductImageWrapper}
            >
              <Grid item xs={12}>
                <img
                  src={images[currentImageIndex] && images[currentImageIndex].src}
                  alt="productImage"
                  className={classes.originalProductImage}
                />
              </Grid>
            </Grid>
          </CommonDialog>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.productInfo}>
          <Typography variant="h4">{productInfo.companyName}</Typography>
          <Typography variant="h2" className={classes.productName}>
            {productInfo.title}
          </Typography>
          <Box>
            {productInfo.isFinalSale && (
              <Typography className={classes.finalSale} variant="h4" color="error">
                Final Sale
              </Typography>
            )}
            {(currentProductVariant.compareAtPrice > currentProductVariant.mortarPrice ||
              currentProductVariant.marketPrice > currentProductVariant.mortarPrice) && (
              <Typography className={classes.oldPrice} variant="h2">
                ${currentProductVariant.compareAtPrice || currentProductVariant.marketPrice}
              </Typography>
            )}
            {(currentProductVariant.mortarPrice || currentProductVariant.marketPrice) && (
              <Typography className={classes.newPrice} variant="h2" color="textPrimary">
                ${currentProductVariant.mortarPrice || currentProductVariant.marketPrice}
              </Typography>
            )}
          </Box>
          {options.find((option) => option.name === 'Color') && (
            <FormControl component="fieldset" className={classes.colorPickerContainer}>
              <FormLabel component="legend" className={classes.colorPickerLabel}>
                Select a Сolor
              </FormLabel>
              <RadioGroup
                className={classes.colorPicker}
                aria-label="color"
                name="colorRadio"
                value={colorRadio}
                onChange={onChange}
              >
                {options
                  .find((option) => option.name === 'Color')
                  .values.map((color) => (
                    <CommonColorPicker key={color} value={color} selectedValue={colorRadio} />
                  ))}
              </RadioGroup>
            </FormControl>
          )}
          {options.find((option) => option.name === 'Size') && (
            <Box className={classes.sizePickerContainer}>
              <Typography variant="body1" className={classes.sizePickerLabel}>
                Select a Size
              </Typography>
              <RadioGroup
                className={classes.colorPicker}
                aria-label="size"
                name="sizeRadio"
                value={sizeRadio}
                onChange={onChange}
              >
                {options
                  .find((option) => option.name === 'Size')
                  .values.map((size) => {
                    if (availableProductSizes.includes(size)) {
                      return <CommonSizePicker key={size} value={size} selectedValue={sizeRadio} />;
                    }
                    return (
                      <CommonSizePicker
                        key={size}
                        disabled
                        value={size}
                        selectedValue={sizeRadio}
                      />
                    );
                  })}
              </RadioGroup>
            </Box>
          )}
          <CommonButton
            label="Add to Cart"
            className={classes.addToCartButton}
            startIcon={<AddIcon />}
            variant="contained"
            isDisabled={
              isEmpty(currentProductVariant) || currentProductVariant.inventoryQuantity === 0
            }
            onClick={handleAddToCart}
          />
          <Box>
            <Tabs
              value={selectedTab}
              onChange={handleChangeSelectedTab}
              TabIndicatorProps={{ children: <span /> }}
              centered
            >
              <Tab disableRipple id={`tabName-${uuid()}`} label="Product Details" />
            </Tabs>
            <TabPanel key={uuid()} value={selectedTab} index={0}>
              {stripHtml(productInfo.bodyHtml)}
            </TabPanel>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.recentlyViewed}>
          <CommonCarousel
            title="Recently Viewed"
            withViewAllButton
            viewAllClickHandler={goToRecentlyViewedPage}
          >
            {recentlyViewedProducts.map((product) => (
              <ProductCard
                key={product.id}
                style={classes.item}
                image={product.image ? product.image.src : ''}
                product={product}
                handleClick={() => handleClickProductCard(kebabCase(product.title), product._id)}
              />
            ))}
          </CommonCarousel>
        </Grid>
      </Grid>
    </CommonLayoutContainer>
  );
};

ProductPage.defaultProps = {
  productInfo: {},
  currentProductVariant: {},
  availableProductSizes: [],
  recentlyViewedProducts: [],
};

ProductPage.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  formData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  recentlyViewedProducts: PropTypes.oneOfType([PropTypes.array]),
  currentProductVariant: PropTypes.oneOfType([PropTypes.object]),
  productInfo: PropTypes.oneOfType([PropTypes.object]),
  availableProductSizes: PropTypes.oneOfType([PropTypes.array]),
  handleClickProductCard: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
  handleChangeSelectedTab: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default React.memo(withStyles(styles)(ProductPage));
