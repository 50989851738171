const styles = (theme) => ({
  container: {
    maxWidth: 600,
    padding: 25,
  },
  title: {
    marginBottom: 25,
    color: theme.palette.black,
  },
});

export default styles;
