import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';

import { DEFAULT_INPUT_LENGTH, ADDRESS_INPUT_LENGTH } from 'constants/inputOptions';
import { INVALID_BASE_INPUT, INVALID_EMAIL, INVALID_PHONE } from 'constants/errorMessages';
import { validateBaseInput, validatePhone, validateEmail } from 'helpers/validations';
import CommonInput from 'commonComponents/CommonInput';
import CommonSelect from 'commonComponents/CommonSelect';
import { CHANGE_CHECKOUT_DATA } from '../../../actions';

import styles from './styles';

const PersonalInformationStep = ({ classes }) => {
  const dispatch = useDispatch();

  const {
    firstName,
    lastName,
    phone,
    email,
    shippingInfo,
    state,
    address,
    additionalAddress,
    city,
    zip,
  } = useSelector((reduxState) => reduxState.checkout);
  const { isAuth } = useSelector((reduxState) => reduxState.user);
  const userSelector = useSelector((reduxState) => reduxState.settings);
  const { orderList } = useSelector((reduxState) => reduxState.cart);

  const setPersonalInfo = useCallback(() => {
    if (isAuth) {
      dispatch(
        CHANGE_CHECKOUT_DATA({
          firstName: firstName || userSelector.firstName,
          lastName: lastName || userSelector.lastName,
          email: email || userSelector.email,
          phone: phone || userSelector.phone,
        })
      );
    }
  }, [dispatch, userSelector, firstName, isAuth, lastName, phone, email]);

  useEffect(setPersonalInfo, []);

  const states = useMemo(() => {
    if (
      shippingInfo &&
      shippingInfo[0] &&
      shippingInfo[0].countries &&
      shippingInfo[0].countries[0].provinces
    ) {
      return shippingInfo[0].countries[0].provinces.map((province) => province.name);
    }

    return [];
  }, [shippingInfo]);

  const storesOjects = useMemo(() => {
    const arrayedOrderList = Object.entries(orderList);

    return arrayedOrderList.map((item) => ({ storeId: item[1].storeId }));
  }, [orderList]);

  const handleFieldsChange = useCallback(
    (event) => {
      const { name, value } = event.target;

      const fieldsToUpdate = {};

      if (name === 'state') {
        const taxesArray = storesOjects.map((storeObject) => {
          const foundShipping = shippingInfo.find(
            (shipping) => shipping.storeId === storeObject.storeId
          );

          const { provinces } = foundShipping.countries[0];

          const foundTax = provinces.find((province) => province.name === value).tax;

          return {
            ...storeObject,
            tax: foundTax,
          };
        });

        fieldsToUpdate.taxes = taxesArray;
      }
      fieldsToUpdate[name] = value;

      dispatch(CHANGE_CHECKOUT_DATA(fieldsToUpdate));
    },
    [storesOjects, dispatch, shippingInfo]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <CommonInput
          label="First Name"
          name="firstName"
          value={firstName}
          onChange={handleFieldsChange}
          error={!validateBaseInput(firstName)}
          helperText={INVALID_BASE_INPUT}
          inputProps={{
            maxLength: DEFAULT_INPUT_LENGTH,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CommonInput
          label="Last Name"
          name="lastName"
          value={lastName}
          onChange={handleFieldsChange}
          error={!validateBaseInput(lastName)}
          helperText={INVALID_BASE_INPUT}
          inputProps={{
            maxLength: DEFAULT_INPUT_LENGTH,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CommonInput
          label="Email"
          name="email"
          type="email"
          value={email}
          onChange={handleFieldsChange}
          error={(email !== '', !validateEmail(email))}
          helperText={INVALID_EMAIL}
          inputProps={{
            maxLength: DEFAULT_INPUT_LENGTH,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CommonInput
          label="Phone (optional)"
          name="phone"
          value={phone}
          onChange={handleFieldsChange}
          error={!validatePhone(phone)}
          helperText={INVALID_PHONE}
          inputProps={{
            maxLength: DEFAULT_INPUT_LENGTH,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CommonSelect
          label="State"
          name="state"
          items={states}
          value={state}
          onChange={handleFieldsChange}
          error={!validateBaseInput(state)}
          helperText={INVALID_BASE_INPUT}
          inputProps={{
            maxLength: DEFAULT_INPUT_LENGTH,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CommonInput
          label="Zip Code"
          name="zip"
          type="number"
          value={zip}
          onChange={handleFieldsChange}
          error={!validateBaseInput(zip)}
          helperText={INVALID_BASE_INPUT}
          inputProps={{
            maxLength: DEFAULT_INPUT_LENGTH,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CommonInput
          label="Address"
          name="address"
          value={address}
          onChange={handleFieldsChange}
          error={!validateBaseInput(address)}
          helperText={INVALID_BASE_INPUT}
          inputProps={{
            maxLength: ADDRESS_INPUT_LENGTH,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CommonInput
          label="Apartment, suite, etc (optional)"
          name="additionalAddress"
          value={additionalAddress}
          onChange={handleFieldsChange}
          inputProps={{
            maxLength: DEFAULT_INPUT_LENGTH,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CommonInput
          label="City"
          name="city"
          value={city}
          onChange={handleFieldsChange}
          error={!validateBaseInput(city)}
          helperText={INVALID_BASE_INPUT}
          inputProps={{
            maxLength: DEFAULT_INPUT_LENGTH,
          }}
        />
      </Grid>
    </Grid>
  );
};

PersonalInformationStep.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default React.memo(withStyles(styles)(PersonalInformationStep));
