const styles = () => ({
  gridWrapper: {
    maxWidth: 400,
  },
  divider: {
    height: 350,
    margin: 'auto',
  },
});

export default styles;
