import { createAction } from 'redux-actions';

export const GET_COMPANIES_BY_IDS = createAction('GET_COMPANIES_BY_IDS_REQUEST');
export const GET_COMPANIES_BY_IDS_SUCCESS = createAction('GET_COMPANIES_BY_IDS_SUCCESS');
export const GET_COMPANIES_BY_IDS_FAIL = createAction('GET_COMPANIES_BY_IDS_FAIL');

export const GET_COMPANY_PRODUCTS = createAction('GET_COMPANY_PRODUCTS_REQUEST');
export const GET_COMPANY_PRODUCTS_SUCCESS = createAction('GET_COMPANY_PRODUCTS_SUCCESS');
export const GET_COMPANY_PRODUCTS_FAIL = createAction('GET_COMPANY_PRODUCTS_FAIL');

export const UNFOLLOW_COMPANY = createAction('UNFOLLOW_COMPANY');
export const UPDATE_DEFAULT_FOLLOWS = createAction('UPDATE_DEFAULT_FOLLOWS');
