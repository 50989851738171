import * as companyActions from 'modules/CompanyPage/actions/index';
import * as companyAPI from 'modules/CompanyPage/api/index';

import * as productActions from 'modules/ProductPage/actions/index';
import * as productAPI from 'modules/ProductPage/api/index';

import * as homeActions from 'modules/HomePage/actions/index';
import * as homeAPI from 'modules/HomePage/api/index';

import * as globalActions from 'config/globalActions';
import * as globalAPI from 'config/globalApiCalls';

import * as favoritesActions from 'modules/FavoritesPage/actions/index';
import * as favoritesAPI from 'modules/FavoritesPage/api/index';

import * as recentlyViewedActions from 'modules/RecentlyViewedPage/actions/index';
import * as recentlyViewedAPI from 'modules/RecentlyViewedPage/api/index';

import * as categoriesActions from 'modules/CategoriesPage/actions/index';
import * as categoriesAPI from 'modules/CategoriesPage/api/index';

import * as registerAction from '../commonComponents/RegisterModal/actions/index';
import * as registerAPI from '../commonComponents/RegisterModal/api/index';

import * as commonLayoutActions from '../commonComponents/layouts/CommonLayout/actions/index';
import * as commonLayoutAPI from '../commonComponents/layouts/CommonLayout/api/index';

import * as loginActions from '../commonComponents/LoginModal/actions/index';
import * as loginAPI from '../commonComponents/LoginModal/api/index';

import * as companyTabActions from '../modules/AdminPage/components/Tabs/MerchantTabs/CompanyTab/actions/index';
import * as companyTabAPI from '../modules/AdminPage/components/Tabs/MerchantTabs/CompanyTab/api/index';

import * as ordersTabActions from '../modules/AdminPage/components/Tabs/MerchantTabs/OrdersTab/actions/index';
import * as ordersTabAPI from '../modules/AdminPage/components/Tabs/MerchantTabs/OrdersTab/api/index';

import * as paymentSettingsTabActions from '../modules/AdminPage/components/Tabs/MerchantTabs/PaymentSettingsTab/actions';
import * as paymentSettingsTabAPI from '../modules/AdminPage/components/Tabs/MerchantTabs/PaymentSettingsTab/api';

import * as userActions from '../modules/ProfilePage/components/Tabs/ProfileTab/actions';
import * as userAPI from '../modules/ProfilePage/components/Tabs/ProfileTab/api';
import * as resetPasswordActions from '../commonComponents/ResetPasswordModal/actions';
import * as resetPasswordApi from '../commonComponents/ResetPasswordModal/api';

import * as checkoutActions from '../modules/CheckoutModule/actions';
import * as checkoutAPI from '../modules/CheckoutModule/api';

import * as cartActions from '../modules/CartPage/actions';
import * as cartAPI from '../modules/CartPage/api';

import * as clientsTabActions from '../modules/AdminPage/components/Tabs/SuperAdminTabs/ClientsTab/actions';
import * as clientsTabAPI from '../modules/AdminPage/components/Tabs/SuperAdminTabs/ClientsTab/api';

import * as manageCompaniesTabActions from '../modules/AdminPage/components/Tabs/SuperAdminTabs/ManageCompaniesTab/actions';
import * as manageCompaniesTabAPI from '../modules/AdminPage/components/Tabs/SuperAdminTabs/ManageCompaniesTab/api';

import * as landingTabActions from '../modules/AdminPage/components/Tabs/SuperAdminTabs/LandingTab/actions';
import * as landingTabAPI from '../modules/AdminPage/components/Tabs/SuperAdminTabs/LandingTab/api';

import * as ordersActions from '../modules/ProfilePage/components/Tabs/OrdersTab/actions';
import * as ordersAPI from '../modules/ProfilePage/components/Tabs/OrdersTab/api';

const apiCallsMapping = (action) => {
  const mapping = {
    [registerAction.SIGN_UP]: registerAPI.signUp,
    [loginActions.LOGIN]: loginAPI.signIn,

    [globalActions.GET_CATEGORIES]: globalAPI.getCategories,
    [globalActions.GET_LANDING_CONFIG]: globalAPI.getLandingConfig,

    [companyTabActions.GET_COMPANY_INFO]: companyTabAPI.getCompanyInformation,
    [companyTabActions.UPDATE_MERCHANT_COMPANY]: companyTabAPI.updateCompanyInformation,

    [ordersTabActions.GET_COMPANY_ORDERS]: ordersTabAPI.getCompanyOrders,
    [ordersTabActions.GET_ORDERS_SUMMARY_INFO]: ordersTabAPI.getOrdersSummaryInfo,

    [clientsTabActions.GET_MERCHANTS]: clientsTabAPI.getMerchantsAccounts,
    [clientsTabActions.DELETE_MERCHANT]: clientsTabAPI.deleteMerchant,
    [clientsTabActions.CREATE_MERCHANT]: clientsTabAPI.createMerchant,

    [manageCompaniesTabActions.GET_MERCHANT_BY_ID]: manageCompaniesTabAPI.getMerchantById,
    [manageCompaniesTabActions.GET_ALL_MERCHANTS]: manageCompaniesTabAPI.getAllMerchants,
    [manageCompaniesTabActions.GET_ALL_STORES]: manageCompaniesTabAPI.getAllStores,
    [manageCompaniesTabActions.GET_ALL_COMPANIES]: manageCompaniesTabAPI.getAllCompanies,
    [manageCompaniesTabActions.CREATE_COMPANY]: manageCompaniesTabAPI.createCompany,
    [manageCompaniesTabActions.UPDATE_COMPANY_INFO]: manageCompaniesTabAPI.updateCompany,
    [manageCompaniesTabActions.DELETE_COMPANY]: manageCompaniesTabAPI.deleteCompany,

    [landingTabActions.GET_ALL_ACTIVE_COMPANIES]: landingTabAPI.getAllActiveCompanies,
    [landingTabActions.UPDATE_LANDING_CONFIG]: landingTabAPI.updateLandingConfig,

    [paymentSettingsTabActions.LINK_STRIPE_ACCOUNT]: paymentSettingsTabAPI.linkStripeAccount,
    [paymentSettingsTabActions.UNLINK_STRIPE_ACCOUNT]: paymentSettingsTabAPI.unLinkStripeAccount,

    [companyActions.GET_COMPANY]: companyAPI.getCompanyInfo,
    [companyActions.GET_FILTERED_COMPANY_PRODUCTS]: companyAPI.getCompanyProducts,
    [companyActions.GET_PRODUCTS_GROUPED_BY_CATEGORIES]: companyAPI.getProductsByCategories,

    [productActions.GET_PRODUCT_INFO]: productAPI.getProductInfo,

    [recentlyViewedActions.GET_PRODUCTS_INFO]: recentlyViewedAPI.getProductsInfo,

    [commonLayoutActions.GET_PRODUCTS_BY_QUERY]: commonLayoutAPI.getProductsByQuery,
    [commonLayoutActions.GET_PRODUCTS_BY_CATEGORIES]: commonLayoutAPI.getProductsByCategories,

    [homeActions.GET_ACTIVE_COMPANIES_BY_GENDER]: homeAPI.getActiveCompaniesByGender,

    [userActions.GET_USER_INFO]: userAPI.getUserInformation,
    [userActions.UPDATE_USER_PROFILE]: userAPI.updateUserProfile,
    [resetPasswordActions.RESET_PASSWORD]: resetPasswordApi.resetPassword,

    [favoritesActions.GET_COMPANIES_BY_IDS]: favoritesAPI.getCompaniesByIds,
    [favoritesActions.GET_COMPANY_PRODUCTS]: favoritesAPI.getCompanyProducts,

    [categoriesActions.GET_FILTERED_PRODUCTS]: categoriesAPI.getProductsByQuery,
    [categoriesActions.GET_ALL_PRODUCTS_BY_CATEGORIES]: categoriesAPI.getProductsByCategories,

    [checkoutActions.GET_SHIPPING_INFO_REQUEST]: checkoutAPI.getShippingInformation,
    [checkoutActions.CREATE_PAYMENT_INTENT]: checkoutAPI.createOrderPayout,

    [cartActions.SYNCHRONIZE_CART_ITEMS]: cartAPI.synchronizeCartProducts,
    [cartActions.SYNCHRONIZE_COMPANY_INFO]: cartAPI.synchronizeCompaniesInfo,

    [ordersActions.GET_USER_ORDERS]: ordersAPI.getUserOrders,
  };

  if (!mapping.hasOwnProperty(action.type)) {
    throw new Error('Not mapped action');
  }

  return mapping[action.type];
};

export default apiCallsMapping;
