const ROUTES = {
  HOME: '/',
  COMPANY: '/shop',
  CATEGORIES: '/categories',
  PRODUCT_PAGE: '/product',
  RECENTLY_VIEWED_PAGE: '/recently-viewed',
  CART: '/cart',
  FAVORITES: '/favorites',
  PROFILE: '/profile',
  BRANDS_LIST: '/brands-list',
  PROFILE_SETTIGNS_PAGE: '/profile/settings',
  ORDERS_PAGE: '/profile/orders',
  ADMIN_PAGE: '/admin',
};

export default ROUTES;
