import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createBlacklistFilter } from 'redux-persist-transform-filter';

import register from '../commonComponents/RegisterModal/reducers';
import user from '../commonComponents/LoginModal/reducers';
import commonLayout from '../commonComponents/layouts/CommonLayout/reducers';

// TODO: combine all admin tabs in admin reducer
import adminPage from '../modules/AdminPage/reducers';

import company from '../modules/CompanyPage/reducers';
import favorites from '../modules/FavoritesPage/reducers';
import homePage from '../modules/HomePage/reducers';
import product from '../modules/ProductPage/reducers';
import cart from '../modules/CartPage/reducers';
import categories from '../modules/CategoriesPage/reducers';
import recentlyViewed from '../modules/RecentlyViewedPage/reducers';
import settings from '../modules/ProfilePage/components/Tabs/ProfileTab/reducers';
import orders from '../modules/ProfilePage/components/Tabs/OrdersTab/reducers';
import global from '../config/globalReducer';
import checkout from '../modules/CheckoutModule/reducers';
import superAdminTabs from '../modules/AdminPage/components/Tabs/SuperAdminTabs/combinedReducer';
import merchantTabs from '../modules/AdminPage/components/Tabs/MerchantTabs/combineReducer';
import resetPassword from '../commonComponents/ResetPasswordModal/reducers';

const userBlacklistedFields = createBlacklistFilter('userInformation', ['errors']);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'adminPage'],
  transforms: [userBlacklistedFields],
};

const persistRecentlyViewedConfig = {
  key: 'recentlyViewed',
  storage,
  whitelist: ['products'],
};

const persistUserConfig = {
  key: 'user',
  storage,
};

const persistGlobalConfig = {
  key: 'global',
  storage,
  whitelist: ['filterByPeopleCategory'],
};

const rootReducer = combineReducers({
  register,
  user: persistReducer(persistUserConfig, user),
  resetPassword,
  company,
  product,
  adminPage,
  homePage,
  cart,
  commonLayout,
  settings,
  orders,
  favorites,
  checkout,
  categories,
  superAdminTabs,
  merchantTabs,
  global: persistReducer(persistGlobalConfig, global),
  recentlyViewed: persistReducer(persistRecentlyViewedConfig, recentlyViewed),
});

export default persistReducer(persistConfig, rootReducer);
