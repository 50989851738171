import { isPlainObject } from 'lodash';

export const requestQueryCreatorFromCart = (orderList = {}) => {
  if (!isPlainObject(orderList)) return '';
  let queryString = '';

  const arrayedOrderList = Object.entries(orderList);

  const allProductsArray = arrayedOrderList.flatMap(
    (orderListSubArray) => orderListSubArray[1].products
  );

  const filteredQueryObject = allProductsArray.reduce((result, product) => {
    if (!result[product.productId]) {
      Object.defineProperty(result, product.productId, {
        value: [product.variantId],
        enumerable: true,
      });
    } else {
      result[product.productId].push(product.variantId);
    }

    return result;
  }, {});

  Object.keys(filteredQueryObject).forEach((key, index, currentArray) => {
    if (typeof key === 'string') {
      const isLastElement = index === currentArray.length - 1;

      const joinedVariantsId = filteredQueryObject[key].join(',');

      queryString += `${key}=${joinedVariantsId}${isLastElement ? '' : '&'}`;
    }
  });

  return queryString;
};
