import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { startCase, kebabCase } from 'lodash';
import { v1 as uuid } from 'uuid';

import ProductCard from 'commonComponents/ProductCard/components/ProductCard';
import MobileBreadcrumbs from 'commonComponents/MobileBreadcrumbs';
import CommonLayoutContainer from '../../../../commonComponents/layouts/CommonLayout/containers/CommonLayoutContainer';
import Totals from '../Totals';
import { calculateTotals } from '../../utils/calculateTotals';
import CheckoutContainer from '../../../CheckoutModule/containers/CheckoutContainer';
import Loading from '../../../../commonComponents/Loading';

import styles from './styles';

const Cart = ({
  classes,
  orderList,
  handleRemoveFromCart,
  handleItemQuantityChange,
  handleClickProductCard,
  isCheckoutModalOpen,
  toggleOpen,
  isLoading,
  isDisabledItemsExist,
}) => {
  const orderInfo = useMemo(() => {
    if (orderList) {
      const allProductsArray = Object.entries(orderList).flatMap((order) => order[1].products);

      let productsCount = 0;

      allProductsArray.forEach((product) => {
        productsCount += product.quantity;
      });

      return {
        allProducts: allProductsArray,
        productsCount,
        totals: calculateTotals(allProductsArray),
      };
    }

    return {};
  }, [orderList]);

  const { allProducts, productsCount, totals } = orderInfo;

  return (
    <CommonLayoutContainer>
      <Loading isLoading={isLoading} />
      {allProducts.length > 0 ? (
        <Grid container spacing={4}>
          <Grid item xs={12} className={classes.breadcrumbWrapper}>
            <MobileBreadcrumbs currentPage="Cart" />
          </Grid>
          <Grid container item xs={12} sm={10} className={classes.singleBrandItems}>
            {Object.keys(orderList).map((companyName, index) => {
              const { totalItemsCost } = calculateTotals(orderList[companyName].products);

              let productsCount = 0;
              orderList[companyName].products.forEach((product) => {
                productsCount += product.quantity;
              });

              return (
                <React.Fragment key={uuid()}>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={10}
                    spacing={4}
                    className={classes.vendorProductsArea}
                  >
                    {orderList[companyName].products.map((product) => {
                      return (
                        <Grid
                          item
                          key={uuid()}
                          xs={12}
                          sm={6}
                          md={4}
                          className={classes.productItem}
                        >
                          <ProductCard
                            isDisabled={product.disabled}
                            key={product.variantId}
                            style={classes.item}
                            product={product}
                            image={product.image}
                            removeHandler={handleRemoveFromCart}
                            handleItemQuantityChange={handleItemQuantityChange}
                            isInCartView
                            handleClick={() =>
                              handleClickProductCard(kebabCase(product.title), product.productId)
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    {orderList[companyName].products.length > 0 && (
                      <Totals
                        title={startCase(companyName)}
                        totalItemsCount={productsCount}
                        totalAmount={`$${+totalItemsCost.toFixed(2)}`}
                        style={index === 2 ? classes.topTotalBrandPrice : classes.totalBrandPrice}
                        withAdditionalInfo
                        additionalInfo={{
                          contacts: [],
                          serviceLinks: orderList[companyName].serviceLinks,
                        }}
                        additionalInfoStyle={classes.additionalInfoTotals}
                      />
                    )}
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Totals
              title="Total"
              totalItemsCount={productsCount}
              isWithSubmit
              totalAmount={`$${+totals.totalItemsCost.toFixed(2)}`}
              style={classes.totalInfo}
              isDisabledItemsExist={isDisabledItemsExist}
              handleSubmit={() => toggleOpen(true)}
            />
          </Grid>
        </Grid>
      ) : (
        <Typography variant="body1" align="center" color="textPrimary">
          Cart is empty.
        </Typography>
      )}
      {!isDisabledItemsExist && (
        <CheckoutContainer isOpen={isCheckoutModalOpen} toggleOpen={toggleOpen} />
      )}
    </CommonLayoutContainer>
  );
};

Cart.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  orderList: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleRemoveFromCart: PropTypes.func.isRequired,
  handleClickProductCard: PropTypes.func.isRequired,
  handleItemQuantityChange: PropTypes.func.isRequired,
  isCheckoutModalOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDisabledItemsExist: PropTypes.bool.isRequired,
};

export default React.memo(withStyles(styles)(Cart));
