import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import OrdersTab from '../components/OrdersTab';
import { GET_USER_ORDERS } from '../actions';

const OrdersTabContainer = () => {
  const dispatch = useDispatch();

  const { orders } = useSelector((state) => state.user);
  const { ordersList, isLoading } = useSelector((state) => state.orders);

  useEffect(() => {
    dispatch(GET_USER_ORDERS());
  }, [orders, dispatch]);

  return <OrdersTab ordersList={ordersList} isLoading={isLoading} />;
};

export default React.memo(OrdersTabContainer);
