import { handleActions, combineActions } from 'redux-actions';

import {
  GET_COMPANY_INFO,
  GET_COMPANY_INFO_SUCCESS,
  GET_COMPANY_INFO_FAIL,
} from '../../CompanyTab/actions';
import * as actions from '../actions';

const defaultState = {
  companyInfo: {},
  summaryInfo: [],
  isLoading: false,
  errors: '',
};

export default handleActions(
  {
    [combineActions(GET_COMPANY_INFO)](state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    [GET_COMPANY_INFO_SUCCESS]: (state, { payload = [] }) => ({
      ...state,
      companyInfo: payload.response,
      isLoading: false,
    }),
    [actions.GET_ORDERS_SUMMARY_INFO_SUCCESS](state, { payload }) {
      return {
        ...state,
        summaryInfo: payload.response,
        isLoading: false,
      };
    },
    [combineActions(actions.GET_CORDERS_SUMMARY_INFO_FAIL, GET_COMPANY_INFO_FAIL)](
      state,
      { payload }
    ) {
      return {
        ...state,
        errors: payload.response,
        isLoading: false,
      };
    },
  },
  defaultState
);
