import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import CommonButton from 'commonComponents/CommonButton';
import Counter from '../../../../modules/CartPage/components/Counter';

import styles from './styles.js';

const ProductCardInCart = ({
  classes,
  id,
  productName,
  color,
  size,
  quantity,
  newPrice,
  handleRemove,
  vendor,
  handleItemQuantityChange,
  isDisabled,
}) => {
  return (
    <Box>
      <Box className={classes.brandInfo}>
        <Typography variant="h4">{productName}</Typography>
        <Typography className={classes.newPrice} variant="h4" color="textPrimary">
          ${newPrice}
        </Typography>
      </Box>
      <Box className={classes.productPropertyContainer}>
        <Typography className={classes.productPropertyTitle}>Color:</Typography>
        <Typography variant="h4">{color}</Typography>
      </Box>
      <Box className={classes.productPropertyContainer}>
        <Typography className={classes.productPropertyTitle}>Size:</Typography>
        <Typography variant="h4">{size}</Typography>
      </Box>
      <Box className={classes.productPropertyContainer}>
        <Typography className={classes.productPropertyTitle}>Quantity:</Typography>
        <Counter
          quantity={quantity}
          handleItemQuantityChange={handleItemQuantityChange}
          itemId={id}
          vendor={vendor}
          isDisabled={isDisabled}
        />
        <CommonButton
          style={{ color: isDisabled ? 'red' : '' }}
          startIcon={<CloseIcon />}
          label="Remove"
          color="primary"
          className={classes.removeButton}
          onClick={() => handleRemove({ id, vendor })}
        />
      </Box>
    </Box>
  );
};

ProductCardInCart.defaultProps = {
  color: '',
  size: '',
  vendor: '',
  quantity: '',
  isDisabled: false,
};

ProductCardInCart.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  productName: PropTypes.string.isRequired,
  newPrice: PropTypes.number.isRequired,
  color: PropTypes.string,
  quantity: PropTypes.number,
  size: PropTypes.string,
  vendor: PropTypes.string,
  handleRemove: PropTypes.func.isRequired,
  handleItemQuantityChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default React.memo(withStyles(styles)(ProductCardInCart));
