import { handleActions } from 'redux-actions';
import * as actions from '../actions/index';

const defaultState = {
  currentTab: '/orders',
};

const adminPage = handleActions(
  {
    [actions.SET_LAST_VISITED_TAB]: (state, { payload }) => ({
      ...state,
      currentTab: payload,
    }),
  },
  defaultState
);

export default adminPage;
