import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Typography, Box, withStyles } from '@material-ui/core';

import { validateEmail } from 'helpers/validations';
import {
  validateConfirmPassword,
  validatePassword,
  getPasswordError,
} from 'commonComponents/RegisterModal/helpers/validations';
import { INVALID_EMAIL, INVALID_PASSWORD_CONFIRM } from 'constants/errorMessages';
import CommonButton from 'commonComponents/CommonButton';
import CommonInput from 'commonComponents/CommonInput';
import { DEFAULT_INPUT_LENGTH } from 'constants/inputOptions';
import CommonDialog from 'commonComponents/CommonDialog';

import styles from './styles';

const CreateClientModal = ({
  classes,
  isOpen,
  handleModalToggle,
  formValues,
  handleFormValuesChange,
  isFormDataValid,
  handleSubmit,
}) => {
  const { email, password, confirmPassword } = formValues;
  return (
    <CommonDialog isOpen={isOpen} onClose={handleModalToggle} style={classes.commonDialog}>
      <Box className={classes.cancelWrapper}>
        <CommonButton
          className={classes.closeButton}
          startIcon={<CloseIcon />}
          label=""
          variant="text"
          size="large"
          onClick={handleModalToggle}
        />
      </Box>
      <Typography variant="h2" align="center" className={classes.modalTitle}>
        Create A Client
      </Typography>
      <Grid container spacing={4} className={classes.dialogWrapper}>
        <Grid item xs={12}>
          <CommonInput
            value={email}
            onChange={handleFormValuesChange}
            name="email"
            label="Email"
            error={(email !== '', !validateEmail(email))}
            helperText={INVALID_EMAIL}
            inputProps={{
              maxLength: DEFAULT_INPUT_LENGTH,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CommonInput
            type="password"
            value={password}
            onChange={handleFormValuesChange}
            name="password"
            label="Password"
            error={password !== '' && !validatePassword(password)}
            helperText={getPasswordError(password)}
          />
        </Grid>
        <Grid item xs={12}>
          <CommonInput
            type="password"
            value={confirmPassword}
            onChange={handleFormValuesChange}
            name="confirmPassword"
            label="Confirm Password"
            error={confirmPassword !== '' && !validateConfirmPassword(password, confirmPassword)}
            helperText={INVALID_PASSWORD_CONFIRM}
          />
        </Grid>
        <Grid item xs={12} className={classes.submitButton}>
          <CommonButton
            label="Submit"
            variant="contained"
            disabled={!isFormDataValid}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </CommonDialog>
  );
};

CreateClientModal.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  isFormDataValid: PropTypes.bool.isRequired,
  handleModalToggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleFormValuesChange: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(withStyles(styles)(CreateClientModal));
