export const calculateTotals = (products = [], shipment) => {
  if (Array.isArray(products)) {
    return products.reduce(
      (result, product) => {
        result.totalItemsCost += product.quantity * product.price;

        return result;
      },
      { totalItemsCost: shipment || 0 }
    );
  }
  return {};
};
