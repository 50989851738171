import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SET_LOGIN_MODAL_SHOWED } from 'commonComponents/layouts/CommonLayout/actions';
import { CHANGE_PEOPLE_FILTER, GET_LANDING_CONFIG } from 'config/globalActions';
import { UPDATE_USER_PROFILE } from 'modules/ProfilePage/components/Tabs/ProfileTab/actions';
import ROUTES from 'routes';
import HomePage from '../components/HomePage';
import { GET_ACTIVE_COMPANIES_BY_GENDER } from '../actions';
import { filterTargetGenerator } from '../../../helpers/filterTargetGenerator';

const HomePageContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { products } = useSelector((state) => state.recentlyViewed);
  const { isLoading, config, companies } = useSelector((state) => state.homePage);
  const { _id, follows } = useSelector((state) => state.user);
  const { filterByPeopleCategory } = useSelector((state) => state.global);

  useEffect(() => {
    const targets = filterTargetGenerator(filterByPeopleCategory);

    dispatch(GET_ACTIVE_COMPANIES_BY_GENDER(targets));
    dispatch(GET_LANDING_CONFIG());
  }, [dispatch, filterByPeopleCategory]);

  const handleClickProductCard = useCallback(
    (productName, productId) => {
      history.push(`${ROUTES.PRODUCT_PAGE}/${productName}-${productId}`);
    },
    [history]
  );

  const handleClickCompanyCard = useCallback(
    (pathName) => {
      history.push(`${ROUTES.COMPANY}/${pathName}`);
    },
    [history]
  );

  const handleFollowCompany = useCallback(
    (companyId) => {
      if (_id) {
        const newFollows = follows.slice();
        const companyIndex = newFollows.indexOf(companyId);

        if (companyIndex > -1) {
          newFollows.splice(companyIndex, 1);
          dispatch(UPDATE_USER_PROFILE({ userId: _id, follows: newFollows }));
        } else {
          newFollows.unshift(companyId);
          dispatch(UPDATE_USER_PROFILE({ userId: _id, follows: newFollows }));
        }
      } else {
        dispatch(SET_LOGIN_MODAL_SHOWED(true));
      }
    },
    [follows, _id, dispatch]
  );

  const handlePeopleFilterChange = useCallback(
    (option) => {
      dispatch(CHANGE_PEOPLE_FILTER(option));
    },
    [dispatch]
  );

  const topCompaniesArray = useMemo(() => {
    const foundConfig = config.find(({ section }) => section === filterByPeopleCategory);

    return foundConfig ? foundConfig.config : [];
  }, [filterByPeopleCategory, config]);

  return (
    <HomePage
      products={products}
      companies={companies}
      handleClickProductCard={handleClickProductCard}
      handleClickCompanyCard={handleClickCompanyCard}
      isLoading={isLoading}
      handleFollowCompany={handleFollowCompany}
      topCompaniesArray={topCompaniesArray}
      follows={follows}
      filterByPeopleCategory={filterByPeopleCategory}
      handlePeopleFilterChange={handlePeopleFilterChange}
    />
  );
};

export default React.memo(HomePageContainer);
