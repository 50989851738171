import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ROUTES from 'routes';
import { MERCHANT_TAB_ROUTES } from 'modules/AdminPage/config';
import { useQuery } from 'helpers/hooks/useQuery';
import SnackBar from 'commonComponents/SnackBar';
import Loading from 'commonComponents/Loading';
import PaymentSettingsTab from '../components/PaymentSettingsTab';
import { LINK_STRIPE_ACCOUNT, UNLINK_STRIPE_ACCOUNT, CLEAR_PAYMENT_TAB_MESSAGE } from '../actions';
import { GET_COMPANY_INFO } from '../../CompanyTab/actions';

const PaymentSettingsContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const { onboardingLink, message, isSuccess, isLoading } = useSelector(
    (state) => state.merchantTabs.paymentSettingsTab
  );
  const { stripeId, isStripeOnboardingCompleted } = useSelector(
    (state) => state.merchantTabs.companyTab
  );

  useEffect(() => {
    dispatch(GET_COMPANY_INFO());
  }, [dispatch]);

  useEffect(() => {
    const stripeAccountId = query.get('stripeId');

    if (stripeAccountId) {
      const link = `${ROUTES.ADMIN_PAGE}${
        MERCHANT_TAB_ROUTES.find((tab) => tab.title === 'Payment Settings').path
      }`;

      dispatch(LINK_STRIPE_ACCOUNT(stripeAccountId));
      history.push(link);
    }
  }, [stripeId, query, history, dispatch]);

  const unlinkStripeAccount = useCallback(() => {
    dispatch(UNLINK_STRIPE_ACCOUNT());
    dispatch(GET_COMPANY_INFO());
  }, [dispatch]);

  const handleRegisterMessageClear = useCallback(() => {
    dispatch(CLEAR_PAYMENT_TAB_MESSAGE());
  }, [dispatch]);

  return (
    <>
      <PaymentSettingsTab
        stripeId={stripeId}
        onboardingLink={onboardingLink}
        isStripeOnboardingCompleted={isStripeOnboardingCompleted}
        unlinkStripeAccount={unlinkStripeAccount}
      />
      <SnackBar
        isSuccess={isSuccess}
        message={message}
        onHandleClose={handleRegisterMessageClear}
      />
      <Loading isLoading={isLoading} />
    </>
  );
};

export default React.memo(PaymentSettingsContainer);
