import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Typography, Link, Grid, withStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import footerConfig from 'constants/footerConfig';
import { wpLinksConfig } from 'constants/links';
import CommonButton from 'commonComponents/CommonButton';
import SocialBox from 'commonComponents/SocialBox';

import styles from './styles';

const Footer = ({ classes }) => {
  const history = useHistory();

  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const socialNetworks = [
    { name: 'instagramm', link: 'instagramm' },
    { name: 'twitter', link: 'twitter' },
    { name: 'facebook', link: 'facebook' },
  ];

  return (
    <Box className={classes.footer}>
      <Container className={classes.wrapper} maxWidth="lg">
        <Grid container justify="center" className={classes.linkContainer}>
          {footerConfig.map((page) => (
            <Grid item xs={4} md={2} key={page.title} className={classes.category}>
              <CommonButton
                label={page.title}
                variant="text"
                color="secondary"
                onClick={() => history.push(page.link)}
              />
            </Grid>
          ))}
          {wpLinksConfig.map((page) => (
            <Grid
              item
              xs={4}
              md={page.title === 'Terms + Conditions' ? 3 : 2}
              key={page.title}
              className={classes.category}
            >
              <Link target="_blank" href={page.link}>
                <CommonButton label={page.title} variant="text" color="secondary" />
              </Link>
            </Grid>
          ))}
        </Grid>
        <Box className={classes.socials}>
          <Typography variant="body2" color="textPrimary" className={classes.copyrightNotice}>
            {`© CLOUD+MORTAR ${currentYear} ALL RIGHTS RESERVED`}
          </Typography>
          <SocialBox style={classes.socialBox} socialNetworks={socialNetworks} />
        </Box>
      </Container>
    </Box>
  );
};

Footer.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default React.memo(withStyles(styles)(Footer));
