import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, kebabCase } from 'lodash';
import { v1 as uuid } from 'uuid';
import { Grid, Typography, Box, withStyles, useTheme, useMediaQuery } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';

import { changeImageSize } from 'helpers/changeImageSize';
import { getFirstCompanyImage } from 'helpers/mediaHelpers.js';
import MobileBreadcrumbs from 'commonComponents/MobileBreadcrumbs';
import CommonLayoutContainer from 'commonComponents/layouts/CommonLayout/containers/CommonLayoutContainer';
import CommonCarousel from 'commonComponents/CommonCarousel';
import CompanyCard from 'commonComponents/CompanyCard';
import ProductCard from 'commonComponents/ProductCard/components/ProductCard';

import styles from './styles.js';

const FavoritesPage = ({
  classes,
  companies,
  companyProducts,
  getNextCompany,
  isLoading,
  handleFollowCompany,
  handleClickProductCard,
  handleClickCompanyCard,
  isAuth,
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const companyProductsKeys = companyProducts && Object.keys(companyProducts);

  return (
    <CommonLayoutContainer isLoading={isLoading}>
      {isAuth ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={getNextCompany}
          hasMore={companies.length > companyProductsKeys.length}
          initialLoad={companyProductsKeys.length < 5}
        >
          <Grid container spacing={4} style={{ minHeight: 700 }}>
            {!isMobileView ? (
              <Grid item xs={12}>
                <Typography variant="h3">Favorites</Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <MobileBreadcrumbs currentPage="Favorites" />
              </Grid>
            )}
            {companyProductsKeys.length > 0 ? (
              companyProductsKeys.map((companyId) => {
                const products = companyProducts[companyId];
                const company = companies.find((element) => element.shopifyId === companyId);
                return (
                  company &&
                  !isEmpty(products) && (
                    <React.Fragment key={companyId}>
                      <Grid item xs={12} sm={12} md={3}>
                        <CompanyCard
                          key={uuid()}
                          style={classes.companyItem}
                          buttonStyle={classes.buttonStyle}
                          company={{
                            title: company.title,
                            description: company.landingDescription,
                            image: getFirstCompanyImage(company.media),
                            socialNetworks: company.socialNetworks,
                          }}
                          withFollowButton
                          handleClick={() => handleClickCompanyCard(company.pathName)}
                          handleFollowCompany={() => handleFollowCompany(company._id)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={9}>
                        <CommonCarousel>
                          {products.map((product) => (
                            <ProductCard
                              key={product.id}
                              style={classes.product}
                              image={product.image && changeImageSize(product.image.src, 400, 300)}
                              product={product}
                              handleClick={() =>
                                handleClickProductCard(kebabCase(product.title), product._id)
                              }
                            />
                          ))}
                        </CommonCarousel>
                      </Grid>
                    </React.Fragment>
                  )
                );
              })
            ) : (
              <Grid item xs={12} className={classes.notFoundBlock}>
                <Typography variant="body1" color="textPrimary">
                  No brands were added.
                </Typography>
              </Grid>
            )}
          </Grid>
        </InfiniteScroll>
      ) : (
        <Box className={classes.notFoundBlock}>
          <Typography variant="body1" color="textPrimary">
            Please Sign In to see your Favorite Brands.
          </Typography>
        </Box>
      )}
    </CommonLayoutContainer>
  );
};

FavoritesPage.defaultProps = {
  companyProducts: {},
  companies: [],
};

FavoritesPage.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  companies: PropTypes.oneOfType([PropTypes.array]),
  companyProducts: PropTypes.oneOfType([PropTypes.object]),
  handleClickProductCard: PropTypes.func.isRequired,
  handleClickCompanyCard: PropTypes.func.isRequired,
  handleFollowCompany: PropTypes.func.isRequired,
  getNextCompany: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
};

export default React.memo(withStyles(styles)(FavoritesPage));
