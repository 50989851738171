import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import MaterialTable from 'material-table';

import palette from 'theme/palette';
import { tableIcons } from '../../constants';

import styles from './style';

const SummaryInfoTable = ({ summaryInfo, formValues, isAdmin }) => {
  const date = useMemo(() => {
    if (formValues.dateTo === '' && formValues.dateFrom === '') {
      return '-';
    }
    if (formValues.dateTo === '' && formValues.dateFrom !== '') {
      return `from ${formValues.dateFrom}`;
    }
    if (formValues.dateTo !== '' && formValues.dateFrom === '') {
      return `to ${formValues.dateTo}`;
    }
    return `from ${formValues.dateFrom} to ${formValues.dateTo}`;
  }, [formValues]);

  const columns = useMemo(() => {
    const merchantColumns = [
      { title: 'Date', render: () => <div>{date}</div> },
      { title: 'Sales', field: 'totalPrice', type: 'currency' },
      {
        title: 'Orders',
        field: 'ordersAmount',
        type: 'numeric',
      },
      {
        title: 'Shipping',
        field: 'totalShipping',
        type: 'currency',
      },
      { title: 'Taxes', field: 'totalTax', type: 'currency' },
      { title: 'Fees', field: 'totalFee', type: 'currency' },
      {
        title: 'Net',
        field: 'net',
        render: (rowData) => (
          <>
            {`$${(
              rowData.totalPrice -
              rowData.totalTax -
              rowData.totalShipping -
              rowData.totalFee
            ).toFixed(2)}`}
          </>
        ),
      },
    ];

    return isAdmin
      ? [{ title: 'Merchant', field: 'merchant' }, ...merchantColumns]
      : merchantColumns;
  }, [isAdmin, date]);

  return (
    <MaterialTable
      icons={tableIcons}
      columns={columns}
      options={{
        sorting: false,
        headerStyle: {
          color: palette.black,
        },
        cellStyle: {
          color: palette.black,
          fontSize: 16,
        },
        pageSize: isAdmin ? 5 : 1,
        pageSizeOptions: isAdmin ? [5, 10, 20] : [1, 5, 10],
        exportButton: true,
        search: false,
        draggable: false,
        exportAllData: true,
      }}
      data={summaryInfo}
      title="Summary info"
    />
  );
};

SummaryInfoTable.propTypes = {
  summaryInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  formValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default React.memo(withStyles(styles)(SummaryInfoTable));
