import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SET_LOGIN_MODAL_SHOWED } from 'commonComponents/layouts/CommonLayout/actions';
import ROUTES from 'routes';
import { UPDATE_USER_PROFILE } from 'modules/ProfilePage/components/Tabs/ProfileTab/actions';
import { filterTargetGenerator } from 'helpers/filterTargetGenerator';
import { GET_ACTIVE_COMPANIES_BY_GENDER } from 'modules/HomePage/actions';
import BrandsListPage from '../components/BrandsListPage';

const BrandsListPageContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { filterByPeopleCategory } = useSelector((state) => state.global);
  const { isLoading, companies } = useSelector((state) => state.homePage);
  const { _id, follows } = useSelector((state) => state.user);

  useEffect(() => {
    const targets = filterTargetGenerator(filterByPeopleCategory);

    dispatch(GET_ACTIVE_COMPANIES_BY_GENDER(targets));
  }, [dispatch, filterByPeopleCategory]);

  const handleClickCompanyCard = useCallback(
    (companyId) => {
      history.push(`${ROUTES.COMPANY}/${companyId}`);
    },
    [history]
  );

  const handleFollowCompany = useCallback(
    (companyId) => {
      if (_id) {
        const newFollows = follows.slice();
        const companyIndex = newFollows.indexOf(companyId);

        if (companyIndex > -1) {
          newFollows.splice(companyIndex, 1);
          dispatch(UPDATE_USER_PROFILE({ userId: _id, follows: newFollows }));
        } else {
          newFollows.unshift(companyId);
          dispatch(UPDATE_USER_PROFILE({ userId: _id, follows: newFollows }));
        }
      } else {
        dispatch(SET_LOGIN_MODAL_SHOWED(true));
      }
    },
    [follows, _id, dispatch]
  );

  return (
    <BrandsListPage
      companies={companies}
      handleClickCompanyCard={handleClickCompanyCard}
      isLoading={isLoading}
      handleFollowCompany={handleFollowCompany}
      follows={follows}
    />
  );
};

export default React.memo(BrandsListPageContainer);
