export const defaultSizes = {
  xs: false,
  s: false,
  m: false,
  l: false,
  xl: false,
  xxl: false,
};

export const womensSizeChart = {
  xs: ['0', '2', '4', 'xs/s'],
  s: ['6', '8', 'xs/s', 's/m'],
  m: ['10', '12', 's/m', 'm/l'],
  l: ['14', '16', 'm/l', 'l/xl'],
  xl: ['18', '20', 'l/xl', 'xl/xxl'],
  xxl: ['22', '24', 'xl/xxl'],
};

export const mensSizeChart = {
  xs: ['30', '32', 'xs/s'],
  s: ['34', '36', 'xs/s', 's/m'],
  m: ['38', '40', 's/m', 'm/l'],
  l: ['42', '44', 'm/l', 'l/xl'],
  xl: ['46', '48', 'l/xl', 'xl/xxl'],
  xxl: ['50', '52', 'xl/xxl'],
};

export const defaultColors = {
  Black: false,
  Graphite: false,
  HeatherOatmeal: false,
  Taupe: false,
  Charcoal: false,
  Cream: false,
  Camel: false,
  IceGrey: false,
  HeatherGreystone: false,
  Fog: false,
  HeatherOat: false,
  Blush: false,
  MedHeatherGrey: false,
  Oatmeal: false,
  HeatherCream: false,
  Rose: false,
  Oat: false,
  HeatherGrey: false,
  Platinum: false,
  Wine: false,
  NavyStripe: false,
  Plum: false,
  Tan: false,
  LightHeatherGrey: false,
  SteelBlue: false,
  White: false,
  Sand: false,
  MidnightNavy: false,
  Ivory: false,
  Mocha: false,
  Stone: false,
  HeatherBlueGrey: false,
  SnakePrint: false,
  HeatherSand: false,
};

export const defaultPriceRange = [0, 500];

export const defaultDiscountRange = [0, 100];
