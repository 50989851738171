const styles = (theme) => ({
  counterContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  controlButton: {
    width: 20,
    height: 20,
    textAlign: 'center',
    color: theme.palette.black,
    borderRadius: 2.5,
    minWidth: 20,
    padding: '0 0 0 11px',
    '& .MuiButton-iconSizeMedium > *:first-child': {
      fontSize: 16,
    },
  },
  count: {
    margin: '0 10px',
  },
});

export default styles;
