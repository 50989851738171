import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, withStyles } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';

import CommonButton from '../../../../commonComponents/CommonButton';

import styles from './styles';

const Counter = ({ quantity, classes, handleItemQuantityChange, itemId, vendor, isDisabled }) => {
  const [count, setCount] = useState(quantity);

  const handleCountSet = useCallback(
    (action) => {
      if (action === 'decrease') {
        if (count > 1) {
          setCount(count - 1);
          handleItemQuantityChange({ value: count - 1, variantId: itemId, vendor });
        }
      } else {
        setCount(count + 1);
        handleItemQuantityChange({ value: count + 1, variantId: itemId, vendor });
      }
    },
    [count, handleItemQuantityChange, itemId, vendor]
  );

  return (
    <Box className={classes.counterContainer}>
      <CommonButton
        label=""
        startIcon={<Remove />}
        variant="contained"
        className={classes.controlButton}
        onClick={() => handleCountSet('decrease')}
      />
      <Typography className={classes.count} variant="h4">
        {count}
      </Typography>
      <CommonButton
        label=""
        isDisabled={isDisabled}
        startIcon={<Add />}
        variant="contained"
        className={classes.controlButton}
        onClick={() => handleCountSet('increase')}
      />
    </Box>
  );
};

Counter.defaultProps = {
  isDisabled: false,
};

Counter.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  quantity: PropTypes.number.isRequired,
  handleItemQuantityChange: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  vendor: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default React.memo(withStyles(styles)(Counter));
