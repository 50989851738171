import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, AppBar, Toolbar, Typography, withStyles } from '@material-ui/core';

import { useRouteToGo } from 'helpers/hooks/useRouteToGo';
import TabSwitcher from 'commonComponents/TabSwitcher';
import Sidebar from '../Sidebar';
import ROUTES from '../../../../routes';
import CommonButton from '../../../../commonComponents/CommonButton';
import { SUPER_ADMIN_TAB_ROUTES, MERCHANT_TAB_ROUTES } from '../../config';

import styles from './style';

const AdminLayout = ({ handleRememberLastTab, classes, isSuperAdmin }) => {
  const TABS = useMemo(() => {
    return isSuperAdmin ? SUPER_ADMIN_TAB_ROUTES : MERCHANT_TAB_ROUTES;
  }, [isSuperAdmin]);

  const goToHomePage = useRouteToGo(ROUTES.HOME);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.nameButtonWrapper}>
          <Typography variant="h3" color="primary">
            Welcome back!
          </Typography>
          <CommonButton
            label="Back"
            variant="text"
            color="secondary"
            className={classes.homeButton}
            onClick={goToHomePage}
          />
        </Toolbar>
      </AppBar>
      <Sidebar handleRememberLastTab={handleRememberLastTab} tabs={TABS} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <TabSwitcher baseUrl={ROUTES.ADMIN_PAGE} tabs={TABS} />
      </main>
    </div>
  );
};

AdminLayout.defaultProps = {
  handleRememberLastTab: () => {},
};

AdminLayout.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleRememberLastTab: PropTypes.func,
  isSuperAdmin: PropTypes.bool.isRequired,
};

export default React.memo(withStyles(styles)(AdminLayout));
