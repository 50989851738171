import { isPlainObject, isEmpty } from 'lodash';

const isAllValuesNotEmpty = (valuesToCheck) =>
  !isEmpty(valuesToCheck) &&
  Object.entries(valuesToCheck).filter((value) => value[1] === '').length > 0;

export const stepperButtonDisabler = (step = 0, valuesToCheck = {}) => {
  if (!isPlainObject(valuesToCheck)) {
    return true;
  }

  const { address, state, city, zip, firstName, lastName, phone, email } = valuesToCheck;

  const firstStepValuesToCheck = { firstName, lastName, phone, email, address, state, city, zip };

  switch (step) {
    case 0:
      return isAllValuesNotEmpty(firstStepValuesToCheck);

    default:
      return false;
  }
};

export const shipmentsMapper = (orderList = {}, shippingInfo = []) => {
  if (isPlainObject(orderList) && Array.isArray(shippingInfo)) {
    return Object.entries(orderList)
      .map((order) => {
        const shippingVariant = shippingInfo.find(
          (shipping) => shipping.storeId === order[1].storeId
        );

        const variants = shippingVariant
          ? shippingVariant.priceBasedShippingRates.map(({ id, name, price }) => ({
              id,
              name,
              price,
            }))
          : [];

        const currentVariant =
          variants.length > 0
            ? variants.sort((prevVariant, nextVariant) =>
                prevVariant.price > nextVariant.price ? 1 : -1
              )[0]
            : {};

        return {
          company: order[0],
          variants,
          currentVariant,
        };
      })
      .filter((company) => company.variants.length > 0);
  }

  return [];
};
