const styles = (theme) => ({
  foundProductsContainer: {
    width: '100%',
    zIndex: '100',
    background: 'rgba(255, 255, 255, 0.75)',
    paddingTop: 30,
  },
  loader: {
    top: 80,
  },
  notFoundBlock: {
    textAlign: 'center',
  },
});

export default styles;
