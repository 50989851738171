import React from 'react';
import PropTypes from 'prop-types';
import { Box, withStyles, Typography } from '@material-ui/core';

import CommonButton from 'commonComponents/CommonButton';

import styles from './styles';

const CompleteLinkingScreen = ({ linkedStripeId, unlinkStripeAccount, classes }) => {
  return (
    <Box className={classes.completeLinkingInfo}>
      <Typography variant="h2">Your stripe account was successfully linked!</Typography>
      <Typography variant="h3" color="textPrimary">
        Stripe ID: {linkedStripeId}
      </Typography>
      <CommonButton
        label="Unlink account"
        variant="contained"
        className={classes.unlinkButton}
        onClick={unlinkStripeAccount}
      />
    </Box>
  );
};

CompleteLinkingScreen.propTypes = {
  linkedStripeId: PropTypes.string.isRequired,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  unlinkStripeAccount: PropTypes.func.isRequired,
};

export default React.memo(withStyles(styles)(CompleteLinkingScreen));
