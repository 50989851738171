const styles = (theme) => ({
  billTitle: {
    color: theme.palette.black,
    textAlign: 'center',
    marginBottom: 20,
  },
  stripeComponent: {
    '&, .StripeElement': {
      display: 'block',
      maxWidth: 500,
      padding: '10px 14px',
      fontSize: '1em',
      boxShadow: 'rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px',
      border: 0,
      outline: 0,
      borderRadius: 4,
      background: theme.palette.white,
      margin: '0 auto',
      marginBottom: 20,
    },
  },
  stripeCheckoutButton: {
    maxWidth: 140,
    position: 'absolute',
    bottom: '85px',
    right: '23px',
    [theme.breakpoints.down('400')]: {
      position: 'initial',
      maxWidth: 'unset',
    },
  },
  personalInfoBox: {
    borderColor: theme.palette.text.primary,
    borderRadius: 5,
    border: '1px solid',
    margin: 10,
  },
  personalInfoText: {
    margin: 12,
    minWidth: 45,
    [theme.breakpoints.down('xs')]: {
      margin: 6,
      textAlign: 'right',
    },
  },
  personalInfoLabel: {
    margin: 12,
    minWidth: 45,
    [theme.breakpoints.down('xs')]: {
      margin: 6,
    },
  },
  personalSummaryInfo: {
    margin: 12,
    minWidth: 105,
    [theme.breakpoints.down('xs')]: {
      margin: 6,
    },
  },
  divider: {
    margin: 10,
    [theme.breakpoints.down('xs')]: {
      margin: 5,
    },
  },
  personalInfoChangeButton: {
    textAlign: 'right',
  },
});

export default styles;
