export const getFirstCompanyImage = (media = []) => {
  if (media.length > 0) {
    const firstImage = media.find((mediaFile) =>
      /image\/(gif|jpe?g|bmp|png)/.test(mediaFile.mimeType)
    );
    return firstImage ? firstImage.url : '';
  }
  return '';
};

export const videoFormatTester = (image) => /.(mp4|webm)/.test(image);
