import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';

import Pagination from 'commonComponents/Pagination';
import ItemsList from 'commonComponents/ItemsList';
import RemoveConfirmationModal from 'commonComponents/RemoveConfirmationModal';
import CommonButton from 'commonComponents/CommonButton';
import Snack from 'commonComponents/SnackBar';
import CreateClientModal from '../CreateClientModal';
import ContactsListModal from '../ContactsListModal';

import styles from './styles';

const ClientsTab = ({
  classes,
  clients,
  handlePageUpdate,
  handleClientRemove,
  pageCount,
  isCreateModalOpen,
  onOpenCreateClientModal,
  onCloseCreateClientModal,
  formValues,
  handleFormValuesChange,
  isFormDataValid,
  responseMessages,
  handleResponseMessageClear,
  handleClientCreate,
  isRemoveClientModalOpen,
  handleRemoveModalOpen,
  handleRemoveModalClose,
  handleContactsListModalOpen,
  selectedContacts,
  isContactsListModalOpen,
  handleCloseContactsListModal,
  pageNumber,
}) => {
  const { errors, successMessage } = responseMessages;

  return (
    <Box>
      <Grid item xs={12}>
        <Typography align="center" variant="h2">
          Merchant Contacts
        </Typography>
      </Grid>
      <Box className={classes.clientListWrapper}>
        <ItemsList
          items={clients}
          fieldsToUse={['email', 'company']}
          handleRemoveButtonHandler={handleRemoveModalOpen}
          onListContactsButtonToggle={handleContactsListModalOpen}
        />
      </Box>
      <Box className={classes.paginationWrapper}>
        <Pagination pageCount={pageCount} currentPage={pageNumber} onChange={handlePageUpdate} />
      </Box>
      <Box>
        <CommonButton
          label="Create Merchant"
          variant="contained"
          onClick={onOpenCreateClientModal}
        />
      </Box>
      <CreateClientModal
        isOpen={isCreateModalOpen}
        handleModalToggle={onCloseCreateClientModal}
        formValues={formValues}
        handleFormValuesChange={handleFormValuesChange}
        isFormDataValid={isFormDataValid}
        handleSubmit={handleClientCreate}
      />
      <ContactsListModal
        isOpen={isContactsListModalOpen}
        handleModalToggle={handleCloseContactsListModal}
        selectedContacts={selectedContacts}
      />
      <RemoveConfirmationModal
        isOpen={isRemoveClientModalOpen}
        handleRemoveModalClose={handleRemoveModalClose}
        handleSubmit={handleClientRemove}
        subTitle="user"
      />
      <Snack
        message={errors || successMessage}
        onHandleClose={handleResponseMessageClear}
        isSuccess={!!successMessage}
      />
    </Box>
  );
};

ClientsTab.defaultProps = {
  responseMessages: { errors: '', successMessage: '' },
};

ClientsTab.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  clients: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCreateModalOpen: PropTypes.bool.isRequired,
  isRemoveClientModalOpen: PropTypes.bool.isRequired,
  isFormDataValid: PropTypes.bool.isRequired,
  handlePageUpdate: PropTypes.func.isRequired,
  handleClientCreate: PropTypes.func.isRequired,
  handleRemoveModalClose: PropTypes.func.isRequired,
  handleResponseMessageClear: PropTypes.func.isRequired,
  onOpenCreateClientModal: PropTypes.func.isRequired,
  onCloseCreateClientModal: PropTypes.func.isRequired,
  handleClientRemove: PropTypes.func.isRequired,
  handleRemoveModalOpen: PropTypes.func.isRequired,
  handleFormValuesChange: PropTypes.func.isRequired,
  handleContactsListModalOpen: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  selectedContacts: PropTypes.oneOfType([PropTypes.array]).isRequired,
  isContactsListModalOpen: PropTypes.bool.isRequired,
  handleCloseContactsListModal: PropTypes.func.isRequired,
  responseMessages: PropTypes.shape({
    successMessage: PropTypes.string,
    errors: PropTypes.string,
  }),
  formValues: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(withStyles(styles)(ClientsTab));
