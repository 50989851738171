const styles = (theme) => ({
  order: {
    display: 'block',
    minHeight: 45,
    width: '100%',
    borderBottom: '10px solid #fff',
    padding: 10,
  },
  mainInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  orderInfo: {
    marginBottom: 10,
  },
  brandInfo: {
    position: 'relative',
  },
  title: {
    marginBottom: 10,
  },
  expandIcon: {
    flexGrow: 2,
    textAlign: 'right',
  },
  statusInfo: {
    flexGrow: 1,
  },
  dateInfo: {
    flexGrow: 1,
  },
  totals: {
    marginTop: 10,
    marginBottom: 0,
  },
  totalBrandInfo: {
    marginBottom: 15,
    '& .MuiTypography-h3': {
      fontSize: 14,
    },
  },
  additionalInfoStyle: {
    marginTop: 0,
  },
  product: {
    width: 40,
    height: 40,
    '&.MuiPaper-root': {
      left: 0,
    },
  },
  productsContainer: {
    marginBottom: 20,
  },
  productModal: {
    left: 0,
    top: 260,
  },
});

export default styles;
