import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';

import ItemsList from 'commonComponents/ItemsList';
import Pagination from 'commonComponents/Pagination';
import CommonButton from 'commonComponents/CommonButton';
import RemoveConfirmationModal from 'commonComponents/RemoveConfirmationModal';
import ManageCompanyModal from '../ManageCompanyModal';

import styles from './styles';

const ManageCompaniesTab = ({
  classes,
  companies,
  handlePageUpdate,
  handleCompanyRemove,
  pageCount,
  isCreateModalOpen,
  onOpenManageCompanyModal,
  onCloseManageCompanyModal,
  formValues,
  handleFormValuesChange,
  isFormDataValid,
  handleCompanyCreate,
  isRemoveCompanyModalOpen,
  handleRemoveModalOpen,
  handleRemoveModalClose,
  onUpdateCompany,
  isEditModalMode,
  onEditCompanyButtonToggle,
  merchants,
  handleSelectMerchant,
  selectedMerchant,
  handleSelectStore,
  selectedStore,
  stores,
  pageNumber,
}) => {
  return (
    <Box>
      <Grid item xs={12}>
        <Typography align="center" variant="h2">
          Companies List
        </Typography>
      </Grid>
      <Box className={classes.clientListWrapper}>
        <ItemsList
          items={companies}
          isCompanyList
          onUpdateCompany={onUpdateCompany}
          fieldsToUse={['title', 'isValid']}
          handleRemoveButtonHandler={handleRemoveModalOpen}
          onEditCompanyButtonToggle={onEditCompanyButtonToggle}
        />
      </Box>
      <Box className={classes.paginationWrapper}>
        <Pagination pageCount={pageCount} currentPage={pageNumber} onChange={handlePageUpdate} />
      </Box>
      <Box>
        <CommonButton
          label="Create Company"
          variant="contained"
          onClick={onOpenManageCompanyModal}
        />
      </Box>
      <ManageCompanyModal
        isOpen={isCreateModalOpen}
        isEditModalMode={isEditModalMode}
        handleModalToggle={onCloseManageCompanyModal}
        formValues={formValues}
        handleFormValuesChange={handleFormValuesChange}
        isFormDataValid={isFormDataValid}
        merchants={merchants}
        stores={stores}
        handleSelectMerchant={handleSelectMerchant}
        handleSubmit={isEditModalMode ? onUpdateCompany : handleCompanyCreate}
        selectedMerchant={selectedMerchant}
        handleSelectStore={handleSelectStore}
        selectedStore={selectedStore}
      />
      <RemoveConfirmationModal
        isOpen={isRemoveCompanyModalOpen}
        handleRemoveModalClose={handleRemoveModalClose}
        handleSubmit={handleCompanyRemove}
        subTitle="company"
      />
    </Box>
  );
};

ManageCompaniesTab.defaultProps = {
  responseMessages: { errors: '', successMessage: '' },
  selectedMerchant: {},
  selectedStore: {},
};

ManageCompaniesTab.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCreateModalOpen: PropTypes.bool.isRequired,
  isRemoveCompanyModalOpen: PropTypes.bool.isRequired,
  isFormDataValid: PropTypes.bool.isRequired,
  handlePageUpdate: PropTypes.func.isRequired,
  handleCompanyCreate: PropTypes.func.isRequired,
  handleRemoveModalClose: PropTypes.func.isRequired,
  onOpenManageCompanyModal: PropTypes.func.isRequired,
  onCloseManageCompanyModal: PropTypes.func.isRequired,
  handleCompanyRemove: PropTypes.func.isRequired,
  handleRemoveModalOpen: PropTypes.func.isRequired,
  handleFormValuesChange: PropTypes.func.isRequired,
  onUpdateCompany: PropTypes.func.isRequired,
  isEditModalMode: PropTypes.bool.isRequired,
  merchants: PropTypes.arrayOf(PropTypes.object).isRequired,
  stores: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMerchant: PropTypes.oneOfType([PropTypes.object]),
  selectedStore: PropTypes.oneOfType([PropTypes.object]),
  handleSelectMerchant: PropTypes.func.isRequired,
  onEditCompanyButtonToggle: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  responseMessages: PropTypes.shape({
    successMessage: PropTypes.string,
    errors: PropTypes.string,
  }),
  handleSelectStore: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    title: PropTypes.string.isRequired,
    pathName: PropTypes.string.isRequired,
    shopifyName: PropTypes.string.isRequired,
    merchant: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
  }).isRequired,
};

export default React.memo(withStyles(styles)(ManageCompaniesTab));
