import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, CardContent, Typography, CardMedia, Box, withStyles } from '@material-ui/core';

import ProductPopupContainer from 'commonComponents/ProductCard/containers/ProductPopupContainer';
import ProductCardInCart from '../ProductCardInCart';

import styles from './styles.js';

const ProductCard = ({
  classes,
  handleClick,
  product,
  image,
  style,
  isInCartView,
  removeHandler,
  handleItemQuantityChange,
  isDisabled,
}) => {
  const { variants = [] } = product;
  const cardRef = useRef(null);

  const [cardPosition, setCardPosition] = useState({ top: 0, left: 0 });
  const [showPopup, setShowPopup] = useState(false);

  const handleMouseOverCard = useCallback(() => {
    const cardCoordinates = cardRef && cardRef.current && cardRef.current.getBoundingClientRect();

    if (cardCoordinates) {
      setCardPosition({
        top: `${Math.round(
          cardCoordinates.top - document.body.getBoundingClientRect().top - 30
        )}px`,
        left: `${Math.round(cardCoordinates.left - 60)}px`,
      });
      setShowPopup(true);
    }
  }, [cardRef, setShowPopup]);

  const handleMouseOutCard = useCallback(() => {
    setShowPopup(false);
  }, [setShowPopup]);

  return (
    <Card
      ref={cardRef}
      className={clsx(isInCartView ? classes.cardInCart : classes.card, style && style)}
      onMouseOver={!isInCartView ? handleMouseOverCard : null}
      onMouseOut={!isInCartView ? handleMouseOutCard : null}
      onBlur={!isInCartView ? handleMouseOutCard : null}
      onFocus={!isInCartView ? handleMouseOverCard : null}
    >
      <CardMedia
        style={{ opacity: isDisabled ? '.3' : 1 }}
        component="img"
        draggable={false}
        alt="Banner"
        image={image}
        className={!isInCartView ? classes.productImage : classes.productImageInCart}
        onClick={handleClick}
      />
      {!isInCartView && (
        <ProductPopupContainer
          productInfo={product}
          handleClick={handleClick}
          cardPosition={cardPosition}
          showPopup={showPopup}
        />
      )}

      <CardContent className={classes.mainInfo}>
        {!isInCartView ? (
          <>
            <Box className={classes.brandInfo}>
              <Typography variant="body2">{product.companyName}</Typography>
              <Box className={classes.priceInfo}>
                {(variants[0].compareAtPrice > variants[0].mortarPrice ||
                  variants[0].marketPrice > variants[0].mortarPrice) && (
                  <Typography className={classes.oldPrice} variant="h4">
                    ${variants[0].compareAtPrice || variants[0].marketPrice}
                  </Typography>
                )}
                {(variants[0].mortarPrice || variants[0].marketPrice) && (
                  <Typography className={classes.newPrice} variant="h4" color="textPrimary">
                    ${variants[0].mortarPrice || variants[0].marketPrice}
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography className={classes.description} variant="h4">
              {product.title}
            </Typography>
            {product.isFinalSale && (
              <Typography className={classes.finalSale} variant="h4" color="error">
                Final Sale
              </Typography>
            )}
          </>
        ) : (
          <ProductCardInCart
            productName={product.title}
            newPrice={product.price}
            quantity={product.quantity}
            vendor={product.vendor}
            id={product.variantId}
            color={product.color}
            size={product.size}
            handleRemove={removeHandler}
            isDisabled={isDisabled}
            handleItemQuantityChange={handleItemQuantityChange}
          />
        )}
      </CardContent>
    </Card>
  );
};

ProductCard.defaultProps = {
  isInCartView: false,
  style: '',
  product: {},
  isDisabled: false,
  removeHandler: () => {},
  handleItemQuantityChange: () => {},
};

ProductCard.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  product: PropTypes.oneOfType([PropTypes.object]),
  handleClick: PropTypes.func.isRequired,
  removeHandler: PropTypes.func,
  handleItemQuantityChange: PropTypes.func,
  image: PropTypes.string.isRequired,
  isInCartView: PropTypes.bool,
  isDisabled: PropTypes.bool,
  style: PropTypes.string,
};

export default React.memo(withStyles(styles)(ProductCard));
