const styles = (theme) => ({
  customImageGallery: {
    '& .image-gallery-thumbnail': {
      opacity: 0.25,
      border: 'none',
    },
    '& .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus': {
      opacity: 1,
    },
    '& .image-gallery-thumbnail .image-gallery-thumbnail-image': {
      borderRadius: 5,
      width: '80px',
      height: '100px',
    },
    '& .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right': {
      margin: '0 10px 0 0',
      width: 80,
    },
    '& .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail': {
      marginTop: 10,
    },
    '& .image-gallery-slide .image-gallery-image': {
      borderRadius: 5,
    },
  },
  finalSale: {
    marginBottom: 10,
  },
  addToCartButton: {
    margin: '40px auto',
    width: 230,
    '&:hover svg': {
      filter: 'invert(1)',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  productName: {
    margin: '10px 0',
  },
  productInfo: {
    textAlign: 'center',
  },
  newPrice: {
    display: 'inline-block',
  },
  oldPrice: {
    textDecoration: 'line-through',
    display: 'inline-block',
    marginRight: 20,
  },
  colorPicker: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& span': {
      marginRight: 5,
    },
  },
  colorPickerLabel: {
    color: theme.palette.black,
    marginBottom: 10,
  },
  colorPickerContainer: {
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  sizePickerContainer: {
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
    },
  },
  sizePickerLabel: {
    color: theme.palette.black,
    marginBottom: 10,
  },
  sizePicker: {
    marginRight: 5,
  },
  recentlyViewed: {
    marginTop: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
      marginBottom: 20,
    },
  },
  fullScreenArea: {
    top: '0',
    width: '100%',
    position: 'absolute',
    height: '100%',
  },
  productImage: {
    borderRadius: 5,
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      width: 335,
      height: 395,
    },
    [theme.breakpoints.down('xs')]: {
      width: 185,
      height: 245,
    },
  },
  breadcrumbWrapper: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  carousel: {
    '& ::-webkit-scrollbar': {
      height: 0,
    },
  },
  commomDialog: {
    padding: 0,
    height: '100%',
    width: '100%',
  },
  originalProductImageWrapper: {
    height: '100%',
  },
  originalProductImage: {
    height: 'auto',
    width: 'auto%',
  },
  closeButton: {
    minWidth: 20,
    maxWidth: 20,
    height: 20,
    padding: 0,
    marginLeft: 'auto',
    position: 'absolute',
    top: 20,
    right: 20,
    '& span': {
      margin: 0,
    },
  },
  item: {
    maxWidth: 230,
  },
});

export default styles;
