import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Card, Typography, CardMedia, Box, Paper, withStyles } from '@material-ui/core';

import ProductPropertyInfo from 'commonComponents/ProductPropertyInfo';

import styles from './styles.js';

const OrderItem = ({ classes, productInfo, style, modalStyles }) => {
  return (
    <Card className={clsx(classes.card, style && style)}>
      <CardMedia
        component="img"
        draggable={false}
        alt="OrderItem"
        image={productInfo.image}
        className={classes.orderImage}
      />
      <Paper className={clsx(classes.orderInfo, modalStyles && modalStyles)}>
        <CardMedia
          component="img"
          draggable={false}
          alt="ItemImage"
          image={productInfo.image}
          className={classes.itemImage}
        />
        <Typography variant="h4">{productInfo.productName}</Typography>
        <Box className={classes.additionalInfo}>
          <ProductPropertyInfo title="Color" value={productInfo.color} />
          <ProductPropertyInfo title="Size" value={productInfo.size} />
          <ProductPropertyInfo title="Price" value={`$${productInfo.price}`} />
          <ProductPropertyInfo title="Quantity" value={productInfo.quantity} />
        </Box>
      </Paper>
    </Card>
  );
};

OrderItem.defaultProps = {
  style: '',
  modalStyles: '',
};

OrderItem.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  productInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  style: PropTypes.string,
  modalStyles: PropTypes.string,
};

export default React.memo(withStyles(styles)(OrderItem));
