import React from 'react';
import PropTypes from 'prop-types';
import { Box, withStyles, Link, Drawer, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import CommonButton from 'commonComponents/CommonButton';
import { wpLinksConfig } from 'constants/links';
import { useRouteToGo } from 'helpers/hooks/useRouteToGo';
import { PEOPLE_CATEGORIES } from 'constants/peopleCategories';
import palette from 'theme/palette';
import ROUTES from 'routes';

import styles from './styles';

const MainMenu = ({
  classes,
  isFilterMenuOpen,
  handleOpenMainMenu,
  handleShowFinder,
  handleOpenPage,
  handlePeopleFilterChange,
  filterByPeopleCategory,
}) => {
  const goToCategories = useRouteToGo(ROUTES.CATEGORIES);
  const goToBrandsList = useRouteToGo(ROUTES.BRANDS_LIST);
  const goToFavorites = useRouteToGo(ROUTES.FAVORITES);

  return (
    <Drawer
      anchor="left"
      open={isFilterMenuOpen}
      classes={{ paper: classes.filtersMenu }}
      onClose={() => handleOpenMainMenu(false)}
    >
      <Box className={classes.menuWrapper}>
        <CommonButton
          className={classes.closeButton}
          startIcon={<CloseIcon />}
          label=""
          variant="text"
          size="large"
          onClick={() => handleOpenMainMenu(false)}
        />
        <Box className={classes.filters}>
          <CommonButton
            label="Women"
            variant="text"
            color="secondary"
            className={classes.womenFilter}
            style={{ color: filterByPeopleCategory === PEOPLE_CATEGORIES.WOMEN && palette.black }}
            onClick={() => {
              handlePeopleFilterChange(PEOPLE_CATEGORIES.WOMEN);
              handleOpenMainMenu(false);
            }}
          />
          <CommonButton
            label="Men"
            variant="text"
            color="secondary"
            className={classes.filterButton}
            style={{ color: filterByPeopleCategory === PEOPLE_CATEGORIES.MEN && palette.black }}
            onClick={() => {
              handlePeopleFilterChange(PEOPLE_CATEGORIES.MEN);
              handleOpenMainMenu(false);
            }}
          />
        </Box>
        <CommonButton
          label="Search"
          variant="text"
          color="secondary"
          className={classes.pageButton}
          onClick={() => {
            handleShowFinder(true);
            handleOpenMainMenu(false);
          }}
        />
        <CommonButton
          label="All Brands"
          variant="text"
          color="secondary"
          className={classes.pageButton}
          onClick={() => handleOpenPage(goToBrandsList)}
        />
        <CommonButton
          label="Categories"
          variant="text"
          color="secondary"
          className={classes.pageButton}
          onClick={() => handleOpenPage(goToCategories)}
        />
        <CommonButton
          label="Favorites"
          variant="text"
          color="secondary"
          className={classes.pageButton}
          onClick={() => handleOpenPage(goToFavorites)}
        />
      </Box>
      <Divider />
      <Box className={classes.menuWrapper}>
        {wpLinksConfig.map((page) => (
          <Link target="_blank" key={page.title} href={page.link}>
            <CommonButton label={page.title} variant="text" color="secondary" />
          </Link>
        ))}
      </Box>
    </Drawer>
  );
};

MainMenu.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleOpenMainMenu: PropTypes.func.isRequired,
  handleOpenPage: PropTypes.func.isRequired,
  isFilterMenuOpen: PropTypes.bool.isRequired,
  handleShowFinder: PropTypes.func.isRequired,
  filterByPeopleCategory: PropTypes.string.isRequired,
  handlePeopleFilterChange: PropTypes.func.isRequired,
};

export default React.memo(withStyles(styles)(MainMenu));
