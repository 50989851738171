import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { withStyles } from '@material-ui/core/styles';
import { Collapse, TableRow, Grid } from '@material-ui/core';

import Totals from 'modules/CartPage/components/Totals';
import StyledCell from '../StyledCell';

import styles from './styles.js';
import OrderItem from '../OrderItem';

const OrderRow = ({ order, classes }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        className={open ? classes.selectedRow : classes.commonRow}
        onClick={() => setOpen(!open)}
      >
        <StyledCell withTopBorder={open}>{order.status}</StyledCell>
        <StyledCell>{order.date}</StyledCell>
        <StyledCell>#{order.orderNumber}</StyledCell>
        <StyledCell>
          {Object.keys(order.orderInfo).map((companyName, index) =>
            index === 0 ? companyName : `, ${companyName}`
          )}
        </StyledCell>
        <StyledCell withTopBorder={open}>Total Items: {order.totalItems}</StyledCell>
      </TableRow>
      <TableRow className={classes.collapsibleRow}>
        <StyledCell withBottomBorder style={classes.emptyCell} />
        <StyledCell withBottomBorder style={classes.emptyCell} />
        <StyledCell withBottomBorder style={classes.contentCell}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Totals
              style={classes.totals}
              totalItemsPrice={`$${order.totalInfo.totalItemsPrice.toFixed(2)}`}
              shippingPrice={`$${order.totalInfo.shippingPrice.toFixed(2)}`}
              taxesPrice={`$${order.totalInfo.taxesPrice.toFixed(2)}`}
              totalAmount={`$${order.totalInfo.totalAmount.toFixed(2)}`}
            />
          </Collapse>
        </StyledCell>
        <StyledCell style={classes.contentCell}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {Object.keys(order.orderInfo).map((companyName) => {
              const currentCompany = order.orderInfo[companyName];
              const { contacts, serviceLinks } = currentCompany;
              const additionalInfo = { contacts, serviceLinks };

              return (
                <Totals
                  key={`${uuid()}`}
                  style={classes.totals}
                  withAdditionalInfo
                  additionalInfo={additionalInfo}
                  additionalInfoStyle={classes.additionalInfoTotals}
                />
              );
            })}
          </Collapse>
        </StyledCell>
        <StyledCell withBottomBorder style={classes.contentCell}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {Object.keys(order.orderInfo).map((companyName) => {
              return (
                <Grid
                  container
                  key={`${companyName}-${uuid()}`}
                  spacing={2}
                  className={classes.productCell}
                >
                  {order.orderInfo[companyName].products.map((product) => {
                    return (
                      <Grid key={`${companyName}-${uuid()}`} item md={4} lg={3}>
                        <OrderItem productInfo={product} />
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
          </Collapse>
        </StyledCell>
      </TableRow>
      <TableRow className={classes.divider} />
    </>
  );
};

OrderRow.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  order: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default React.memo(withStyles(styles)(OrderRow));
