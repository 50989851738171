const styles = (theme) => ({
  gridWrapper: {
    maxWidth: 700,
  },
  divider: {
    margin: '25px 0',
  },
  areaTitle: {
    textAlign: 'center',
    marginBottom: 25,
  },
});

export default styles;
