import { createAction } from 'redux-actions';

export const LINK_STRIPE_ACCOUNT = createAction('LINK_STRIPE_ACCOUNT_REQUEST');
export const LINK_STRIPE_ACCOUNT_SUCCESS = createAction('LINK_STRIPE_ACCOUNT_SUCCESS');
export const LINK_STRIPE_ACCOUNT_FAIL = createAction('LINK_STRIPE_ACCOUNT_FAIL');

export const UNLINK_STRIPE_ACCOUNT = createAction('UNLINK_STRIPE_ACCOUNT_REQUEST');
export const UNLINK_STRIPE_ACCOUNT_SUCCESS = createAction('UNLINK_STRIPE_ACCOUNT_SUCCESS');
export const UNLINK_STRIPE_ACCOUNT_FAIL = createAction('UNLINK_STRIPE_ACCOUNT_FAIL');

export const SET_ONBOARDING_LINK = createAction('SET_ONBOARDING_LINK');
export const CLEAR_PAYMENT_TAB_MESSAGE = createAction('CLEAR_PAYMENT_TAB_MESSAGE');
