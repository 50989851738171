import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

import styles from './styles';

const TabPanel = ({ children, value, index, classes, ...other }) => {
  return (
    <div
      role="tabpanel"
      className={classes.tabContent}
      hidden={value !== index}
      id={`${uuid()}-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default React.memo(withStyles(styles)(TabPanel));
