import { takeEvery, put } from 'redux-saga/effects';

import {
  UPDATE_COMPANY_INFO_SUCCESS,
  DELETE_COMPANY_SUCCESS,
  CREATE_COMPANY_SUCCESS,
  GET_ALL_MERCHANTS,
  GET_ALL_STORES,
} from '../actions';

function* updateCompanySuccess() {
  yield put(GET_ALL_MERCHANTS());
  yield put(GET_ALL_STORES());
}

function* manageCompaniesSaga() {
  yield takeEvery(
    [UPDATE_COMPANY_INFO_SUCCESS, DELETE_COMPANY_SUCCESS, CREATE_COMPANY_SUCCESS],
    updateCompanySuccess
  );
}

export default manageCompaniesSaga;
