import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { getStringQuery } from 'helpers/getStringQuery';
import ROUTES from 'routes';
import Loading from 'commonComponents/Loading';
import {
  GET_PRODUCTS_BY_QUERY,
  GET_PRODUCTS_BY_CATEGORIES,
  SET_LOGIN_MODAL_SHOWED,
} from 'commonComponents/layouts/CommonLayout/actions';
import CommonLayout from '../components/CommonLayout';

const CommonLayoutContainer = ({ children, isLoading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  const { isAuth } = useSelector((state) => state.user);
  const { categories, filterByPeopleCategory } = useSelector((state) => state.global);
  const { success } = useSelector((state) => state.register);
  const {
    foundProductsByQuery,
    foundProductsByCategories,
    isLoadingData,
    isLoginModalShowed,
  } = useSelector((state) => state.commonLayout);

  const [searchInput, setSearchInput] = useState('');
  const [showFinder, setShowFinder] = useState(false);
  const [isResetPasswordModalShowed, setIsResetPasswordModalShowed] = useState(false);
  const [isRegisterModalShowed, setIsRegisterModalShowed] = useState(false);

  const handleSearchData = useCallback(
    debounce((query) => dispatch(GET_PRODUCTS_BY_QUERY(query)), 500),
    []
  );

  const handleSearchProductsByCategories = useCallback(
    debounce((ids) => dispatch(GET_PRODUCTS_BY_CATEGORIES(ids)), 500),
    []
  );

  const onChangeFields = (event) => {
    const searchValue = event.target.value;

    setSearchInput(searchValue);

    if (searchValue && searchValue.length > 1) {
      handleSearchData(event.target.value);
    }
  };

  const foundCategories = useMemo(() => {
    if (categories.length > 0 && searchInput.length > 0) {
      const filteredCategories = categories.filter(
        (category) =>
          category.label.toLowerCase().indexOf(searchInput.toLowerCase()) + 1 &&
          category.section === filterByPeopleCategory
      );

      return filteredCategories;
    }
    return [];
  }, [searchInput, filterByPeopleCategory, categories]);

  const categoriesFilteredBySex = useMemo(() => {
    return categories.filter((category) => category.section === filterByPeopleCategory);
  }, [filterByPeopleCategory, categories]);

  const foundProducts = useMemo(() => {
    if (categories.length > 0 && foundProductsByQuery.length > 0) {
      const categoryIds = categoriesFilteredBySex.map(({ _id }) => _id);

      const products = foundProductsByQuery.filter((product) =>
        product.categories.some((id) => categoryIds.includes(id))
      );

      return products;
    }
    return [];
  }, [foundProductsByQuery, categoriesFilteredBySex, categories]);

  useEffect(() => {
    if (foundCategories && foundCategories.length > 0) {
      handleSearchProductsByCategories(getStringQuery(foundCategories, true));
    }
  }, [categories, searchInput, foundCategories, handleSearchProductsByCategories]);

  useEffect(() => {
    if (isAuth) {
      dispatch(SET_LOGIN_MODAL_SHOWED(false));
    }
  }, [isAuth, dispatch]);

  useEffect(() => {
    if (success) {
      setIsRegisterModalShowed(false);
    }
  }, [success]);

  const handleShowLoginModal = useCallback(
    (modalState) => {
      dispatch(SET_LOGIN_MODAL_SHOWED(modalState));
    },
    [dispatch]
  );

  const handleShowResetPasswordModal = useCallback(
    (modalState) => {
      setIsResetPasswordModalShowed(modalState);
    },
    [setIsResetPasswordModalShowed]
  );

  const handleShowRegisterModal = useCallback(
    (modalState) => {
      setIsRegisterModalShowed(modalState);
    },
    [setIsRegisterModalShowed]
  );

  const handleShowFinder = useCallback(
    (value) => {
      setShowFinder(value);
    },
    [setShowFinder]
  );

  const handleClickCard = useCallback(
    (productName, productId) => {
      history.push(`${ROUTES.PRODUCT_PAGE}/${productName}-${productId}`);
    },
    [history]
  );

  const groupedProducts = useMemo(() => {
    if (foundProductsByCategories.length > 0 && foundCategories.length > 0) {
      const sortedData = foundCategories.map((category) => {
        const products = foundProductsByCategories.filter((product) =>
          product.categories.includes(category._id)
        );

        return {
          name: category.label,
          products,
        };
      });
      return sortedData;
    }
    return [];
  }, [foundProductsByCategories, foundCategories]);

  const isFindAreaVisible = useMemo(() => showFinder && searchInput.length > 1, [
    showFinder,
    searchInput,
  ]);

  return (
    <>
      <CommonLayout
        content={children}
        isLoginModalShowed={isLoginModalShowed}
        isResetPasswordModalShowed={isResetPasswordModalShowed}
        isRegisterModalShowed={isRegisterModalShowed}
        handleShowLoginModal={handleShowLoginModal}
        handleShowResetPasswordModal={handleShowResetPasswordModal}
        handleShowRegisterModal={handleShowRegisterModal}
        searchInput={searchInput}
        onChangeSearchInput={onChangeFields}
        foundProducts={foundProducts}
        handleClickCard={handleClickCard}
        handleShowFinder={handleShowFinder}
        showFinder={showFinder}
        isFindAreaVisible={isFindAreaVisible}
        isLoadingSearchData={isLoadingData}
        groupedProducts={groupedProducts}
      />
      <Loading isLoading={isLoading} />
    </>
  );
};

CommonLayoutContainer.defaultProps = {
  isLoading: false,
};

CommonLayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

export default React.memo(CommonLayoutContainer);
