import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ROUTES from 'routes';
import { getStringQuery } from 'helpers/getStringQuery';
import { GET_PRODUCTS_INFO } from '../actions';
import RecentlyViewedPage from '../components/RecentlyViewedPage';

const RecentlyViewedPageContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { products } = useSelector((state) => state.recentlyViewed);

  const getProductsInfo = useCallback(() => {
    if (products.length > 0) {
      dispatch(GET_PRODUCTS_INFO(getStringQuery(products, true)));
    }
  }, [products, dispatch]);

  useEffect(getProductsInfo, []);

  const handleClickProductCard = useCallback(
    (productName, productId) => {
      history.push(`${ROUTES.PRODUCT_PAGE}/${productName}-${productId}`);
    },
    [history]
  );

  return <RecentlyViewedPage products={products} handleClickProductCard={handleClickProductCard} />;
};

export default React.memo(RecentlyViewedPageContainer);
