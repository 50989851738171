const styles = (theme) => ({
  dialogContainer: {
    padding: '23px',
    backdropFilter: 'blur(200px)',
    ' -webkit-backdrop-filter': 'blur(200px)',
    background: theme.palette.background.primary,
    borderRadius: '10px',
  },
});

export default styles;
