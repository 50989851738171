import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Typography, Grid, Divider, withStyles } from '@material-ui/core';
import { startCase } from 'lodash';

import ROUTES from 'routes';
import { useRouteToGo } from 'helpers/hooks/useRouteToGo';
import CommonButton from 'commonComponents/CommonButton';
import { STEPS } from 'modules/CheckoutModule/config';
import { calculateTotals } from '../../../../CartPage/utils/calculateTotals';
import { CREATE_PAYMENT_INTENT, SET_TOTAL_ORDER_PRICE } from '../../../actions';

import styles from './styles';

const PaymentStep = ({ classes, handleSetActiveStep, handleCloseModal }) => {
  const [isCheckoutButtonDisabled, setDisabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const handleGoToOrders = useRouteToGo(ROUTES.ORDERS_PAGE);

  const checkoutSelector = useSelector((state) => state.checkout);
  const { orderList } = useSelector((state) => state.cart);
  const { _id: userId } = useSelector((state) => state.user);

  useEffect(() => {
    setTimeout(() => {
      if (checkoutSelector.isSuccess) {
        handleGoToOrders();
      }

      setDisabled(false);
    }, 1000);
  }, [checkoutSelector.messages, handleGoToOrders, checkoutSelector.isSuccess]);

  const paymentInformation = useMemo(() => {
    const {
      firstName,
      lastName,
      phone,
      email,
      taxes,
      state,
      address,
      additionalAddress,
      city,
      zip,
      shipments,
      totalOrderPrice,
    } = checkoutSelector;

    return {
      orderDetails: {
        firstName,
        lastName,
        phone,
        email,
        state,
        address,
        additionalAddress,
        city,
        zip,
        shipments,
        taxes,
        totalOrderPrice,
      },
      orderItemsList: orderList,
      userId,
    };
  }, [userId, checkoutSelector, orderList]);

  const totalOrderPrice = useMemo(() => {
    let result = 0;
    let totalTaxes = 0;

    Object.entries(orderList).forEach((orderSubArray) => {
      const shipmentPrice = checkoutSelector.shipments.find(
        (shipment) => shipment.company === orderSubArray[0]
      ).currentVariant.price;

      const foundTaxObject = checkoutSelector.taxes.find(
        (tax) => tax.storeId === orderSubArray[1].storeId
      );

      const taxValue = foundTaxObject ? foundTaxObject.tax : 0;

      const totalItemCost = calculateTotals(orderSubArray[1].products).totalItemsCost;
      totalTaxes += totalItemCost * taxValue;

      result += shipmentPrice + totalItemCost;
    });

    return { orderSum: result, taxValue: totalTaxes };
  }, [orderList, checkoutSelector.taxes, checkoutSelector.shipments]);

  useEffect(() => {
    const totalOrderInfo = { ...totalOrderPrice };

    totalOrderInfo.taxValue = +totalOrderPrice.taxValue.toFixed(2);

    totalOrderInfo.orderSum = +(totalOrderPrice.orderSum + totalOrderPrice.taxValue).toFixed(2);

    dispatch(SET_TOTAL_ORDER_PRICE(totalOrderInfo));
  }, [dispatch, totalOrderPrice]);

  const handleCardInputChange = useCallback((event) => {
    if (event.error) {
      console.log(event.error);
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);

    try {
      const cardElement = elements.getElement(CardElement);

      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          address: {
            city: paymentInformation.orderDetails.city,
            country: 'US',
            line1: paymentInformation.orderDetails.address,
            line2: paymentInformation.orderDetails.additionalAddress || '',
            postal_code: paymentInformation.orderDetails.zip,
            state: paymentInformation.orderDetails.state,
          },
          name: paymentInformation.orderDetails.firstName,
          phone: paymentInformation.orderDetails.phone,
          email: paymentInformation.orderDetails.email,
        },
      });

      const source = await stripe.createToken(cardElement, {
        type: 'card',
        currency: 'usd',
        usage: 'reusable',
      });

      dispatch(
        CREATE_PAYMENT_INTENT({
          ...paymentInformation,
          paymentMethod: paymentMethod.paymentMethod,
          source,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const paymentData = useMemo(() => {
    let paymentMethod = '';

    Object.keys(orderList).forEach((key, index) => {
      const { name } = checkoutSelector.shipments.find(
        (shipment) => shipment.company === key
      ).currentVariant;

      paymentMethod += ` ${startCase(key)} - ${name}${
        Object.keys(orderList).length > index + 1 ? ',' : ''
      }`;
    });

    const { address, additionalAddress, city, state, zip } = checkoutSelector;

    return [
      {
        title: 'Contact',
        data: checkoutSelector.email,
        handleClickChangeData: () =>
          handleSetActiveStep(
            STEPS.findIndex(({ stepTitle }) => stepTitle === 'Contact Information')
          ),
      },
      {
        title: 'Ship to',
        data: `${address}, ${additionalAddress}, ${city}, ${state}, ${zip}`,
        handleClickChangeData: () =>
          handleSetActiveStep(
            STEPS.findIndex(({ stepTitle }) => stepTitle === 'Contact Information')
          ),
      },
      {
        title: 'Method',
        data: paymentMethod,
        handleClickChangeData: () =>
          handleSetActiveStep(
            STEPS.findIndex(({ stepTitle }) => stepTitle === 'Shipping Information')
          ),
      },
    ];
  }, [checkoutSelector, handleSetActiveStep, orderList]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justify="center" spacing={4}>
        <Grid container item xs={12} className={classes.personalInfoBox}>
          {paymentData.map(({ data, title, handleClickChangeData }, index) => (
            <React.Fragment key={title}>
              <Grid item xs={12} sm={2}>
                <Typography variant="body1" className={classes.personalInfoLabel}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="body1" color="primary" className={classes.personalSummaryInfo}>
                  {data}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} className={classes.personalInfoChangeButton}>
                <CommonButton variant="text" label="Change" onClick={handleClickChangeData} />
              </Grid>
              {paymentData.length > index + 1 && (
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              )}
            </React.Fragment>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" color="primary">
            Payment
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">All transactions are secure and encrypted.</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardElement onChange={handleCardInputChange} className={classes.stripeComponent} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CommonButton
            isDisabled={isCheckoutButtonDisabled}
            label="Pay Now"
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.stripeCheckoutButton}
          />
        </Grid>
        <Grid container item xs={12} className={classes.personalInfoBox}>
          {Object.keys(orderList).map((key) => {
            const { price } = checkoutSelector.shipments.find(
              (shipment) => shipment.company === key
            ).currentVariant;

            const { totalItemsCost } = calculateTotals(orderList[key].products, price);

            const foundTaxObject = checkoutSelector.taxes.find(
              (taxObject) => taxObject.storeId === orderList[key].storeId
            );
            const taxes = foundTaxObject ? foundTaxObject.tax : 0;
            const subTotalPrice = totalItemsCost - price - taxes;

            return (
              <React.Fragment key={key}>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body1" className={classes.personalInfoLabel}>
                    {startCase(key)}
                  </Typography>
                </Grid>
                <Grid container item xs={12} sm={8}>
                  <Grid item xs={6} sm={3}>
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.personalInfoLabel}
                    >
                      Subtotal
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={9}>
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.personalInfoText}
                    >
                      ${subTotalPrice.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.personalInfoLabel}
                    >
                      Shipping
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={9}>
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.personalInfoText}
                    >
                      ${price.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.personalInfoLabel}
                    >
                      Tax
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={9}>
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.personalInfoText}
                    >
                      ${taxes.toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.personalInfoLabel}
                    >
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={9}>
                    <Typography
                      variant="body1"
                      color="primary"
                      className={classes.personalInfoText}
                    >
                      ${totalItemsCost.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2} className={classes.personalInfoChangeButton}>
                  <CommonButton
                    variant="text"
                    label="Change"
                    className={classes.personalInfoChangeButton}
                    onClick={handleCloseModal}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </React.Fragment>
            );
          })}
          <Grid container item xs={12}>
            <Grid item xs={6} sm={4}>
              <Typography variant="body1" className={classes.personalInfoLabel}>
                Total Order
              </Typography>
            </Grid>
            <Grid item xs={6} sm={8}>
              <Typography variant="body1" color="primary" className={classes.personalInfoText}>
                ${checkoutSelector.totalOrderPrice.orderSum}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

PaymentStep.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleSetActiveStep: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default React.memo(withStyles(styles)(PaymentStep));
