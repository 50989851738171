import 'regenerator-runtime/runtime';
import { all } from 'redux-saga/effects';

import globalSaga from 'config/globalSaga';
import companyPageSaga from 'modules/CompanyPage/helpers/companyPageSaga';
import manageCompaniesSaga from 'modules/AdminPage/components/Tabs/SuperAdminTabs/ManageCompaniesTab/helpers/manageCompaniesSaga';
import apiCallsSaga from '../helpers/watchRequest';
import { authSaga } from '../helpers/authenticationWatcher/checkAuthSaga';
import getOnboardingLinkSaga from '../modules/CheckoutModule/sagas';
import synchronizeCartItemsSaga from '../modules/CartPage/sagas';

function* rootSaga() {
  yield all([
    authSaga(),
    apiCallsSaga(),
    companyPageSaga(),
    globalSaga(),
    manageCompaniesSaga(),
    getOnboardingLinkSaga(),
    synchronizeCartItemsSaga(),
  ]);
}

export default rootSaga;
