const styles = (theme) => ({
  brandsListContainer: {
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  companyItem: {
    marginRight: 0,
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 135,
    },
  },
  filters: {
    textAlign: 'center',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
    },
  },
  filterButton: {
    fontSize: 16,
    margin: '0 15px 0 25px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
});

export default styles;
