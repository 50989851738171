const styles = (theme) => ({
  closeButton: {
    color: theme.palette.black,
    minWidth: 20,
    maxWidth: 20,
    height: 40,
    position: 'absolute',
    top: 11,
    right: 15,
    padding: 0,
  },
  cancelWrapper: {
    marginBottom: 20,
  },
  submitButton: {
    marginTop: 20,
  },
  commonDialog: { borderRadius: 10 },
  dialogWrapper: {
    maxWidth: 324,
    textAlign: 'center',
    margin: 'auto',
    maginBotom: 53,
  },
  resetPassword: {
    cursor: 'pointer',
    fontSize: 12,
    color: theme.palette.black,
  },
  signInButton: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  signUpButton: {
    textAlign: 'left',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  topActions: {
    marginBottom: 20,
  },
  modalTitle: {
    color: theme.palette.black,
    marginBottom: 20,
  },
  inputAdornment: {
    position: 'absolute',
    right: '10px',
  },
});

export default styles;
