import React from 'react';
import ShippingOptionsStep from './components/Steps/ShippingOptionsStep';
import PersonalInformationStep from './components/Steps/PersonalInformationStep';
import PaymentStep from './components/Steps/PaymentStep';

export const STEPS = [
  {
    stepTitle: 'Contact Information',
    Component: (handleSetActiveStep) => (
      <PersonalInformationStep handleSetActiveStep={handleSetActiveStep} />
    ),
  },
  {
    stepTitle: 'Shipping Information',
    Component: (handleSetActiveStep) => (
      <ShippingOptionsStep handleSetActiveStep={handleSetActiveStep} />
    ),
  },
  {
    Component: (handleSetActiveStep, handleCloseModal) => (
      <PaymentStep handleSetActiveStep={handleSetActiveStep} handleCloseModal={handleCloseModal} />
    ),
  },
];
