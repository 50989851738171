import { combineActions, handleActions } from 'redux-actions';

import { GET_CATEGORIES, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAIL } from 'config/globalActions';
import * as actions from '../actions/index';

const defaultState = {
  companyInfo: {},
  categories: [],
  filteredProducts: [],
  productsByCategories: {},
  selectedCategory: '',
  pageCount: 0,
  errors: '',
  isLoading: false,
};

export default handleActions(
  {
    [combineActions(actions.GET_FILTERED_PRODUCTS, GET_CATEGORIES)](state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    [actions.GET_ALL_PRODUCTS_BY_CATEGORIES](state, { payload }) {
      const { productsByCategories } = state;
      const categoryId = payload;

      return {
        ...state,
        productsByCategories: { ...productsByCategories, [categoryId]: {} },
        isLoading: true,
      };
    },
    [actions.SELECT_CATEGORY](state, { payload = '' }) {
      return {
        ...state,
        selectedCategory: payload,
      };
    },
    [actions.UPDATE_FILTERED_PRODUCTS](state, { payload }) {
      return {
        ...state,
        filteredProducts: payload,
        isLoading: false,
        errors: null,
      };
    },
    [GET_CATEGORIES_SUCCESS](state, { payload }) {
      return {
        ...state,
        categories: payload.response.data,
        isLoading: false,
        errors: null,
      };
    },
    [actions.GET_FILTERED_PRODUCTS_SUCCESS](state, { payload = {} }) {
      return {
        ...state,
        filteredProducts: payload.response.data,
        pageCount: payload.response.pageCount,
        isLoading: false,
        errors: null,
      };
    },
    [actions.UPDATE_COMPANY_GROUPED_PRODUCTS](state, { payload = {} }) {
      return {
        ...state,
        productsByCategories: payload,
        isLoading: false,
        errors: null,
      };
    },
    [actions.GET_ALL_PRODUCTS_BY_CATEGORIES_SUCCESS](state, { payload = {} }) {
      const { productsByCategories } = state;
      const categoryId = payload.actionPayload;

      return {
        ...state,
        productsByCategories: { ...productsByCategories, [categoryId]: payload.response.data },
        isLoading: false,
        errors: null,
      };
    },
    [combineActions(
      actions.GET_FILTERED_PRODUCTS_FAIL,
      actions.GET_ALL_PRODUCTS_BY_CATEGORIES_FAIL,
      GET_CATEGORIES_FAIL
    )](state, { payload = {} }) {
      return {
        ...state,
        isLoading: false,
        errors: payload.response,
      };
    },
  },
  defaultState
);
