import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from 'commonComponents/Loading';
import { validateEmail } from 'helpers/validations';
import {
  validateConfirmPassword,
  validatePassword,
} from 'commonComponents/RegisterModal/helpers/validations';
import ClientsTab from '../components/ClientTab';
import {
  CLEAR_ADMIN_CLIENT_TAB_STATUS_MESSAGE,
  CREATE_MERCHANT,
  DELETE_MERCHANT,
  GET_MERCHANTS,
  SET_MERCHANT_ID_TO_REMOVE,
} from '../actions';

const ClientsTabContainer = () => {
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    role: 'merchant',
  });
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isContactsListModalOpen, toggleContactsListModalOpen] = useState(false);
  const [isCreateClientModalOpen, toggleCreateClientModalOpen] = useState(false);
  const [isRemoveClientModalOpen, toggleRemoveClientModalOpen] = useState(false);

  const isEmailValid = useMemo(() => validateEmail(formValues.email), [formValues.email]);
  const isPasswordValid = useMemo(() => validatePassword(formValues.password), [
    formValues.password,
  ]);
  const isConfirmPasswordValid = useMemo(
    () => validateConfirmPassword(formValues.password, formValues.confirmPassword),
    [formValues.password, formValues.confirmPassword]
  );
  const isFormDataValid = useMemo(() => {
    return isEmailValid && isPasswordValid && isConfirmPasswordValid;
  }, [isEmailValid, isPasswordValid, isConfirmPasswordValid]);

  useEffect(() => {
    dispatch(GET_MERCHANTS(pageNumber));
  }, [dispatch, pageNumber]);

  const {
    merchants,
    pageCount,
    successMessage,
    errors,
    isLoading,
    merchantIdToRemove,
  } = useSelector((state) => state.superAdminTabs.clientsTab);

  const handlePageUpdate = useCallback((page) => {
    setPageNumber(page);
  }, []);

  const handleContactsListModalOpen = useCallback((contacts) => {
    toggleContactsListModalOpen(true);
    setSelectedContacts(contacts);
  }, []);

  const handleCloseContactsListModal = useCallback(() => {
    toggleContactsListModalOpen(false);
    setSelectedContacts([]);
  }, []);

  const handleOpenCreateClientModal = useCallback(() => {
    toggleCreateClientModalOpen(true);
    setFormValues({
      email: '',
      password: '',
      confirmPassword: '',
      role: 'merchant',
    });
  }, []);

  const handleCloseCreateClientModal = useCallback(() => {
    toggleCreateClientModalOpen(false);
  }, []);

  const handleRemoveModalOpen = useCallback(
    (merchantId) => {
      dispatch(SET_MERCHANT_ID_TO_REMOVE(merchantId));

      toggleRemoveClientModalOpen(true);
    },
    [dispatch]
  );

  const handleRemoveModalClose = useCallback(() => {
    toggleRemoveClientModalOpen(false);
  }, []);

  const handleClientRemove = useCallback(() => {
    dispatch(DELETE_MERCHANT(merchantIdToRemove));

    handleRemoveModalClose();
  }, [merchantIdToRemove, dispatch, handleRemoveModalClose]);

  const handleFormValuesChange = useCallback(
    (event) => {
      return setFormValues({ ...formValues, [event.target.name]: event.target.value });
    },
    [formValues]
  );

  const handleClientCreate = useCallback(() => {
    const { email, password, role } = formValues;

    dispatch(CREATE_MERCHANT({ email, password, role }));

    handleCloseCreateClientModal();
  }, [dispatch, formValues, handleCloseCreateClientModal]);

  const handleResponseMessageClear = useCallback(() => {
    dispatch(CLEAR_ADMIN_CLIENT_TAB_STATUS_MESSAGE());
  }, [dispatch]);

  return (
    <>
      <ClientsTab
        clients={merchants}
        handlePageUpdate={handlePageUpdate}
        handleClientRemove={handleClientRemove}
        pageCount={pageCount}
        isCreateModalOpen={isCreateClientModalOpen}
        onOpenCreateClientModal={handleOpenCreateClientModal}
        onCloseCreateClientModal={handleCloseCreateClientModal}
        isRemoveClientModalOpen={isRemoveClientModalOpen}
        formValues={formValues}
        handleFormValuesChange={handleFormValuesChange}
        isFormDataValid={isFormDataValid}
        responseMessages={{ errors, successMessage }}
        handleResponseMessageClear={handleResponseMessageClear}
        handleClientCreate={handleClientCreate}
        handleRemoveModalOpen={handleRemoveModalOpen}
        handleRemoveModalClose={handleRemoveModalClose}
        pageNumber={pageNumber}
        selectedContacts={selectedContacts}
        handleContactsListModalOpen={handleContactsListModalOpen}
        isContactsListModalOpen={isContactsListModalOpen}
        handleCloseContactsListModal={handleCloseContactsListModal}
      />
      <Loading isLoading={isLoading} />
    </>
  );
};

export default React.memo(ClientsTabContainer);
