export const IMAGES_UPLOADING_CONFIG = {
  accept: 'image/jpeg, image/png',
  maxSize: 2097152,
  filesQuantity: 5,
};

export const VIDEO_UPLOADING_CONFIG = {
  accept: 'video/mp4, video/webm, video/mov',
  maxSize: 52428800 /* 50mb */,
  filesQuantity: 1,
};
