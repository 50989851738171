import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, withStyles, useTheme, useMediaQuery } from '@material-ui/core';

import MobileBreadcrumbs from 'commonComponents/MobileBreadcrumbs';
import CommonLayoutContainer from 'commonComponents/layouts/CommonLayout/containers/CommonLayoutContainer';
import { getFirstCompanyImage } from 'helpers/mediaHelpers.js';
import CompanyCard from 'commonComponents/CompanyCard';
import CommonButton from 'commonComponents/CommonButton';
import { useRouteToGo } from 'helpers/hooks/useRouteToGo';
import ROUTES from 'routes';

import styles from './styles.js';

const BrandsListPage = ({
  classes,
  handleClickCompanyCard,
  isLoading,
  companies,
  handleFollowCompany,
  follows,
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const goToCategories = useRouteToGo(ROUTES.CATEGORIES);
  const goToBrandsList = useRouteToGo(ROUTES.BRANDS_LIST);
  const goToFavorites = useRouteToGo(ROUTES.FAVORITES);

  return (
    <CommonLayoutContainer isLoading={isLoading}>
      <Grid container spacing={4} className={classes.brandsListContainer}>
        {!isMobileView ? (
          <Grid item xs={12}>
            <Box className={classes.filters}>
              <CommonButton
                label="Brands"
                variant="text"
                className={classes.filterButton}
                onClick={goToBrandsList}
              />
              <CommonButton
                label="Categories"
                variant="text"
                className={classes.filterButton}
                onClick={goToCategories}
              />
              <CommonButton
                label="Favorites"
                variant="text"
                className={classes.filterButton}
                onClick={goToFavorites}
              />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <MobileBreadcrumbs currentPage="All Brands" />
          </Grid>
        )}
        {companies.map((company) => (
          <Grid key={company._id} xs={12} sm={6} md={4} lg={3} item>
            <CompanyCard
              style={classes.companyItem}
              image={getFirstCompanyImage(company.media)}
              companyName={company.title}
              companyDescription={company.description}
              socialNetworks={company.socialNetworks}
              company={{
                title: company.title,
                description: company.description,
                image: getFirstCompanyImage(company.media),
                socialNetworks: company.socialNetworks,
              }}
              handleClick={() => handleClickCompanyCard(company.pathName)}
              handleFollowCompany={() => handleFollowCompany(company._id)}
              isFollow={follows.includes(company._id)}
            />
          </Grid>
        ))}
      </Grid>
    </CommonLayoutContainer>
  );
};

BrandsListPage.defaultProps = {
  companies: [],
  follows: [],
};

BrandsListPage.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  companies: PropTypes.oneOfType([PropTypes.array]),
  handleClickCompanyCard: PropTypes.func.isRequired,
  handleFollowCompany: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  follows: PropTypes.oneOfType([PropTypes.array]),
};

export default React.memo(withStyles(styles)(BrandsListPage));
