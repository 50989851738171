import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, Typography, Box, withStyles } from '@material-ui/core';

import CommonButton from 'commonComponents/CommonButton';
import CommonDialog from 'commonComponents/CommonDialog';

import styles from './styles';

const ContactsListModal = ({ classes, isOpen, handleModalToggle, selectedContacts }) => {
  return (
    <CommonDialog isOpen={isOpen} onClose={handleModalToggle} style={classes.commonDialog}>
      <Box className={classes.cancelWrapper}>
        <CommonButton
          className={classes.closeButton}
          startIcon={<CloseIcon />}
          label=""
          variant="text"
          size="large"
          onClick={handleModalToggle}
        />
      </Box>
      <Typography variant="h2" align="center" className={classes.modalTitle}>
        Contacts List
      </Typography>
      <Grid container spacing={4} className={classes.dialogWrapper}>
        {selectedContacts.map(({ email, name, phone, title }) => (
          <Grid key={email} item xs={6}>
            <Typography key={email} variant="h2" color="primary">
              {title}
            </Typography>
            <Typography key={email} variant="h3" color="textPrimary">
              {name}
            </Typography>
            <Typography key={email} variant="h3" color="textPrimary">
              {email}
            </Typography>
            <Typography key={email} variant="h3" color="textPrimary">
              {phone}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </CommonDialog>
  );
};

ContactsListModal.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleModalToggle: PropTypes.func.isRequired,
  selectedContacts: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default React.memo(withStyles(styles)(ContactsListModal));
