import { put, takeEvery, select } from 'redux-saga/effects';

import { GET_USER_INFO } from 'modules/ProfilePage/components/Tabs/ProfileTab/actions';
import { LOGIN_SUCCESS } from 'commonComponents/LoginModal/actions';
import * as selectors from './selectors';
import * as actions from './globalActions';

function* getUserInfo() {
  const _id = yield select(selectors.userId);

  if (_id) {
    yield put(GET_USER_INFO(_id));
  }
}

function* globalSaga() {
  const categories = yield select(selectors.categories);

  if (categories.length === 0) {
    yield put(actions.GET_CATEGORIES());
  }

  yield takeEvery([actions.GET_CATEGORIES_SUCCESS, LOGIN_SUCCESS], getUserInfo);
}

export default globalSaga;
