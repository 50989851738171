import React, { useMemo, useCallback, useEffect } from 'react';
import { Typography, Grid, withStyles } from '@material-ui/core';
import { startCase } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CommonButton from 'commonComponents/CommonButton';
import { STEPS } from 'modules/CheckoutModule/config';
import CommonSelect from 'commonComponents/CommonSelect';
import { CHANGE_SHIPPING_OPTIONS_FIELDS } from '../../../actions';
import { shipmentsMapper } from '../../../utils';

import styles from './styles';

const ShippingOptionsStep = ({ classes, handleSetActiveStep }) => {
  const dispatch = useDispatch();

  const { shippingInfo, shipments, state, address, additionalAddress, city, zip } = useSelector(
    (reduxState) => reduxState.checkout
  );
  const { orderList } = useSelector((reduxState) => reduxState.cart);

  const mappedShipments = useMemo(() => shipmentsMapper(orderList, shippingInfo), [
    orderList,
    shippingInfo,
  ]);

  useEffect(() => {
    dispatch(CHANGE_SHIPPING_OPTIONS_FIELDS(mappedShipments));
  }, [dispatch, mappedShipments, shipments.length, orderList]);

  const handleShippingOptionChange = useCallback(
    (event) => {
      const { name, value } = event.target;

      const shipmentsCopy = [...shipments];

      const foundCompanyToUpdate = shipmentsCopy.find((shipment) => shipment.company === name);

      foundCompanyToUpdate.currentVariant = foundCompanyToUpdate.variants.find(
        (variant) => variant.name === value
      );

      dispatch(CHANGE_SHIPPING_OPTIONS_FIELDS(shipmentsCopy));
    },
    [dispatch, shipments]
  );

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12} className={classes.personalInfoBox}>
        <Grid item xs={12} sm={2}>
          <Typography variant="body1" className={classes.personalInfoLabel}>
            Ship to
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="body1" color="primary" className={classes.personalSummaryInfo}>
            {`${address}, ${additionalAddress}, ${city}, ${state}, ${zip}`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} className={classes.personalInfoChangeButton}>
          <CommonButton
            variant="text"
            label="Change"
            onClick={() =>
              handleSetActiveStep(
                STEPS.findIndex(({ stepTitle }) => stepTitle === 'Contact Information')
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" color="primary">
          Shipping Method
        </Typography>
      </Grid>
      {shipments.map(({ company, variants, currentVariant }) => {
        return (
          <Grid item key={company} xs={12} sm={6}>
            <CommonSelect
              items={variants.map((variant) => {
                return {
                  value: variant.name,
                  label: `${variant.name} (${variant.price ? `$${variant.price}` : 'Free'})`,
                };
              })}
              name={company}
              value={currentVariant.name}
              label={startCase(company)}
              onChange={handleShippingOptionChange}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

ShippingOptionsStep.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleSetActiveStep: PropTypes.func.isRequired,
};

export default React.memo(withStyles(styles)(ShippingOptionsStep));
