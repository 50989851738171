import { createAction } from 'redux-actions';

export const GET_SHIPPING_INFO_REQUEST = createAction('GET_SHIPPING_INFO_REQUEST');
export const GET_SHIPPING_INFO_SUCCESS = createAction('GET_SHIPPING_INFO_SUCCESS');
export const GET_SHIPPING_INFO_FAIL = createAction('GET_SHIPPING_INFO_FAIL');

export const CHANGE_CHECKOUT_DATA = createAction('CHANGE_CHECKOUT_DATA');
export const CHANGE_SHIPPING_OPTIONS_FIELDS = createAction('CHANGE_SHIPPING_OPTIONS_FIELDS');
export const SET_TOTAL_ORDER_PRICE = createAction('SET_TOTAL_ORDER_PRICE');

export const CREATE_PAYMENT_INTENT = createAction('CREATE_PAYMENT_INTENT_REQUEST');
export const CREATE_PAYMENT_INTENT_SUCCESS = createAction('CREATE_PAYMENT_INTENT_SUCCESS');
export const CREATE_PAYMENT_INTENT_FAIL = createAction('CREATE_PAYMENT_INTENT_FAIL');

export const CLEAR_PAYMENT_RESPONSE_MESSAGES = createAction('CLEAR_PAYMENT_RESPONSE_MESSAGES');
