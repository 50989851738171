import { handleActions } from 'redux-actions';

import * as actions from '../actions/index';

const defaultState = {
  products: [],
  isLoading: false,
  errors: null,
};

const recentlyViewedReducer = handleActions(
  {
    [actions.UPDATE_PRODUCTS]: (state, { payload }) => {
      if (Object.keys(payload).length !== 0 && payload.constructor === Object) {
        let { products } = state;

        products = products.filter((product) => product.id !== payload.id);
        products.unshift(payload);

        if (products.length > 20) {
          products.pop(payload);
        }

        return {
          ...state,
          products,
        };
      }
      return {
        ...state,
      };
    },
    [actions.GET_PRODUCTS_INFO]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.GET_PRODUCTS_INFO_SUCCESS]: (state, { payload = {} }) => {
      const { products } = state;

      const productsObj = products.reduce((productObj, currentProduct, index) => {
        productObj[currentProduct.id] = index;
        return productObj;
      }, {});

      payload.response.data.sort((a, b) => productsObj[a.id] - productsObj[b.id]);

      return {
        ...state,
        products: payload.response.data,
        isLoading: false,
        errors: null,
      };
    },
    [actions.GET_PRODUCTS_INFO_FAIL]: (state, { payload = {} }) => ({
      ...state,
      isLoading: false,
      errors: payload.response,
    }),
  },
  defaultState
);

export default recentlyViewedReducer;
