const styles = (theme) => ({
  autocompleteInput: {
    '& .MuiInputBase-input': {
      fontSize: 13,
      color: theme.palette.black,
    },
  },
  autocompleteList: {
    fontSize: 13,
    color: theme.palette.black,
  },
});

export default styles;
