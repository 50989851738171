import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { Box, withStyles, Grid, Container, Typography } from '@material-ui/core';
import { kebabCase } from 'lodash';

import CommonCarousel from 'commonComponents/CommonCarousel';
import Loading from 'commonComponents/Loading';
import { changeImageSize } from 'helpers/changeImageSize';
import ProductCard from 'commonComponents/ProductCard/components/ProductCard';

import styles from './styles';

const FoundProductsArea = ({
  classes,
  foundProducts,
  handleClickCard,
  groupedProducts,
  isLoadingSearchData,
}) => {
  return (
    <Box className={classes.foundProductsContainer}>
      <Container maxWidth="lg">
        <Loading isLoading={isLoadingSearchData} style={classes.loader} />
        <Grid spacing={4} container>
          {groupedProducts.map(
            (category) =>
              category.products.length > 0 && (
                <Grid key={`${category.name}-${uuid()}`} xs={12} item>
                  <CommonCarousel title={category.name}>
                    {category.products.map((product) => (
                      <ProductCard
                        key={product.id}
                        style={classes.item}
                        image={product.image && changeImageSize(product.image.src, 300, 300)}
                        product={product}
                        handleClick={() => handleClickCard(kebabCase(product.title), product._id)}
                      />
                    ))}
                  </CommonCarousel>
                </Grid>
              )
          )}
          {foundProducts.map((product) => (
            <Grid key={product.id} xs={6} sm={6} md={4} lg={3} item>
              <ProductCard
                image={product.image && changeImageSize(product.image.src, 300, 300)}
                product={product}
                className={classes.productCard}
                handleClick={() => handleClickCard(kebabCase(product.title), product._id)}
              />
            </Grid>
          ))}
          {groupedProducts.length === 0 && foundProducts.length === 0 && (
            <Grid item xs={12} className={classes.notFoundBlock}>
              <Typography variant="body1" color="textPrimary">
                No items has been found.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

FoundProductsArea.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  groupedProducts: PropTypes.oneOfType([PropTypes.array]).isRequired,
  isLoadingSearchData: PropTypes.bool.isRequired,
  handleClickCard: PropTypes.func.isRequired,
  foundProducts: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default React.memo(withStyles(styles)(FoundProductsArea));
