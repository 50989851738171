import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Link, withStyles } from '@material-ui/core';

import CompleteLinkingScreen from './CompleteLinkingScreeen';

import styles from './styles';

const PaymentSettingsTab = ({
  stripeId,
  classes,
  onboardingLink,
  unlinkStripeAccount,
  isStripeOnboardingCompleted,
}) => {
  return (
    <Box>
      {stripeId && isStripeOnboardingCompleted ? (
        <CompleteLinkingScreen
          linkedStripeId={stripeId}
          unlinkStripeAccount={unlinkStripeAccount}
        />
      ) : (
        <Box className={classes.stripeInfo}>
          <Typography variant="h2">
            Please link your{' '}
            <Link className={classes.stripeLink} href={onboardingLink}>
              stripe account
            </Link>{' '}
            to allow customers pay for your products
          </Typography>
        </Box>
      )}
    </Box>
  );
};

PaymentSettingsTab.defaultProps = {
  stripeId: '',
};

PaymentSettingsTab.propTypes = {
  stripeId: PropTypes.string,
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onboardingLink: PropTypes.string.isRequired,
  isStripeOnboardingCompleted: PropTypes.bool.isRequired,
  unlinkStripeAccount: PropTypes.func.isRequired,
};

export default React.memo(withStyles(styles)(PaymentSettingsTab));
