import { handleActions, combineActions } from 'redux-actions';

import {
  SET_ONBOARDING_LINK,
  LINK_STRIPE_ACCOUNT_SUCCESS,
  UNLINK_STRIPE_ACCOUNT_SUCCESS,
  LINK_STRIPE_ACCOUNT,
  LINK_STRIPE_ACCOUNT_FAIL,
  UNLINK_STRIPE_ACCOUNT_FAIL,
  UNLINK_STRIPE_ACCOUNT,
  CLEAR_PAYMENT_TAB_MESSAGE,
} from '../actions';

const defaultState = {
  onboardingLink: '',
  message: null,
  isSuccess: false,
};

const paymentSettings = handleActions(
  {
    [combineActions(LINK_STRIPE_ACCOUNT, UNLINK_STRIPE_ACCOUNT)](state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    [LINK_STRIPE_ACCOUNT_SUCCESS]: (state, { payload }) => ({
      ...state,
      message: 'Account was successfully linked',
      isSuccess: true,
      isLoading: false,
    }),
    [UNLINK_STRIPE_ACCOUNT_SUCCESS]: (state, { payload }) => ({
      ...state,
      message: 'Account was successfully unlinked',
      isSuccess: true,
      isLoading: false,
    }),
    [SET_ONBOARDING_LINK]: (state, { payload }) => ({
      ...state,
      onboardingLink: payload,
    }),
    [combineActions(LINK_STRIPE_ACCOUNT_FAIL, UNLINK_STRIPE_ACCOUNT_FAIL)](state) {
      return {
        ...state,
        message: 'Something bad happend',
        isSuccess: false,
        isLoading: false,
      };
    },
    [CLEAR_PAYMENT_TAB_MESSAGE]: (state) => ({
      ...state,
      message: null,
    }),
  },
  defaultState
);

export default paymentSettings;
