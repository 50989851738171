import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { withStyles, Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { ExportToCsv } from 'export-to-csv';

import palette from 'theme/palette';
import { tableIcons } from '../../constants';
import OrdersFilterPopup from '../OrdersFilterPopup';
import { getCompanyOrders } from '../../api';

import styles from './style';

const OrderDetailsTable = ({
  classes,
  shopifyId,
  formValues,
  getSummaryInfo,
  handleFormValuesChange,
  handleSetDefaultFilters,
  isAdmin,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const tableRef = React.createRef();

  const handleOpenFilters = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const columns = useMemo(() => {
    const merchantColumns = [
      { title: 'Order', field: 'orderNumber', type: 'numeric' },
      { title: 'Order Date', field: 'createdAt', type: 'date', defaultSort: 'desc' },
      { title: 'Sales', field: 'totalPrice', type: 'currency' },
      {
        title: 'Shipping',
        field: 'shippingLines[0].price',
        type: 'currency',
        sorting: false,
      },
      { title: 'Taxes', field: 'totalTax', type: 'currency', sorting: false },
      { title: 'Fees', field: 'applicationFee', type: 'currency', sorting: false },
      {
        title: 'Net',
        field: 'totalPrice',
        render: (rowData) => (
          <>
            {`$${(
              rowData.totalPrice -
              rowData.totalTax -
              (rowData.shippingLines[0] ? rowData.shippingLines[0].price : 0) -
              (rowData.applicationFee || 0)
            ).toFixed(2)}`}
          </>
        ),
      },
      {
        title: 'Fulfillment Status',
        field: 'fulfillmentStatus',
        render: (rowData) => <>{rowData.fulfillmentStatus || 'unfulfilled'}</>,
      },
      {
        title: 'Financial Status',
        field: 'financialStatus',
      },
    ];

    return isAdmin
      ? [{ title: 'Merchant', field: 'merchant', sorting: false }, ...merchantColumns]
      : merchantColumns;
  }, [isAdmin]);

  const handleClosePopup = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const getQueryParams = useCallback(
    (query, isGetOrdersRoute = false) => {
      const { orderBy, orderDirection } = query;

      let params = '';

      if (shopifyId) {
        params += `storeId=${shopifyId}&`;
      }

      if (orderBy && isGetOrdersRoute) {
        const direction = orderDirection === 'asc' ? 1 : -1;

        params += `order={"${orderBy.field}":${direction}}&`;
      }

      if (!isEmpty(formValues.dateFrom)) {
        params += `createdAtFrom=${formValues.dateFrom}&`;
      }

      if (!isEmpty(formValues.dateTo)) {
        params += `createdAtTo=${formValues.dateTo}&`;
      }

      if (!isEmpty(formValues.financialStatus)) {
        params += `financialStatus=${formValues.financialStatus}&`;
      }

      if (!isEmpty(formValues.fulfillmentStatus)) {
        params += `fulfillmentStatus=${formValues.fulfillmentStatus}&`;
      }

      return params;
    },
    [formValues, shopifyId]
  );

  const handleGetOrders = useCallback(
    (query) => {
      return new Promise((resolve, reject) => {
        getSummaryInfo({ shopifyId, query: getQueryParams(query) });

        getCompanyOrders({
          shopifyId,
          page: query.page + 1,
          limit: query.pageSize,
          query: getQueryParams(query, true),
        })
          .then((response) => response.data)
          .then((result) => {
            resolve({
              data: result.orders,
              page: query.page,
              totalCount: result.total,
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [getSummaryInfo, getQueryParams, shopifyId]
  );

  const handleExportData = useCallback(() => {
    let query = 'exportMode=true';

    if (shopifyId) {
      query += `&storeId=${shopifyId}`;
    }

    const getDataPromise = getCompanyOrders({
      shopifyId,
      page: 1,
      limit: 10,
      query,
    })
      .then((response) => response.data)
      .then((result) => result.orders);

    getDataPromise.then((result) => {
      const rows = result.map((row) => {
        const updatedRow = {
          orderNumber: row.orderNumber,
          createdAt: row.createdAt,
          totalPrice: row.totalPrice,
          shippingAmount: row.shippingLines[0] ? row.shippingLines[0].price : 0,
          totalTax: row.totalTax,
          fee: row.applicationFee,
          net: row.totalPrice,
          fulfillmentStatus: row.fulfillmentStatus,
          financialStatus: row.financialStatus,
        };

        return isAdmin ? { merchant: row.merchant, ...updatedRow } : updatedRow;
      });

      const headers = [
        'Order',
        'Order Date',
        'Order Amount',
        'Shipping Amount',
        'Taxes',
        'Fee',
        'Net',
        'Fulfillment Status',
        'Financial Status',
      ];

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        filename: 'Orders',
        headers: isAdmin ? ['Merchant', ...headers] : headers,
      };

      const csvExporter = new ExportToCsv(options);

      csvExporter.generateCsv(rows);
    });
  }, [shopifyId, isAdmin]);

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        tableRef={tableRef}
        columns={columns}
        detailPanel={({ lineItems }) => {
          return (
            <Grid container spacing={4} className={classes.orderDetails}>
              {lineItems.map((productItem) => (
                <React.Fragment key={productItem.id}>
                  <Grid item xs={4}>
                    <Typography variant="h4" color="primary">
                      Product Id
                    </Typography>
                    <Typography variant="body1" color="primary">
                      {productItem.productId}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h4" color="primary">
                      Price
                    </Typography>
                    <Typography variant="body1" color="primary">
                      ${productItem.price}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h4" color="primary">
                      Quantity
                    </Typography>
                    <Typography variant="body1" color="primary">
                      {productItem.quantity}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          );
        }}
        options={{
          headerStyle: {
            color: palette.black,
          },
          cellStyle: {
            color: palette.black,
            fontSize: 16,
          },
          pageSize: 10,
          //   exportButton: true,
          //   exportAllData: true,
          search: false,
          draggable: false,
          searchText: 'Search by Order number',
          searchFieldStyle: { minWidth: 250 },
        }}
        actions={[
          {
            icon: tableIcons.Export,
            tooltip: 'Export as CSV',
            isFreeAction: true,
            onClick: handleExportData,
          },
          {
            icon: tableIcons.Filter,
            tooltip: 'Filters',
            isFreeAction: true,
            onClick: handleOpenFilters,
          },
        ]}
        data={(query) => handleGetOrders(query)}
        title="Orders"
      />
      <OrdersFilterPopup
        classes={classes}
        anchorEl={anchorEl}
        handleClosePopup={handleClosePopup}
        formValues={formValues}
        handleFormValuesChange={handleFormValuesChange}
        handleSetDefaultFilters={handleSetDefaultFilters}
        tableRef={tableRef}
      />
    </>
  );
};

OrderDetailsTable.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  shopifyId: PropTypes.string.isRequired,
  getSummaryInfo: PropTypes.func.isRequired,
  handleFormValuesChange: PropTypes.func.isRequired,
  handleSetDefaultFilters: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  formValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default React.memo(withStyles(styles)(OrderDetailsTable));
