const styles = (theme) => ({
  companyItem: {
    marginRight: 0,
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  buttonStyle: {
    maxWidth: 300,
    [theme.breakpoints.down('650')]: {
      maxWidth: 'unset',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
  notFoundBlock: {
    textAlign: 'center',
  },
  product: {
    '& img': {
      height: 328,
      [theme.breakpoints.down('650')]: {
        height: 195,
      },
    },
  },
});

export default styles;
