import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ThemeProvider } from '@material-ui/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './static/fonts/font.css';
import './static/styles/common.css';
import './static/styles/reset.css';

import theme from './theme';
import { configureStore } from './store/configureStore';
import MainLayout from './commonComponents/layouts/MainLayout/components/MainLayout';

import { STRIPE_CONFIG } from './config/stripeConfig';

const store = configureStore();
const persistor = persistStore(store);
const stripePromise = loadStripe(STRIPE_CONFIG.PUBLIC_KEY);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Elements stripe={stripePromise}>
            <MainLayout />
          </Elements>
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
