const styles = (theme) => ({
  completeLinkingInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    textAlign: 'center',
  },
  unlinkButton: {
    maxWidth: 300,
    marginTop: 20,
  }
});

export default styles;
