const styles = (theme) => ({
  productDetails: {
    width: 350,
    background: theme.palette.white,
    boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.25)',
    borderRadius: 5,
    position: 'absolute',
    padding: '20px',
    textAlign: 'center',
    color: theme.palette.black,
    zIndex: 2,
    '&:hover': {
      display: 'block !important',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none !important',
    },
  },
  additionalInfo: {
    textAlign: 'left',
  },
  largeProductImage: {
    borderRadius: 5,
    height: 290,
    cursor: 'pointer',
    marginBottom: 10,
  },
  mainInfo: { marginTop: 10, color: theme.palette.black },
  brandInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap-reverse',
      marginBottom: 3,
      '& .MuiTypography-body2': {
        width: '100%',
      },
      '& $oldPrice': {
        fontSize: 12,
      },
    },
  },
  priceInfo: {
    display: 'flex',
  },
  newPrice: {
    marginLeft: 10,
  },
  oldPrice: {
    textDecoration: 'line-through',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 3,
    },
  },
  description: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  colorPickerContainer: {
    marginTop: 10,
  },
  colorPicker: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& span': {
      marginRight: 5,
      marginBottom: 10,
    },
  },
  sizePickerContainer: {},
  addToCartButton: {
    marginTop: 10,
    '&:hover svg': {
      filter: 'invert(1)',
    },
  },
});

export default styles;
