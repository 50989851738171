import api from '../../../../../../../api/config';

export const getAllCompanies = (page) => api.get(`/companies?page=${page}&limit=10`);
export const deleteCompany = (id) => api.delete(`/admin/company/${id}`);
export const createCompany = (body) => api.post(`/admin/company`, body);
export const updateCompany = (formData) => {
  const merchantId = formData.get('merchantId');

  return api.patch(`/admin/company/${merchantId}`, formData);
};

export const getAllMerchants = () => api.get(`/user/merchants`);
export const getAllStores = () => api.get(`/stores`);
export const getMerchantById = (id) => api.get(`/user/${id}`);
