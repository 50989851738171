export const sortMedia = (mediaFiles = []) => {
  if (!Array.isArray(mediaFiles)) {
    return {};
  }

  return mediaFiles.reduce(
    (initial, mediaFile) => {
      const fileItem = {
        id: mediaFile._id,
        original: mediaFile.url,
        mimeType: mediaFile.mimeType,
        name: mediaFile.name,
      };

      if (mediaFile.mimeType.startsWith('image')) {
        initial.images.push(fileItem);
      } else {
        initial.video.push(fileItem);
      }

      return initial;
    },
    { video: [], images: [] }
  );
};
