import { createAction } from 'redux-actions';

export const GET_COMPANY = createAction('GET_COMPANY_REQUEST');
export const GET_COMPANY_SUCCESS = createAction('GET_COMPANY_SUCCESS');
export const GET_COMPANY_FAIL = createAction('GET_COMPANY_FAIL');

export const GET_FILTERED_COMPANY_PRODUCTS = createAction('GET_FILTERED_COMPANY_PRODUCTS_REQUEST');
export const GET_FILTERED_COMPANY_PRODUCTS_SUCCESS = createAction(
  'GET_FILTERED_COMPANY_PRODUCTS_SUCCESS'
);
export const GET_FILTERED_COMPANY_PRODUCTS_FAIL = createAction(
  'GET_FILTERED_COMPANY_PRODUCTS_FAIL'
);

export const GET_PRODUCTS_GROUPED_BY_CATEGORIES = createAction(
  'GET_PRODUCTS_GROUPED_BY_CATEGORIES_REQUEST'
);
export const GET_PRODUCTS_GROUPED_BY_CATEGORIES_SUCCESS = createAction(
  'GET_PRODUCTS_GROUPED_BY_CATEGORIES_SUCCESS'
);
export const GET_PRODUCTS_GROUPED_BY_CATEGORIES_FAIL = createAction(
  'GET_PRODUCTS_GROUPED_BY_CATEGORIES_FAIL'
);

export const SELECT_COMPANY_CATEGORY = createAction('SELECT_COMPANY_CATEGORY');
export const UPDATE_COMPANY_FILTERED_PRODUCTS = createAction('UPDATE_COMPANY_FILTERED_PRODUCTS');
