import { isPlainObject } from 'lodash';

export const socialNetworksMapper = (socialNetworksObject = {}) => {
  if (!isPlainObject(socialNetworksObject)) {
    return {};
  }

  return Object.entries(socialNetworksObject)
    .filter((socialNetworkSubArray) => socialNetworkSubArray[1])
    .map((socialNetworkSubArray) => ({
      name: socialNetworkSubArray[0],
      link: socialNetworkSubArray[1],
    }));
};
