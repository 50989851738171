import { handleActions, combineActions } from 'redux-actions';

import * as actions from '../actions';

const defaultState = {
  companies: [],
  merchants: [],
  stores: [],
  selectedCompany: {},
  pageCount: 0,
  isLoading: false,
  successMessage: '',
  companyIdToRemove: '',
  errors: '',
};

export default handleActions(
  {
    [combineActions(
      actions.GET_ALL_COMPANIES,
      actions.CREATE_COMPANY,
      actions.UPDATE_COMPANY_INFO,
      actions.DELETE_COMPANY,
      actions.GET_ALL_MERCHANTS,
      actions.GET_ALL_STORES,
      actions.GET_MERCHANT_BY_ID
    )](state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    [actions.GET_ALL_COMPANIES_SUCCESS]: (state, { payload = [] }) => ({
      ...state,
      companies: payload.response.companies,
      pageCount: payload.response.pageCount,
      isLoading: false,
    }),
    [actions.REMOVE_LAST_MERCHANT_AND_STORE]: (state) => {
      const merchantsCopy = [...state.merchants];
      const storesCopy = [...state.stores];

      merchantsCopy.pop();
      storesCopy.pop();

      return {
        ...state,
        merchants: merchantsCopy,
        stores: storesCopy,
      };
    },
    [actions.SELECT_COMPANY]: (state, { payload = {} }) => {
      return {
        ...state,
        selectedCompany: payload,
      };
    },
    [actions.GET_ALL_MERCHANTS_SUCCESS]: (state, { payload = [] }) => ({
      ...state,
      merchants: payload.response,
      isLoading: false,
    }),
    [actions.GET_ALL_STORES_SUCCESS]: (state, { payload = [] }) => ({
      ...state,
      stores: payload.response,
      isLoading: false,
    }),
    [actions.GET_MERCHANT_BY_ID_SUCCESS]: (state, { payload = {} }) => {
      const merchantsCopy = [...state.merchants];
      const storesCopy = [...state.stores];

      storesCopy.push({
        shopifyId: state.selectedCompany.shopifyId,
        shopifyName: state.selectedCompany.shopifyName,
      });

      merchantsCopy.push(payload.response);

      return {
        ...state,
        merchants: merchantsCopy,
        stores: storesCopy,
        isLoading: false,
      };
    },
    [actions.DELETE_COMPANY_SUCCESS]: (state, { payload = {} }) => {
      const { _id: deletedCompanyId } = payload.response;

      const companiesCopy = [...state.companies];

      const indexOfItemToRemove = companiesCopy.findIndex(
        (company) => company._id === deletedCompanyId
      );

      companiesCopy.splice(indexOfItemToRemove, 1);

      return {
        ...state,
        companies: companiesCopy,
        successMessage: 'Company has been successfully removed.',
        isLoading: false,
      };
    },
    [actions.CREATE_COMPANY_SUCCESS]: (state, { payload = {} }) => ({
      ...state,
      companies: [...state.companies, payload.response],
      pageCount: payload.response.pageCount,
      successMessage: 'Company successfully created.',
      isLoading: false,
    }),
    [actions.UPDATE_COMPANY_INFO_SUCCESS]: (state, { payload = {} }) => {
      const updatedCompany = payload.response;

      const companiesCopy = [...state.companies];

      const indexOfUpdatedCompany = companiesCopy.findIndex(
        (company) => company._id === updatedCompany._id
      );

      companiesCopy[indexOfUpdatedCompany] = { ...updatedCompany };

      return {
        ...state,
        companies: companiesCopy,
        successMessage: 'Company has been successfully updated.',
        isLoading: false,
      };
    },

    [combineActions(
      actions.GET_ALL_COMPANIES_FAIL,
      actions.CREATE_COMPANY_FAIL,
      actions.UPDATE_COMPANY_INFO_FAIL,
      actions.DELETE_COMPANY_FAIL,
      actions.GET_ALL_MERCHANTS_FAIL,
      actions.GET_ALL_STORES_FAIL,
      actions.GET_MERCHANT_BY_ID_FAIL
    )](state, { payload }) {
      return {
        ...state,
        errors: payload.response,
        isLoading: false,
      };
    },
    [actions.SET_COMPANY_ID_TO_REMOVE]: (state, { payload }) => ({
      ...state,
      companyIdToRemove: payload,
      isLoading: false,
    }),
    [actions.CLEAR_ADMIN_COMPANY_TAB_STATUS_MESSAGE]: (state) => ({
      ...state,
      errors: '',
      successMessage: '',
    }),
  },
  defaultState
);
