import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  withStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Tooltip,
  Drawer,
} from '@material-ui/core';

import ROUTES from '../../../../routes';

import styles from './style';

const Sidebar = ({ classes, handleRememberLastTab, tabs }) => {
  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar}>
          <Typography variant="h3" color="primary" className={classes.title}>
            Admin Panel
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <List className={classes.menuList}>
          {tabs.map((route) => (
            <NavLink
              to={`${ROUTES.ADMIN_PAGE}${route.path}`}
              key={route.path}
              activeClassName={classes.activeTab}
              onClick={() => handleRememberLastTab(route.path)}
            >
              <Tooltip title={route.additionalInfo}>
                <ListItem className={classes.listItem}>
                  <ListItemIcon>{route.icon}</ListItemIcon>
                  <Typography variant="h4" color="primary">
                    {route.title}
                  </Typography>
                </ListItem>
              </Tooltip>
            </NavLink>
          ))}
        </List>
        <div className={classes.background} />
      </Drawer>
    </>
  );
};

Sidebar.defaultProps = {
  handleRememberLastTab: () => {},
};

Sidebar.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tabs: PropTypes.oneOfType([PropTypes.array]).isRequired,
  handleRememberLastTab: PropTypes.func,
};

export default React.memo(withStyles(styles)(Sidebar));
