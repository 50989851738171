import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, kebabCase } from 'lodash';

import api from 'api/config';
import Snack from 'commonComponents/SnackBar';
import Loading from 'commonComponents/Loading';
import { validateBaseInput } from 'commonComponents/RegisterModal/helpers/validations';
import ManageCompaniesTab from '../components/ManageCompaniesTab';
import {
  CLEAR_ADMIN_COMPANY_TAB_STATUS_MESSAGE,
  CREATE_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY_INFO,
  GET_ALL_COMPANIES,
  GET_ALL_MERCHANTS,
  GET_MERCHANT_BY_ID,
  SET_COMPANY_ID_TO_REMOVE,
  REMOVE_LAST_MERCHANT_AND_STORE,
  SELECT_COMPANY,
  GET_ALL_STORES,
} from '../actions';
import { validateFee } from '../helpers/validators';

const ManageCompaniesTabContainer = () => {
  const dispatch = useDispatch();

  const {
    companies,
    merchants,
    stores,
    isLoading,
    pageCount,
    successMessage,
    errors,
    companyIdToRemove,
    selectedCompany,
  } = useSelector((state) => state.superAdminTabs.manageCompaniesTab);

  const [formValues, setFormValues] = useState({
    title: '',
    pathName: '',
    fee: '',
    shopifyName: '',
    merchant: '',
    isValid: false,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [isManageCompanyModalOpen, toggleManageCompanyModalOpen] = useState(false);
  const [isRemoveCompanyModalOpen, toggleRemoveCompanyModalOpen] = useState(false);
  const [isEditModalMode, setEditModalMode] = useState(false);

  const isFormDataValid = useMemo(
    () =>
      validateBaseInput(formValues.title) &&
      validateBaseInput(formValues.pathName) &&
      validateFee(formValues.fee) &&
      !isEmpty(selectedMerchant) &&
      !isEmpty(selectedStore),
    [formValues.title, formValues.pathName, formValues.fee, selectedMerchant, selectedStore]
  );

  useEffect(() => {
    dispatch(GET_ALL_STORES());
    dispatch(GET_ALL_MERCHANTS());
    dispatch(GET_ALL_COMPANIES(pageNumber));
  }, [dispatch, pageNumber]);

  useEffect(() => {
    const foundedMerchant = merchants.find(
      (merchant) => merchant.email === formValues.merchant || merchant._id === formValues.merchant
    );

    if (!isEmpty(foundedMerchant)) {
      setSelectedMerchant(foundedMerchant);
    }

    const foundedStore = stores.find((store) => store.shopifyName === formValues.shopifyName);

    if (!isEmpty(foundedStore)) {
      setSelectedStore(foundedStore);
    }
  }, [formValues.merchant, formValues.shopifyName, stores, merchants]);

  const handlePageUpdate = useCallback((page) => {
    setPageNumber(page);
  }, []);

  const handleOpenManageCompanyModal = useCallback(() => {
    setEditModalMode(false);

    toggleManageCompanyModalOpen(true);

    setFormValues({
      title: '',
      shopifyName: '',
      pathName: '',
      fee: '',
      merchant: '',
      isValid: false,
    });
  }, []);

  const handleCloseManageCompanyModal = useCallback(() => {
    toggleManageCompanyModalOpen(false);
    setSelectedMerchant(null);
    setSelectedStore(null);

    if (isEditModalMode) {
      dispatch(SELECT_COMPANY({}));
      dispatch(REMOVE_LAST_MERCHANT_AND_STORE());
    }
  }, [dispatch, isEditModalMode]);

  const handleEditCompanyButtonToggle = useCallback(
    (company) => {
      dispatch(SELECT_COMPANY(company));
      dispatch(GET_MERCHANT_BY_ID(company.merchantId));

      toggleManageCompanyModalOpen(true);
      setEditModalMode(true);

      setFormValues({
        title: company.title,
        shopifyName: company.shopifyName,
        pathName: company.pathName,
        fee: company.fee,
        merchant: company.merchantId,
        isValid: company.isValid,
      });
    },
    [dispatch]
  );

  const handleRemoveModalOpen = useCallback(
    (companyId) => {
      dispatch(SET_COMPANY_ID_TO_REMOVE(companyId));

      toggleRemoveCompanyModalOpen(true);
    },
    [dispatch]
  );

  const handleUpdatedCompany = useCallback(
    (event, company = {}) => {
      api.defaults.headers['Content-Type'] = 'multipart/form-data';

      let generalInfo = {};

      const formData = new FormData();

      if (!isEmpty(company)) {
        generalInfo = {
          shopifyId: company.shopifyId,
          isValid: event.target.checked,
        };
      } else {
        generalInfo = {
          ...formValues,
          shopifyName: selectedStore.shopifyName,
          shopifyId: selectedStore.shopifyId,
          merchantId: selectedMerchant._id,
        };
      }

      formData.append(
        'merchantId',
        !isEmpty(company) ? company.merchantId : selectedCompany.merchantId
      );
      formData.append('companyId', !isEmpty(company) ? company._id : selectedCompany._id);
      formData.append('generalInfo', JSON.stringify(generalInfo));
      formData.append('mediaToRemove', JSON.stringify([]));

      dispatch(UPDATE_COMPANY_INFO(formData));

      api.defaults.headers['Content-Type'] = 'application/json';

      handleCloseManageCompanyModal();
    },
    [
      dispatch,
      formValues,
      selectedCompany,
      selectedMerchant,
      selectedStore,
      handleCloseManageCompanyModal,
    ]
  );

  const handleRemoveModalClose = useCallback(() => {
    toggleRemoveCompanyModalOpen(false);
  }, []);

  const handleCompanyRemove = useCallback(() => {
    dispatch(DELETE_COMPANY(companyIdToRemove));

    handleRemoveModalClose();
  }, [companyIdToRemove, dispatch, handleRemoveModalClose]);

  const handleFormValuesChange = useCallback(
    (event) => {
      if (event.target.type === 'checkbox') {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.checked,
        });
      } else if (event.target.name === 'title') {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value,
          pathName: kebabCase(event.target.value),
        });
      } else {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
      }
    },
    [formValues]
  );

  const handleSelectMerchant = useCallback((value) => {
    setSelectedMerchant(value);
  }, []);

  const handleSelectStore = useCallback((value) => {
    setSelectedStore(value);
  }, []);

  const handleCreateCompany = useCallback(() => {
    dispatch(
      CREATE_COMPANY({
        title: formValues.title,
        pathName: formValues.pathName,
        fee: formValues.fee,
        isValid: formValues.isValid,
        shopifyName: selectedStore.shopifyName,
        shopifyId: selectedStore.shopifyId,
        merchantId: selectedMerchant._id,
      })
    );

    handleCloseManageCompanyModal();
  }, [dispatch, formValues, handleCloseManageCompanyModal, selectedMerchant, selectedStore]);

  const handleResponseMessageClear = useCallback(() => {
    dispatch(CLEAR_ADMIN_COMPANY_TAB_STATUS_MESSAGE());
  }, [dispatch]);

  return (
    <>
      <ManageCompaniesTab
        companies={companies}
        merchants={merchants}
        handlePageUpdate={handlePageUpdate}
        handleCompanyRemove={handleCompanyRemove}
        pageCount={pageCount}
        isCreateModalOpen={isManageCompanyModalOpen}
        onOpenManageCompanyModal={handleOpenManageCompanyModal}
        onCloseManageCompanyModal={handleCloseManageCompanyModal}
        isRemoveCompanyModalOpen={isRemoveCompanyModalOpen}
        formValues={formValues}
        handleFormValuesChange={handleFormValuesChange}
        isFormDataValid={isFormDataValid}
        handleCompanyCreate={handleCreateCompany}
        handleRemoveModalOpen={handleRemoveModalOpen}
        handleRemoveModalClose={handleRemoveModalClose}
        onUpdateCompany={handleUpdatedCompany}
        isEditModalMode={isEditModalMode}
        onEditCompanyButtonToggle={handleEditCompanyButtonToggle}
        handleSelectMerchant={handleSelectMerchant}
        handleSelectStore={handleSelectStore}
        selectedMerchant={selectedMerchant}
        selectedStore={selectedStore}
        stores={stores}
        pageNumber={pageNumber}
      />
      <Loading isLoading={isLoading} />
      <Snack
        message={errors || successMessage}
        onHandleClose={handleResponseMessageClear}
        isSuccess={!!successMessage}
      />
    </>
  );
};

export default React.memo(ManageCompaniesTabContainer);
