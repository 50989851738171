import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { USER_ROLES } from 'constants/userRoles';
import PrivateRoute from 'commonComponents/PrivateRoute';
import HomePageContainer from 'modules/HomePage/containers/HomePageContainer';
import CompanyPageContainer from 'modules/CompanyPage/containers/CompanyPageContainer';
import CategoriesPageContainer from 'modules/CategoriesPage/containers/CategoriesPageContainer';
import ProductPageContainer from 'modules/ProductPage/containers/ProductPageContainer';
import RecentlyViewedPageContainer from 'modules/RecentlyViewedPage/containers/RecenltyViewedPageContainer';
import ProfilePageContainer from 'modules/ProfilePage/containers/ProfilePageContainer';
import BrandsListPageContainer from 'modules/BrandsListPage/containers/BrandsListPageContainer';
import FavoritesPageContainer from 'modules/FavoritesPage/containers/FavoritesPageContainer';
import AdminContainer from '../../../../../modules/AdminPage/containers/AdminPageContainer';
import CartPageContainer from '../../../../../modules/CartPage/containers/CartPageContainer';
import ROUTES from '../../../../../routes';

import styles from './styles';
import { CHECK_AUTH_STATUS } from '../../../../../helpers/authenticationWatcher/authActions';

const MainLayout = ({ classes }) => {
  const dispatch = useDispatch();
  const { isAuth, token } = useSelector((state) => state.user);

  dispatch(CHECK_AUTH_STATUS({ isAuth, token }));

  return (
    <Box className={classes.mainLayoutContainer}>
      <Switch>
        <Route exact path={ROUTES.HOME} component={HomePageContainer} />
        <PrivateRoute
          path={ROUTES.ADMIN_PAGE}
          component={AdminContainer}
          roles={[USER_ROLES.ADMIN, USER_ROLES.MERCHANT]}
        />
        <Route
          exact
          path={`${ROUTES.COMPANY}/:companyPath/:categoryName?`}
          component={CompanyPageContainer}
        />
        <Route
          exact
          path={`${ROUTES.CATEGORIES}/:categoryName?`}
          component={CategoriesPageContainer}
        />
        <Route
          exact
          path={`${ROUTES.PRODUCT_PAGE}/:productPath`}
          component={ProductPageContainer}
        />
        <Route path={ROUTES.RECENTLY_VIEWED_PAGE} component={RecentlyViewedPageContainer} />
        <Route path={ROUTES.FAVORITES} component={FavoritesPageContainer} />
        <PrivateRoute path={ROUTES.PROFILE} component={ProfilePageContainer} />
        <Route path={ROUTES.CART} component={CartPageContainer} />
        <Route path={ROUTES.BRANDS_LIST} component={BrandsListPageContainer} />
        <Redirect from="*" to="/" />
      </Switch>
    </Box>
  );
};

MainLayout.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withStyles(styles)(MainLayout);
