import { createAction } from 'redux-actions';

export const GET_MERCHANTS = createAction('GET_MERCHANTS_REQUEST');
export const GET_MERCHANTS_SUCCESS = createAction('GET_MERCHANTS_SUCCESS');
export const GET_MERCHANTS_FAIL = createAction('GET_MERCHANTS_FAIL');

export const DELETE_MERCHANT = createAction('DELETE_MERCHANT_REQUEST');
export const DELETE_MERCHANT_SUCCESS = createAction('DELETE_MERCHANT_SUCCESS');
export const DELETE_MERCHANT_FAIL = createAction('DELETE_MERCHANT_FAIL');

export const CLEAR_ADMIN_CLIENT_TAB_STATUS_MESSAGE = createAction(
  'CLEAR_ADMIN_CLIENT_TAB_STATUS_MESSAGE'
);

export const CREATE_MERCHANT = createAction('CREATE_MERCHANT_REQUEST');
export const CREATE_MERCHANT_SUCCESS = createAction('CREATE_MERCHANT_SUCCESS');
export const CREATE_MERCHANT_FAIL = createAction('CREATE_MERCHANT_FAIL');

export const SET_MERCHANT_ID_TO_REMOVE = createAction('SET_MERCHANT_ID_TO_REMOVE');
