const styles = (theme) => ({
  targets: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  targetsAreaTitle: {
    marginRight: 20,
    fontSize: 14,
  },
});

export default styles;
