import { handleActions } from 'redux-actions';

import * as actions from '../actions/index';

const defaultState = {
  productInfo: {},
  errors: '',
  isLoading: false,
};

const product = handleActions(
  {
    [actions.GET_PRODUCT_INFO]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.GET_PRODUCT_INFO_SUCCESS]: (state, { payload = {} }) => {
      if (payload.response.data[0]) {
        return {
          ...state,
          productInfo: payload.response.data[0],
          isLoading: false,
          errors: '',
        };
      }
      return {
        ...state,
        isLoading: false,
        errors: 'Product does not exist.',
      };
    },
  },
  defaultState
);

export default product;
