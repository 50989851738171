import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Snack from 'commonComponents/SnackBar';
import Loading from 'commonComponents/Loading';
import { GET_LANDING_CONFIG } from 'config/globalActions';
import LandingTab from '../components/LandingTab';
import {
  UPDATE_LANDING_CONFIG,
  GET_ALL_ACTIVE_COMPANIES,
  CLEAR_ADMIN_LANDING_TAB_STATUS_MESSAGE,
} from '../actions';

const LandingTabContainer = () => {
  const dispatch = useDispatch();

  const { config, companies, successMessage, isLoading, errors } = useSelector(
    (state) => state.superAdminTabs.landingTab
  );

  useEffect(() => {
    dispatch(GET_LANDING_CONFIG());
    dispatch(GET_ALL_ACTIVE_COMPANIES());
  }, [dispatch]);

  const getFilteredCompanies = useCallback(
    (gender) => {
      const filteredCompanies = companies.filter(({ companyTarget }) =>
        companyTarget.some((target) => target === gender)
      );
      return filteredCompanies.map((company) => company.title);
    },
    [companies]
  );

  const getFilteredConfig = useCallback(
    (gender) => {
      const foundConfig = config.find(({ section }) => section === gender);

      return foundConfig ? foundConfig.config : [];
    },
    [config]
  );

  const handleResponseMessageClear = useCallback(() => {
    dispatch(CLEAR_ADMIN_LANDING_TAB_STATUS_MESSAGE());
  }, [dispatch]);

  const handleSubmit = useCallback(
    (gender, fields) => {
      const foundConfig = config.find(({ section }) => section.toLowerCase() === gender.toLowerCase());

      const updatedConfig = Object.keys(fields).map((key, index) => {
        const foundCompany = companies.find(({ title }) => fields[key] === title);

        return {
          position: (index + 1).toString(),
          companyId: foundCompany._id,
        };
      });

      dispatch(UPDATE_LANDING_CONFIG({ ...foundConfig, config: updatedConfig }));
    },

    [config, companies, dispatch]
  );

  return (
    <>
      <LandingTab
        getFilteredCompanies={getFilteredCompanies}
        getFilteredConfig={getFilteredConfig}
        onSubmit={handleSubmit}
      />
      <Loading isLoading={isLoading} />
      <Snack
        message={errors || successMessage}
        onHandleClose={handleResponseMessageClear}
        isSuccess={!!successMessage}
      />
    </>
  );
};

export default React.memo(LandingTabContainer);
