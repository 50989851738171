import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { kebabCase } from 'lodash';

import ROUTES from 'routes';
import {
  SELECT_CATEGORY,
  GET_FILTERED_PRODUCTS,
  GET_ALL_PRODUCTS_BY_CATEGORIES,
  UPDATE_FILTERED_PRODUCTS,
  UPDATE_COMPANY_GROUPED_PRODUCTS,
} from '../actions';
import CategoriesPage from '../components/CategoriesPage';

const CategoriesPageContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { categoryName = '' } = useParams();

  const { filterByPeopleCategory } = useSelector((state) => state.global);
  const {
    filteredProducts,
    categories,
    productsByCategories,
    isLoading,
    selectedCategory,
    pageCount,
  } = useSelector((state) => state.categories);

  const categoriesFilteredBySex = useMemo(() => {
    return categories.filter((category) => category.section === filterByPeopleCategory);
  }, [filterByPeopleCategory, categories]);

  const selectedCategoryId = useMemo(() => {
    const categoryObj = categoriesFilteredBySex.find(
      (category) => kebabCase(category.label) === categoryName
    );
    return categoryObj ? categoryObj._id : '';
  }, [categoryName, categoriesFilteredBySex]);

  const handleSelectCategory = useCallback(() => {
    dispatch(SELECT_CATEGORY(selectedCategoryId));
  }, [dispatch, selectedCategoryId]);

  useEffect(() => {
    return () => {
      dispatch(SELECT_CATEGORY(''));
    };
  }, [dispatch]);

  useEffect(() => {
    if (categoriesFilteredBySex.length > 0) {
      const selectedCategoryObj = categoriesFilteredBySex.find(
        (category) => kebabCase(category.label) === categoryName
      );

      selectedCategoryObj
        ? history.push(`${ROUTES.CATEGORIES}/${categoryName}`)
        : history.push(`${ROUTES.CATEGORIES}`);

      selectedCategoryObj && dispatch(UPDATE_FILTERED_PRODUCTS([]));

      dispatch(UPDATE_COMPANY_GROUPED_PRODUCTS({}));
    }
  }, [dispatch, categoryName, handleSelectCategory, categoriesFilteredBySex, history]);

  useEffect(() => {
    handleSelectCategory();
  }, [dispatch, handleSelectCategory, categoryName]);

  const getNextCategory = useCallback(() => {
    if (categoriesFilteredBySex.length > Object.keys(productsByCategories).length) {
      const filteredCategories = categoriesFilteredBySex.filter(
        (category) => !Object.keys(productsByCategories).includes(category._id)
      );
      const nextItem = filteredCategories.pop();

      dispatch(GET_ALL_PRODUCTS_BY_CATEGORIES(nextItem && nextItem._id));
    }
  }, [categoriesFilteredBySex, productsByCategories, dispatch]);

  const handleClickCard = useCallback(
    (productName, productId) => {
      history.push(`${ROUTES.PRODUCT_PAGE}/${productName}-${productId}`);
    },
    [history]
  );

  const handleCategoryClick = useCallback(
    (category) => {
      history.push(`${ROUTES.CATEGORIES}/${category}`);
    },
    [history]
  );

  const handleGetFilteredProducts = useCallback(
    (pageNumber, query) => {
      let modifiedQuery = `page=${pageNumber}&${query}&category=`;

      if (selectedCategory !== '') {
        modifiedQuery += `${selectedCategory}`;
      } else {
        categoriesFilteredBySex.forEach((category) => {
          modifiedQuery += `${category._id},`;
        });

        modifiedQuery = modifiedQuery.slice(0, -1);
      }

      dispatch(GET_FILTERED_PRODUCTS(modifiedQuery));
    },
    [dispatch, categoriesFilteredBySex, selectedCategory]
  );

  const handleUpdateFilteredProducts = useCallback(
    (products) => {
      dispatch(UPDATE_FILTERED_PRODUCTS(products));
    },
    [dispatch]
  );

  return (
    <CategoriesPage
      categories={categoriesFilteredBySex}
      products={filteredProducts}
      isLoading={isLoading}
      handleClickCard={handleClickCard}
      productsByCategories={productsByCategories}
      handleCategoryClick={handleCategoryClick}
      selectedCategory={selectedCategory}
      handleGetFilteredProducts={handleGetFilteredProducts}
      handleUpdateFilteredProducts={handleUpdateFilteredProducts}
      getNextCategory={getNextCategory}
      pageCount={pageCount}
    />
  );
};

export default React.memo(CategoriesPageContainer);
