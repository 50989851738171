const styles = (theme) => ({
  recentlyViewedWrapper: {
    marginBottom: 120,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  prevPage: {
    display: 'inline-block',
  },
});

export default styles;
