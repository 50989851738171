const styles = (theme) => ({
  card: {
    boxShadow: 'none',
    background: theme.palette.white,
    height: 30,
    width: 30,
    cursor: 'pointer',
  },
  orderImage: {
    borderRadius: 5,
    height: '100%',
    width: '100%',
    '&:hover + $orderInfo, &:active + $orderInfo': {
      display: 'block',
    },
  },
  itemImage: {
    borderRadius: 5,
    height: 290,
    width: 230,
    marginBottom: 20,
  },
  orderInfo: {
    height: 410,
    width: 270,
    background: theme.palette.white,
    boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.25)',
    borderRadius: 5,
    position: 'absolute',
    display: 'none',
    padding: '20px 20px 40px',
    textAlign: 'center',
    color: theme.palette.black,
    top: 0,
    left: -290,
    zIndex: 10,
  },
  additionalInfo: {
    marginTop: 10,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    '& div': {
      marginRight: 10,
    },
  },
});

export default styles;
