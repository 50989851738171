import { AREA_WIDTH } from '../../config';

const styles = (theme) => ({
  root: {
    display: 'flex',
    color: theme.palette.black,
    fontSize: 25,
  },
  appBar: {
    width: `calc(100% - ${AREA_WIDTH}px)`,
    marginLeft: AREA_WIDTH,
    backgroundColor: theme.palette.white,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: theme.palette.white,
  },
  nameButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logoutButton: {
    maxWidth: 100,
    marginLeft: 20,
  },
  homeButton: {
    maxWidth: 100,
  },
});

export default styles;
