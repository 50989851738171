import { handleActions } from 'redux-actions';

import { PEOPLE_CATEGORIES } from '../constants/peopleCategories';

import * as actions from './globalActions';

const defaultState = {
  categories: [],
  filterByPeopleCategory: PEOPLE_CATEGORIES.WOMEN,
  errors: '',
  isLoading: false,
};

const global = handleActions(
  {
    [actions.GET_CATEGORIES](state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    [actions.CHANGE_PEOPLE_FILTER]: (state, { payload = {} }) => ({
      ...state,
      filterByPeopleCategory: payload,
    }),
    [actions.GET_CATEGORIES_SUCCESS](state, { payload = {} }) {
      return {
        ...state,
        categories: payload.response.data,
        isLoading: false,
        errors: null,
      };
    },
    [actions.GET_CATEGORIES_FAIL](state, { payload = {} }) {
      return {
        ...state,
        isLoading: false,
        errors: payload.response,
      };
    },
  },
  defaultState
);

export default global;
