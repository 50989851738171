import { AREA_WIDTH } from '../../config';
// import pict from '../../../../static/images/adminSidebar.jpg';

const styles = (theme) => ({
  drawer: {
    width: AREA_WIDTH,
    flexShrink: 0,
    padding: 10,
  },
  drawerPaper: {
    width: AREA_WIDTH,
    color: theme.palette.black,
    fontSize: 20,
    padding: 10,
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    zIndex: 2,
  },
  menuList: {
    zIndex: 2,
  },
  divider: {
    zIndex: 2,
    backgroundColor: theme.palette.black,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
  },
  listItem: {
    color: theme.palette.black,
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundColor: theme.palette.white,
  },
  activeTab: {
    display: 'block',
    backgroundColor: theme.palette.text.primary,
  },
});

export default styles;
