import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getStringQuery } from 'helpers/getStringQuery';
import Loading from 'commonComponents/Loading';
import Snack from 'commonComponents/SnackBar';
import CheckoutModal from '../components/CheckoutModal/CheckoutModal';
import { CLEAR_PAYMENT_RESPONSE_MESSAGES, GET_SHIPPING_INFO_REQUEST } from '../actions';

const CheckoutContainer = ({ isOpen, toggleOpen }) => {
  const dispatch = useDispatch();

  const { orderList = {} } = useSelector((state) => state.cart);
  const { isLoading, messages, isSuccess } = useSelector((state) => state.checkout);

  const storeIdsArray = useMemo(() => Object.entries(orderList).map((order) => order[1].storeId), [
    orderList,
  ]);

  useEffect(() => {
    if (storeIdsArray.length > 0) {
      const ids = getStringQuery(storeIdsArray);

      dispatch(GET_SHIPPING_INFO_REQUEST(ids));
    }
  }, [dispatch, storeIdsArray]);

  const handleResponseMessageClear = useCallback(() => {
    dispatch(CLEAR_PAYMENT_RESPONSE_MESSAGES());
  }, [dispatch]);

  return (
    <>
      <CheckoutModal
        isOpen={isOpen}
        isSuccess={isSuccess}
        handleResponseMessageClear={handleResponseMessageClear}
        toggleOpen={toggleOpen}
        messages={messages}
      />
      <Loading isLoading={isLoading} />
      <Snack
        message={messages}
        autoHideDuration={900}
        isSuccess={isSuccess}
        onHandleClose={handleResponseMessageClear}
      />
    </>
  );
};

CheckoutContainer.defaultProps = {
  isOpen: false,
};

CheckoutContainer.propTypes = {
  isOpen: PropTypes.bool,
  toggleOpen: PropTypes.func.isRequired,
};

export default React.memo(CheckoutContainer);
