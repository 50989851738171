import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, withStyles, Box, Typography } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { INVALID_EMAIL, INVALID_BASE_INPUT } from 'constants/errorMessages';
import { validateEmail, validateBaseInput } from 'helpers/validations';
import VideoPlayer from 'commonComponents/VideoPlayer';
import CommonButton from 'commonComponents/CommonButton';
import CommonInput from 'commonComponents/CommonInput';
import DropZoneFileUploader from 'commonComponents/DropZoneFileUploader';
import Gallery from 'commonComponents/ImageGalery';
import GenderTarget from '../GenderTarget';
import { IMAGES_UPLOADING_CONFIG, VIDEO_UPLOADING_CONFIG } from './dndConfigs';

import styles from './style';
import { MAX_COMPANY_DESCRIPTION_LENGTH, MAX_COMPANY_PROFILE_LENGTH } from '../../constants';

const CompanyInformationForm = ({
  classes,
  companyInformation,
  onFormChange,
  onImagesChange,
  onImageRemove,
  inVideoRemove,
  onVideosChange,
  onSubmit,
  isTitleValid,
  isPathNameValid,
  handleTargetsSet,
  targetsFor,
  onAddContact,
  onRemoveContact,
  isContactInfoValid,
}) => {
  const { form = {}, images, video } = companyInformation;

  const {
    title,
    year,
    contacts,
    city,
    pathName,
    facebook,
    instagram,
    pinterest,
    twitter,
    shipping,
    returns,
    customerService,
    description,
    landingDescription,
  } = form;

  return (
    <>
      <Box className={classes.submitArea}>
        <CommonButton
          className={classes.submitButton}
          variant="contained"
          label="Save changes"
          onClick={onSubmit}
          isDisabled={!isTitleValid || !isPathNameValid || !isContactInfoValid}
        />
      </Box>
      <Grid
        container
        justify="center"
        alignItems="flex-start"
        spacing={4}
        className={classes.container}
      >
        <Grid container spacing={4} item xs={12} className={classes.gridWrapper}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.areaTitle}>
              General Information
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CommonInput
              name="title"
              label="Company Name"
              value={title}
              onChange={onFormChange}
              error={!isTitleValid}
              helperText={INVALID_BASE_INPUT}
            />
          </Grid>
          <Grid item xs={6}>
            <CommonInput
              name="pathName"
              label="Path Name"
              value={pathName}
              onChange={onFormChange}
              error={!isPathNameValid}
              helperText={INVALID_BASE_INPUT}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.areaTitle}>
            Contact Information
          </Typography>
        </Grid>
        <Grid container item spacing={4} xs={12}>
          {contacts.map((contact, indexValue) => (
            <React.Fragment key={contact._id}>
              <Grid item xs={3}>
                <CommonInput
                  name="name"
                  label="Name"
                  value={contact.name}
                  helperText={INVALID_BASE_INPUT}
                  onChange={(event, index = indexValue) => onFormChange(event, index)}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonInput
                  name="title"
                  label="Title"
                  value={contact.title}
                  error={!validateBaseInput(contact.title)}
                  isTouchedByDefault
                  helperText={INVALID_BASE_INPUT}
                  onChange={(event, index = indexValue) => onFormChange(event, index)}
                />
              </Grid>
              <Grid item xs={3}>
                <CommonInput
                  name="email"
                  label="Email"
                  type="email"
                  value={contact.email}
                  isTouchedByDefault
                  onChange={(event, index = indexValue) => onFormChange(event, index)}
                  error={!validateEmail(contact.email)}
                  helperText={INVALID_EMAIL}
                />
              </Grid>
              <Grid item xs={2}>
                <CommonInput
                  name="phone"
                  label="Phone"
                  value={contact.phone}
                  onChange={(event, index = indexValue) => onFormChange(event, index)}
                />
              </Grid>
              <Grid item xs={1}>
                <CommonButton
                  label=""
                  startIcon={<DeleteForeverIcon />}
                  variant="contained"
                  className={classes.removeContactButton}
                  isDisabled={contacts.length === 1}
                  onClick={(event, contactObj = contact) => onRemoveContact(contactObj)}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={3}>
            <CommonButton
              label="Add contact"
              variant="contained"
              isDisabled={!isContactInfoValid}
              onClick={onAddContact}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid container spacing={4} justify="center" item xs={12} className={classes.gridWrapper}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.areaTitle}>
              Company Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <GenderTarget handleTargetsSet={handleTargetsSet} targetsFor={targetsFor} />
          </Grid>
          <Grid item xs={6}>
            <CommonInput name="year" label="Year Founded" value={year} onChange={onFormChange} />
          </Grid>
          <Grid item xs={6}>
            <CommonInput name="city" label="City" value={city} onChange={onFormChange} />
          </Grid>
          <Grid item xs={12}>
            <CommonInput
              name="landingDescription"
              label="Short description"
              value={landingDescription}
              style={classes.companyDescription}
              multiline
              rows={4}
              inputProps={{
                maxLength: MAX_COMPANY_DESCRIPTION_LENGTH,
              }}
              onChange={onFormChange}
              helperText={`${MAX_COMPANY_DESCRIPTION_LENGTH} Characters max (${
                MAX_COMPANY_DESCRIPTION_LENGTH - landingDescription.length
              } left)`}
            />
          </Grid>
          <Grid item xs={12}>
            <CommonInput
              name="description"
              label="Company profile"
              value={description}
              style={classes.companyDescription}
              multiline
              rows={4}
              inputProps={{
                maxLength: MAX_COMPANY_PROFILE_LENGTH,
              }}
              onChange={onFormChange}
              helperText={`${MAX_COMPANY_PROFILE_LENGTH} Characters max (${
                MAX_COMPANY_PROFILE_LENGTH - description.length
              } left)`}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid container spacing={4} item xs={12} className={classes.gridWrapper}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.areaTitle}>
              Customer Service and Policy Links
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <CommonInput
              name="shipping"
              label="Shipping"
              type="url"
              pattern="https://.*"
              size="30"
              value={shipping}
              onChange={onFormChange}
            />
          </Grid>
          <Grid item xs={4}>
            <CommonInput
              name="returns"
              label="Returns"
              type="url"
              pattern="https://.*"
              size="30"
              value={returns}
              onChange={onFormChange}
            />
          </Grid>
          <Grid item xs={4}>
            <CommonInput
              name="customerService"
              label="Customer service"
              type="url"
              pattern="https://.*"
              size="30"
              value={customerService}
              onChange={onFormChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid container spacing={4} item xs={12} className={classes.gridWrapper}>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.areaTitle}>
              Social Networks
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CommonInput
              name="facebook"
              label="Facebook"
              type="url"
              pattern="https://.*"
              size="30"
              value={facebook}
              onChange={onFormChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CommonInput
              name="instagram"
              label="Instagram"
              type="url"
              pattern="https://.*"
              size="30"
              value={instagram}
              onChange={onFormChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CommonInput
              name="pinterest"
              label="Pinterest"
              type="url"
              pattern="https://.*"
              size="30"
              value={pinterest}
              onChange={onFormChange}
            />
          </Grid>
          <Grid item xs={6}>
            <CommonInput
              name="twitter"
              label="Twitter"
              type="url"
              pattern="https://.*"
              size="30"
              value={twitter}
              onChange={onFormChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.areaTitle}>
            Company Images
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DropZoneFileUploader
            uploadedMedia={images}
            uploadHandler={onImagesChange}
            config={IMAGES_UPLOADING_CONFIG}
          />
          {images.length > 0 && (
            <div style={{ padding: '100px 0px' }}>
              <Gallery mediaFiles={images} handleImageRemove={onImageRemove} />
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.areaTitle}>
            Video Area
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.videoAreaWrapper}>
            <DropZoneFileUploader
              uploadedMedia={video}
              uploadHandler={onVideosChange}
              config={VIDEO_UPLOADING_CONFIG}
            />

            {video.length > 0 && (
              <>
                <VideoPlayer controls formats={['mp4', 'webm']} filePath={video[0].original} />
                <CommonButton
                  className={classes.submitButton}
                  variant="contained"
                  label="Remove"
                  onClick={() => inVideoRemove(video[0])}
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

CompanyInformationForm.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  companyInformation: PropTypes.shape({
    form: PropTypes.shape({
      title: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      year: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      facebook: PropTypes.string.isRequired,
      instagram: PropTypes.string.isRequired,
      pinterest: PropTypes.string.isRequired,
      twitter: PropTypes.string.isRequired,
    }),
    images: PropTypes.array.isRequired,
    video: PropTypes.array.isRequired,
  }).isRequired,
  onAddContact: PropTypes.func.isRequired,
  onRemoveContact: PropTypes.func.isRequired,
  isContactInfoValid: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onImagesChange: PropTypes.func.isRequired,
  onImageRemove: PropTypes.func.isRequired,
  inVideoRemove: PropTypes.func.isRequired,
  onVideosChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isTitleValid: PropTypes.bool.isRequired,
  isPathNameValid: PropTypes.bool.isRequired,
  targetsFor: PropTypes.string.isRequired,
  handleTargetsSet: PropTypes.bool.isRequired,
};

export default React.memo(withStyles(styles)(CompanyInformationForm));
