import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import Moment from 'moment';

import { useRouteToGo } from '../../../helpers/hooks/useRouteToGo';

import AdminLayout from '../components/AdminLayout';
import ROUTES from '../../../routes';
import { SET_LAST_VISITED_TAB } from '../actions';

const AdminPageContainer = () => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const dispatch = useDispatch();

  const { _id, isAuth, role } = useSelector((state) => state.user);
  const { currentTab } = useSelector((state) => state.adminPage);

  const redirectToHome = useRouteToGo(ROUTES.HOME);
  const goToLastVisitedTab = useRouteToGo(`${ROUTES.ADMIN_PAGE}${currentTab}`);

  const handleRememberLastTab = useCallback(
    (tab) => {
      dispatch(SET_LAST_VISITED_TAB(tab));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isAuth) redirectToHome();

    if (currentTab !== '/paymentSettings') goToLastVisitedTab();
  }, [isAuth, redirectToHome, goToLastVisitedTab, currentTab]);

  useEffect(() => {
    if (role === 'admin') setIsSuperAdmin(true);
  }, [role]);

  return (
    <AdminLayout
      companyId={_id}
      handleRememberLastTab={handleRememberLastTab}
      isSuperAdmin={isSuperAdmin}
    />
  );
};

export default React.memo(AdminPageContainer);
