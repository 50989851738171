const styles = (theme) => ({
  title: {
    marginBottom: 15,
    color: theme.palette.black,
  },
  personalInfoBox: {
    borderColor: theme.palette.text.primary,
    borderRadius: 5,
    border: '1px solid',
    margin: 10,
  },
  personalInfoText: {
    margin: 12,
    minWidth: 45,
    [theme.breakpoints.down('xs')]: {
      margin: 6,
      textAlign: 'right',
    },
  },
  personalInfoLabel: {
    margin: 12,
    minWidth: 45,
    [theme.breakpoints.down('xs')]: {
      margin: 6,
    },
  },
  personalInfoChangeButton: {
    textAlign: 'right',
  },
  personalSummaryInfo: {
    margin: 12,
    minWidth: 105,
    [theme.breakpoints.down('xs')]: {
      margin: 6,
    },
  },
  badge: {
    width: '100%',
    '& .MuiBadge-badge': {
      top: -10,
      right: 0,
      backgroundColor: theme.palette.black,
      color: theme.palette.white,
    },
  },
});

export default styles;
