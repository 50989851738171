import { takeEvery, put } from 'redux-saga/effects';
import { GET_COMPANY_INFO_SUCCESS } from '../../AdminPage/components/Tabs/MerchantTabs/CompanyTab/actions';
import { getOnboardingLink } from '../../AdminPage/components/Tabs/MerchantTabs/PaymentSettingsTab/api';
import { SET_ONBOARDING_LINK } from '../../AdminPage/components/Tabs/MerchantTabs/PaymentSettingsTab/actions';

function* getOnboardingLinkWorker({ payload }) {
  try {
    const { response } = payload;
    if (!response.isStripeOnboardingCompleted) {
      const onboardingLinkObject = yield getOnboardingLink();

      yield put(SET_ONBOARDING_LINK(onboardingLinkObject.data.url));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getOnboardingLinkWatcher() {
  yield takeEvery(GET_COMPANY_INFO_SUCCESS, getOnboardingLinkWorker);
}

export default getOnboardingLinkWatcher;
