const styles = (theme) => ({
  card: {
    boxShadow: 'none',
    background: theme.palette.white,
    minWidth: 230,
    maxWidth: 230,
    overflow: 'visible',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 135,
      minWidth: 135,
    },
  },
  productDetails: {
    height: 520,
    width: 350,
    background: theme.palette.white,
    boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.25)',
    borderRadius: 5,
    position: 'absolute',
    display: 'none',
    padding: '20px',
    textAlign: 'center',
    color: theme.palette.black,
    zIndex: 2,
    '&:hover': {
      display: 'block',
    },
  },
  cardInCart: {
    boxShadow: 'none',
    position: 'relative',
    background: theme.palette.white,
    minWidth: 200,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      borderBottom: '1px solid rgba(0,0,0,0.1)',
    },
    '& $productImageInCart': {
      [theme.breakpoints.down('xs')]: {
        marginRight: 20,
        height: 120,
        width: '30%',
      },
    },
    '& $mainInfo': {
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
  },
  productImage: {
    borderRadius: 5,
    height: 290,
    cursor: 'pointer',
    '&:hover + $productDetails, &:active + $productDetails': {
      display: 'block',
    },
    [theme.breakpoints.down('xs')]: {
      height: 195,
    },
  },
  productImageInCart: {
    borderRadius: 5,
    height: 290,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      height: 195,
    },
  },
  mainInfo: { marginTop: 10, color: theme.palette.black },
  brandInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap-reverse',
      marginBottom: 3,
      '& .MuiTypography-body2': {
        width: '100%',
      },
      '& $oldPrice': {
        fontSize: 12,
      },
    },
  },
  priceInfo: {
    display: 'flex',
  },
  newPrice: {
    marginLeft: 10,
  },
  oldPrice: {
    textDecoration: 'line-through',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 3,
    },
  },
});

export default styles;
