import { createAction } from 'redux-actions';

export const ADD_TO_CART = createAction('ADD_TO_CART');
export const REMOVE_FROM_CART = createAction('REMOVE_FROM_CART');
export const CHANGE_ITEM_QUANTITY = createAction('CHANGE_ITEM_QUANTITY');
export const UPDATE_CART_ITEMS = createAction('UPDATE_CART_ITEMS');
export const UPDATE_CART_ITEMS_FINISH = createAction('UPDATE_CART_ITEMS_FINISH');

export const SYNCHRONIZE_CART_ITEMS = createAction('SYNCHRONIZE_CART_ITEMS_REQUEST');
export const SYNCHRONIZE_CART_ITEMS_SUCCESS = createAction('SYNCHRONIZE_CART_ITEMS_SUCCESS');
export const SYNCHRONIZE_CART_ITEMS_FAIL = createAction('SYNCHRONIZE_CART_ITEMS_FAIL');

export const SYNCHRONIZE_COMPANY_INFO = createAction('SYNCHRONIZE_COMPANY_INFO_REQUEST');
export const SYNCHRONIZE_COMPANY_INFO_SUCCESS = createAction('SYNCHRONIZE_COMPANY_INFO_SUCCESS');
export const SYNCHRONIZE_COMPANY_INFO_FAIL = createAction('SYNCHRONIZE_COMPANY_INFO_FAIL');

export const CLEAR_CART_ERRORS = createAction('CLEAR_CART_ERRORS');
