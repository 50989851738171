export const formatGetter = (string) => {
  if (typeof string === 'string') {
    const arrayOfFormats = string.split(',');

    return arrayOfFormats
      .map((format) => {
        const slashIndex = format.indexOf('/');

        return `*${format.slice(slashIndex + 1)}`;
      })
      .join(', ');
  }

  return string;
};
