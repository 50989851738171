const styles = (theme) => ({
  stripeInfo: {
    textAlign: 'center',
    marginTop: 20,
  },
  stripeLink: {
    color: theme.palette.text.primary,
  },
});

export default styles;
