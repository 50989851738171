import { handleActions } from 'redux-actions';

import * as actions from '../actions';

const defaultState = {
  merchants: [],
  pageCount: 0,
  isLoading: false,
  successMessage: '',
  merchantIdToRemove: '',
  errors: '',
};

const clientsTab = handleActions(
  {
    [actions.GET_MERCHANTS]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.GET_MERCHANTS_SUCCESS]: (state, { payload = [] }) => ({
      ...state,
      merchants: payload.response.users,
      pageCount: payload.response.pageCount,
      isLoading: false,
    }),
    [actions.GET_MERCHANTS_FAIL]: (state, { payload }) => ({
      ...state,
      errors: payload.response,
      isLoading: false,
    }),
    [actions.DELETE_MERCHANT]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.DELETE_MERCHANT_SUCCESS]: (state, { payload = {} }) => {
      const { _id: deletedMerchantId } = payload.response;

      const merchantsCopy = [...state.merchants];

      const indexOfItemToRemove = merchantsCopy.findIndex(
        (merchant) => merchant._id === deletedMerchantId
      );

      merchantsCopy.splice(indexOfItemToRemove, 1);

      return {
        ...state,
        isLoading: false,
        successMessage: 'Client has been successfully removed.',
        merchants: merchantsCopy,
      };
    },
    [actions.DELETE_MERCHANT_FAIL]: (state, { payload }) => ({
      ...state,
      errors: payload.response,
      isLoading: false,
    }),
    [actions.CREATE_MERCHANT]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.CREATE_MERCHANT_SUCCESS]: (state, { payload = {} }) => ({
      ...state,
      merchants: [...state.merchants, payload.response.user],
      successMessage: 'Client successfully created.',
      isLoading: false,
    }),
    [actions.CREATE_MERCHANT_FAIL]: (state, { payload }) => ({
      ...state,
      errors: payload.response,
      isLoading: false,
    }),
    [actions.SET_MERCHANT_ID_TO_REMOVE]: (state, { payload }) => ({
      ...state,
      merchantIdToRemove: payload,
      isLoading: false,
    }),

    [actions.CLEAR_ADMIN_CLIENT_TAB_STATUS_MESSAGE]: (state) => ({
      ...state,
      errors: '',
      successMessage: '',
    }),
  },
  defaultState
);

export default clientsTab;
