import MuiButton from './MuiButton';
import MuiCardContent from './MuiCardContent';
import MuiTab from './MuiTab';
import MuiTabs from './MuiTabs';

export default {
  MuiButton,
  MuiCardContent,
  MuiTab,
  MuiTabs,
};
