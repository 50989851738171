import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { USER_ROLES } from 'constants/userRoles';
import Loading from 'commonComponents/Loading';
import OrdersTab from '../components/OrdersTab';

import { GET_COMPANY_INFO } from '../../CompanyTab/actions';
import { GET_ORDERS_SUMMARY_INFO } from '../actions';

const OrdersTabContainer = () => {
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    dateFrom: '',
    dateTo: '',
    financialStatus: '',
    fulfillmentStatus: '',
  });

  const { companyInfo, summaryInfo, isLoading } = useSelector(
    (state) => state.merchantTabs.ordersTab
  );
  const { role } = useSelector((state) => state.user);

  const isAdmin = useMemo(() => {
    return role === USER_ROLES.ADMIN;
  }, [role]);

  useEffect(() => {
    if (isEmpty(companyInfo)) {
      dispatch(GET_COMPANY_INFO());
    }
  }, [dispatch, companyInfo]);

  const getSummaryInfo = useCallback(
    (shopifyId) => {
      dispatch(GET_ORDERS_SUMMARY_INFO(shopifyId));
    },
    [dispatch]
  );

  const handleFormValuesChange = useCallback(
    (event) => {
      if (event.target.type === 'checkbox') {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.checked,
        });
      } else {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
      }
    },
    [formValues]
  );

  const handleSetDefaultFilters = useCallback(() => {
    setFormValues({
      dateFrom: '',
      dateTo: '',
      financialStatus: '',
      fulfillmentStatus: '',
    });
  }, []);

  return (
    <>
      <OrdersTab
        summaryInfo={summaryInfo}
        shopifyId={companyInfo.shopifyId}
        formValues={formValues}
        getSummaryInfo={getSummaryInfo}
        handleFormValuesChange={handleFormValuesChange}
        handleSetDefaultFilters={handleSetDefaultFilters}
        isAdmin={isAdmin}
      />
      <Loading isLoading={isLoading} />
    </>
  );
};

export default React.memo(OrdersTabContainer);
