import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Collapse, withStyles } from '@material-ui/core';

import FoundProductsArea from 'commonComponents/Header/components/FoundProductsArea';
import HeaderContainer from 'commonComponents/Header/containers/HeaderContainer';
import Footer from 'commonComponents/Footer/components/Footer';
import LoginModalContainer from 'commonComponents/LoginModal/containers/LoginModalContainer';
import RegisterModalContainer from 'commonComponents/RegisterModal/containers/RegisterModalContainer';
import ResetPasswordModalContainer from 'commonComponents/ResetPasswordModal/containers/ResetPasswordModalContainer';

import styles from './styles';

const CommonLayout = ({
  content,
  classes,
  isLoginModalShowed,
  isResetPasswordModalShowed,
  isRegisterModalShowed,
  handleShowLoginModal,
  handleShowResetPasswordModal,
  handleShowRegisterModal,
  searchInput,
  onChangeSearchInput,
  foundProducts,
  handleClickCard,
  handleShowFinder,
  showFinder,
  isFindAreaVisible,
  isLoadingSearchData,
  groupedProducts,
}) => {
  return (
    <Box>
      <HeaderContainer
        handleShowRegisterModal={handleShowRegisterModal}
        handleShowLoginModal={handleShowLoginModal}
        searchInput={searchInput}
        onChangeSearchInput={onChangeSearchInput}
        handleShowFinder={handleShowFinder}
        showFinder={showFinder}
      />
      <LoginModalContainer
        isLoginModalShowed={isLoginModalShowed}
        handleShowLoginModal={handleShowLoginModal}
        handleShowResetPasswordModal={handleShowResetPasswordModal}
        handleShowRegisterModal={handleShowRegisterModal}
      />
      <RegisterModalContainer
        isRegisterModalShowed={isRegisterModalShowed}
        handleShowLoginModal={handleShowLoginModal}
        handleShowRegisterModal={handleShowRegisterModal}
      />
      <ResetPasswordModalContainer
        isResetPasswordModalShowed={isResetPasswordModalShowed}
        handleShowResetPasswordModal={handleShowResetPasswordModal}
        handleShowLoginModal={handleShowLoginModal}
      />
      <Collapse in={isFindAreaVisible}>
        <FoundProductsArea
          handleClickCard={handleClickCard}
          foundProducts={foundProducts}
          isLoadingSearchData={isLoadingSearchData}
          groupedProducts={groupedProducts}
        />
      </Collapse>
      <Collapse in={!isFindAreaVisible}>
        <Container maxWidth="lg" className={classes.container}>
          {content}
        </Container>
        <Footer />
      </Collapse>
    </Box>
  );
};

CommonLayout.defaultProps = {
  foundProducts: [],
};

CommonLayout.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  content: PropTypes.node.isRequired,
  isLoginModalShowed: PropTypes.bool.isRequired,
  isResetPasswordModalShowed: PropTypes.bool.isRequired,
  isRegisterModalShowed: PropTypes.bool.isRequired,
  isFindAreaVisible: PropTypes.bool.isRequired,
  isLoadingSearchData: PropTypes.bool.isRequired,
  handleShowLoginModal: PropTypes.func.isRequired,
  handleShowResetPasswordModal: PropTypes.func.isRequired,
  handleClickCard: PropTypes.func.isRequired,
  handleShowRegisterModal: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  foundProducts: PropTypes.oneOfType([PropTypes.array]),
  onChangeSearchInput: PropTypes.func.isRequired,
  handleShowFinder: PropTypes.func.isRequired,
  groupedProducts: PropTypes.oneOfType([PropTypes.array]).isRequired,
  showFinder: PropTypes.bool.isRequired,
};

export default React.memo(withStyles(styles)(CommonLayout));
