const styles = (theme) => ({
  customImageGallery: {
    '& .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover': {
      border: '2px solid #00acc1 !important',
    },
  },
  galleryWrapper: {
    maxWidth: 700,
    margin: '0 auto',
  },
  removeThumbnailButton: {
    fontSize: '15px',
    fontWeight: '500',
    padding: '5px 10px',
    marginBottom: '5px',
    borderRadius: '5px',
    backgroundColor: '#000',
    color: '#fff',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
});

export default styles;
