import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { camelCase } from 'lodash';

import SnackBar from 'commonComponents/SnackBar';
import Cart from '../components/Cart';
import {
  CHANGE_ITEM_QUANTITY,
  REMOVE_FROM_CART,
  SYNCHRONIZE_CART_ITEMS,
  CLEAR_CART_ERRORS,
  SYNCHRONIZE_COMPANY_INFO,
} from '../actions';
import ROUTES from '../../../routes';
import { requestQueryCreatorFromCart } from '../utils/requestQueryCreatorFromCart';

const CartPageContainer = () => {
  const [isCheckoutModalOpen, toggleModalOpen] = useState(false);
  const [isDisabledItemsExist, setExistenceStatus] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();
  const { orderList, isLoading, errors } = useSelector((state) => state.cart);
  const { isAuth } = useSelector((state) => state.user);

  const handleSyncCart = useCallback(() => {
    const requestQuery = requestQueryCreatorFromCart(orderList);
    dispatch(SYNCHRONIZE_CART_ITEMS(requestQuery));

    let storeIds = '';
    Object.keys(orderList).forEach((key) => {
      storeIds += `${storeIds === '' ? '' : ','}${orderList[key].storeId}`;
    });

    dispatch(SYNCHRONIZE_COMPANY_INFO(storeIds));
  }, [dispatch, orderList]);

  useEffect(handleSyncCart, []);

  const handleRemoveFromCart = useCallback(
    ({ id, vendor }) => {
      const vendorToSearch = camelCase(vendor);

      dispatch(REMOVE_FROM_CART({ variantId: id, vendor: vendorToSearch }));
    },
    [dispatch]
  );

  const handleItemQuantityChange = useCallback(
    ({ variantId, value, vendor }) => {
      const vendorToSearch = camelCase(vendor);

      dispatch(CHANGE_ITEM_QUANTITY({ variantId, value, vendor: vendorToSearch }));
    },
    [dispatch]
  );

  const handleClickProductCard = useCallback(
    (productName, productId) => {
      history.push(`${ROUTES.PRODUCT_PAGE}/${productName}-${productId}`);
    },
    [history]
  );

  useEffect(() => {
    const allProductsArray = Object.entries(orderList).flatMap((order) => order[1].products);
    const isDisabledExist = allProductsArray.filter((item) => item.disabled).length > 0;

    setExistenceStatus(isDisabledExist);
  }, [orderList, isLoading]);

  const handleErrorClear = useCallback(() => {
    dispatch(CLEAR_CART_ERRORS());
  }, [dispatch]);

  return (
    <>
      <Cart
        orderList={orderList}
        handleRemoveFromCart={handleRemoveFromCart}
        handleItemQuantityChange={handleItemQuantityChange}
        handleClickProductCard={handleClickProductCard}
        isCheckoutModalOpen={isCheckoutModalOpen}
        toggleOpen={toggleModalOpen}
        isLoading={isLoading}
        isDisabledItemsExist={isDisabledItemsExist || !isAuth}
      />
      <SnackBar message={errors} onHandleClose={handleErrorClear} />
    </>
  );
};

export default React.memo(CartPageContainer);
