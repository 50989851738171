import { createAction } from 'redux-actions';

export const CHANGE_PEOPLE_FILTER = createAction('CHANGE_PEOPLE_FILTER');

export const GET_CATEGORIES = createAction('GET_CATEGORIES_REQUEST');
export const GET_CATEGORIES_SUCCESS = createAction('GET_CATEGORIES_SUCCESS');
export const GET_CATEGORIES_FAIL = createAction('GET_CATEGORIES_FAIL');

export const GET_LANDING_CONFIG = createAction('GET_LANDING_CONFIG_REQUEST');
export const GET_LANDING_CONFIG_SUCCESS = createAction('GET_LANDING_CONFIG_SUCCESS');
export const GET_LANDING_CONFIG_FAIL = createAction('GET_LANDING_CONFIG_FAIL');
