const styles = (theme) => ({
  commonRow: {
    background: 'rgba(245, 245, 245, .5)',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.black,
      color: theme.palette.white,
    },
  },
  selectedRow: {
    background: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  collapsibleRow: {
    background: theme.palette.secondary.main,
  },
  emptyCell: {
    paddingBottom: 0,
    paddingTop: 0,
    verticalAlign: 'baseline',
  },
  divider: {
    height: 10,
    background: theme.palette.white,
  },
  totals: {
    marginBottom: 40,
    '& .MuiTypography-root': {
      fontSize: 12,
    },
    '& .MuiTypography-h3': {
      fontSize: 12,
      marginBottom: 20,
    },
  },
  productsContainer: {
    marginTop: 20,
  },
  productCell: {
    marginBottom: 20,
    position: 'relative',
  },
  contentCell: {
    paddingBottom: 0,
    paddingTop: 0,
    verticalAlign: 'top',
    maxWidth: 120,
  },
  additionalInfoTotals: {
    marginTop: 0,
    '& > *': {
      marginBottom: 10,
    },
  },
});

export default styles;
