import { handleActions } from 'redux-actions';

import * as actions from '../actions';

const defaultState = {
  ordersList: [],
  isLoading: false,
  errors: '',
};

const orderTab = handleActions(
  {
    [actions.GET_USER_ORDERS]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.GET_USER_ORDERS_SUCCESS]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      ordersList: payload.response,
    }),
    [actions.GET_USER_ORDERS_FAIL]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      errors: payload.response,
    }),
  },
  defaultState
);

export default orderTab;
