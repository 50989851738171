const styles = (theme) => ({
  singleBrandItems: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap-reverse',
      paddingRight: '0 !important',
    },
  },
  vendorProductsArea: {
    marginBottom: 50,
  },
  productItem: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0 !important',
    },
  },
  totalBrandPrice: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 20,
      paddingRight: 10,
    },
  },
  topTotalBrandPrice: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 0,
      paddingRight: 10,
    },
  },
  totalInfo: {
    position: 'sticky',
    top: 80,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 40,
    },
  },
  breadcrumbWrapper: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  additionalInfoTotals: {
    [theme.breakpoints.down('sm')]: {
      display: 'inline-flex',
      marginTop: 10,
      '& > *': {
        marginRight: 5,
      },
    },
  },
});

export default styles;
