import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { startCase } from 'lodash';
import { Typography, Link, Box, withStyles } from '@material-ui/core';

import { validateEmail } from 'helpers/validations';
import ProductPropertyInfo from 'commonComponents/ProductPropertyInfo';
import CommonButton from '../../../../commonComponents/CommonButton';

import styles from './styles';

const Totals = ({
  title,
  totalItemsCount,
  shippingPrice,
  taxesPrice,
  totalAmount,
  isWithSubmit,
  classes,
  style,
  additionalInfoStyle,
  withAdditionalInfo,
  additionalInfo,
  handleSubmit,
  isDisabledItemsExist,
}) => {
  return (
    <Box className={clsx(classes.totalsContainer, style && style)}>
      {title && (
        <Typography className={classes.title} variant="h3">
          {title}
        </Typography>
      )}
      <Box className={classes.totalInfo}>
        {totalItemsCount && (
          <ProductPropertyInfo
            title="Total Quantity"
            value={`${totalItemsCount}`}
            style={classes.totalItem}
          />
        )}

        {shippingPrice && (
          <ProductPropertyInfo
            title="Shipping"
            value={`${shippingPrice}`}
            style={classes.totalItem}
          />
        )}
        {taxesPrice && (
          <ProductPropertyInfo title="Taxes" value={`${taxesPrice}`} style={classes.totalItem} />
        )}
        {totalAmount && (
          <ProductPropertyInfo
            title={shippingPrice ? 'Total Price' : 'Subtotal'}
            value={`${totalAmount}`}
            style={classes.totalItem}
          />
        )}
        {isWithSubmit && (
          <Typography className={classes.title} variant="h4">
            Shipping, taxes and discounts calculated at checkout
          </Typography>
        )}
      </Box>
      {withAdditionalInfo && (
        <Box className={clsx(classes.additionalInfo, additionalInfoStyle && additionalInfoStyle)}>
          {additionalInfo.serviceLinks &&
            additionalInfo.serviceLinks.map(({ name, link }) => (
              <Typography key={name} variant="body2" color="textPrimary">
                <Link
                  target="_blank"
                  href={validateEmail(link) ? `mailto:${link}` : link}
                  className={classes.serviceLinks}
                >
                  {startCase(name)}
                </Link>
              </Typography>
            ))}
        </Box>
      )}
      {isWithSubmit && (
        <CommonButton
          onClick={isDisabledItemsExist ? null : handleSubmit}
          isDisabled={isDisabledItemsExist}
          label="Checkout"
          variant="contained"
          className={classes.submitButton}
        />
      )}
    </Box>
  );
};

Totals.defaultProps = {
  isWithSubmit: false,
  withAdditionalInfo: false,
  additionalInfo: {},
  shippingPrice: '',
  taxesPrice: '',
  style: '',
  title: '',
  additionalInfoStyle: '',
  handleSubmit: () => {},
};

Totals.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  title: PropTypes.string,
  totalItemsCount: PropTypes.string.isRequired,
  shippingPrice: PropTypes.string,
  taxesPrice: PropTypes.string,
  totalAmount: PropTypes.string.isRequired,
  style: PropTypes.string,
  additionalInfoStyle: PropTypes.string,
  isWithSubmit: PropTypes.bool,
  isDisabledItemsExist: PropTypes.bool.isRequired,
  withAdditionalInfo: PropTypes.bool,
  additionalInfo: PropTypes.oneOfType([PropTypes.object]),
  handleSubmit: PropTypes.func,
};

export default React.memo(withStyles(styles)(Totals));
