import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, withStyles, Typography } from '@material-ui/core';

import SummaryInfoTable from '../SummaryInfoTable';
import OrderDetailsTable from '../OrderDetailsTable';

import styles from './style';

const OrdersTab = ({
  classes,
  summaryInfo,
  shopifyId,
  formValues,
  getSummaryInfo,
  handleFormValuesChange,
  handleSetDefaultFilters,
  isAdmin,
}) => {
  const renderOrdersTable = shopifyId || isAdmin;

  return (
    <Grid container justify="center" alignItems="flex-start" spacing={4}>
      <Grid container spacing={4} item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.areaTitle}>
            Order Summary
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SummaryInfoTable summaryInfo={summaryInfo} formValues={formValues} isAdmin={isAdmin} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2" className={classes.areaTitle}>
          Order Detail
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {renderOrdersTable && (
          <OrderDetailsTable
            shopifyId={shopifyId}
            formValues={formValues}
            getSummaryInfo={getSummaryInfo}
            handleFormValuesChange={handleFormValuesChange}
            handleSetDefaultFilters={handleSetDefaultFilters}
            isAdmin={isAdmin}
          />
        )}
      </Grid>
    </Grid>
  );
};

OrdersTab.defaultProps = {
  summaryInfo: {},
};

OrdersTab.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.array]).isRequired,
  shopifyId: PropTypes.string.isRequired,
  summaryInfo: PropTypes.oneOfType([PropTypes.object]),
  getSummaryInfo: PropTypes.func.isRequired,
  handleFormValuesChange: PropTypes.func.isRequired,
  handleSetDefaultFilters: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  formValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default React.memo(withStyles(styles)(OrdersTab));
