import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import CommonButton from 'commonComponents/CommonButton';
import CommonDialog from 'commonComponents/CommonDialog';
import CheckoutStepper from '../CheckoutStepper/CheckoutStepper';

import styles from './styles';

const CheckoutModal = ({ classes, isOpen, toggleOpen }) => {
  return (
    <CommonDialog
      isOpen={isOpen}
      maxWidth="md"
      style={classes.commomDialog}
      onClose={() => toggleOpen(false)}
    >
      <CommonButton
        className={classes.closeButton}
        startIcon={<CloseIcon />}
        label=""
        variant="text"
        size="large"
        onClick={() => toggleOpen(false)}
      />
      <CheckoutStepper handleCloseModal={() => toggleOpen(false)} />
    </CommonDialog>
  );
};

CheckoutModal.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default React.memo(withStyles(styles)(CheckoutModal));
