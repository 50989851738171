import { combineActions, handleActions } from 'redux-actions';

import * as actions from '../actions/index';

const defaultState = {
  companies: [],
  companyProducts: {},
  errors: '',
  isLoading: false,
};

const favoritePageReducer = handleActions(
  {
    [actions.GET_COMPANIES_BY_IDS]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [actions.GET_COMPANY_PRODUCTS]: (state, { payload }) => {
      const { companyProducts } = state;
      const companyId = payload;

      return {
        ...state,
        companyProducts: { ...companyProducts, [companyId]: {} },
        isLoading: true,
        errors: null,
      };
    },
    [actions.GET_COMPANIES_BY_IDS_SUCCESS]: (state, { payload = {} }) => {
      return {
        ...state,
        companies: payload.response.companies,
        isLoading: false,
        errors: null,
      };
    },
    [actions.UPDATE_DEFAULT_FOLLOWS]: () => {
      return {
        ...defaultState,
      };
    },
    [actions.UNFOLLOW_COMPANY]: (state, { payload = {} }) => {
      const { companyProducts, companies } = state;

      const companyIndex = companies.indexOf((element) => element._id === payload.id);

      companies.splice(companyIndex, 1);

      delete companyProducts[payload.shopifyId];

      return {
        ...state,
        companies,
        companyProducts,
        isLoading: false,
        errors: null,
      };
    },
    [actions.GET_COMPANY_PRODUCTS_SUCCESS](state, { payload = {} }) {
      const { companyProducts } = state;
      const companyId = payload.actionPayload;
      return {
        ...state,
        companyProducts: { ...companyProducts, [companyId]: payload.response.products },
        isLoading: false,
        errors: null,
      };
    },
    [combineActions(actions.GET_COMPANIES_BY_IDS_FAIL, actions.GET_COMPANY_PRODUCTS_FAIL)]: (
      state,
      { payload = {} }
    ) => ({
      ...state,
      isLoading: false,
      errors: payload.response,
    }),
  },
  defaultState
);

export default favoritePageReducer;
