export default {
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  h1: {
    fontSize: 48,
    fontWeight: 'bold',
    lineHeight: '59px',
  },
  h2: {
    fontSize: 24,
    fontWeight: 'bold',
    lineHeight: '29px',
  },
  h3: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  h4: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '16px',
  },
  h6: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '14px',
  },
  subtitle1: {
    fontWeight: 'normal',
    lineHeight: '19px',
  },
  body1: {
    fontSize: 14,
    lineHeight: '16px',
  },
  body2: {
    fontSize: 12,
    lineHeight: '14px',
  },
};
