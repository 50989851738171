import { takeEvery, put, select } from 'redux-saga/effects';

import { GET_CATEGORIES_SUCCESS, GET_CATEGORIES } from 'config/globalActions';
import * as selectors from './selectors';
import { GET_PRODUCTS_GROUPED_BY_CATEGORIES, GET_COMPANY_SUCCESS } from '../actions';

function* getCategoriesSuccess() {
  const storeId = yield select(selectors.storeId);

  if (storeId) {
    yield put(GET_PRODUCTS_GROUPED_BY_CATEGORIES(storeId));
  }
}

function* getCompanySuccess() {
  const categories = yield select(selectors.categories);

  if (categories.length === 0) {
    yield put(GET_CATEGORIES());
  } else {
    yield getCategoriesSuccess();
  }
}

function* companyPageSaga() {
  yield takeEvery(GET_COMPANY_SUCCESS, getCompanySuccess);
  yield takeEvery(GET_CATEGORIES_SUCCESS, getCategoriesSuccess);
}

export default companyPageSaga;
