import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import Totals from 'modules/CartPage/components/Totals';
import OrderItem from '../OrderItem';

import styles from './styles';

const MobileOrderItem = ({ classes, order }) => {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <Box className={classes.order}>
      <Box className={classes.mainInfo} onClick={handleClick}>
        <Box className={classes.statusInfo}>
          <Typography variant="h3" className={classes.title}>
            Status
          </Typography>
          <Typography variant="h6">{order.status}</Typography>
        </Box>
        <Box className={classes.dateInfo}>
          <Typography variant="h3" className={classes.title}>
            Date
          </Typography>
          <Typography variant="h6">{order.date}</Typography>
        </Box>
        <Box className={classes.expandIcon}>{open ? <ExpandLess /> : <ExpandMore />}</Box>
      </Box>
      <Box className={classes.orderInfo} onClick={handleClick}>
        <Box>
          <Typography variant="h3" className={classes.title}>
            Order Information
          </Typography>
          <Typography variant="h6">#{order.orderNumber}</Typography>
        </Box>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Totals
            style={classes.totals}
            totalItemsPrice={`$${order.totalInfo.totalItemsPrice}`}
            shippingPrice={`$${order.totalInfo.shippingPrice}`}
            taxesPrice={`$${order.totalInfo.taxesPrice}`}
            totalAmount={`$${order.totalInfo.totalAmount}`}
          />
        </Collapse>
      </Box>
      <Box className={classes.brandInfo}>
        <Box>
          <Typography variant="h3" className={classes.title}>
            Brand Information
          </Typography>
          {!open && (
            <Typography variant="h6">
              {order.brandsCount} Brand - {order.totalItems} Items
            </Typography>
          )}
          <Collapse in={open} timeout="auto" unmountOnExit>
            {Object.keys(order.orderInfo).map((companyName, index) => {
              const currentCompany = order.orderInfo[companyName];
              const { totalItemsCost, contacts, serviceLinks, shippingCost, tax } = currentCompany;
              const additionalInfo = { contacts, serviceLinks };

              return (
                <React.Fragment key={uuid()}>
                  <Totals
                    style={classes.totalBrandInfo}
                    additionalInfoStyle={classes.additionalInfoStyle}
                    title={companyName}
                    totalItemsPrice={`$${totalItemsCost}`}
                    shippingPrice={`$${shippingCost}`}
                    taxesPrice={`$${tax}`}
                    totalAmount={`$${totalItemsCost}`}
                    withAdditionalInfo
                    additionalInfo={additionalInfo}
                  />
                  <Grid container spacing={2} className={classes.productsContainer}>
                    {order.orderInfo[companyName].products.map((product) => {
                      return (
                        <Grid key={uuid()} item>
                          <OrderItem
                            productInfo={product}
                            style={classes.product}
                            modalStyles={classes.productModal}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </React.Fragment>
              );
            })}
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};

MobileOrderItem.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  order: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default React.memo(withStyles(styles)(MobileOrderItem));
