import { isEmpty } from 'lodash';

export const socialNetworkExtractor = (socialNetworksArray = []) => {
  if (Array.isArray(socialNetworksArray)) {
    return socialNetworksArray.reduce((initial, currentValue) => {
      if (!isEmpty(currentValue.name)) {
        Object.defineProperty(initial, currentValue.name, { value: currentValue.link });
      }

      return initial;
    }, {});
  }

  return {};
};
