import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { Grid, Box, withStyles, useTheme, useMediaQuery } from '@material-ui/core';

import MobileOrderItem from 'modules/ProfilePage/components/MobileOrderItem';
import OrdersTable from 'modules/ProfilePage/components/OrdersTable';

import styles from './styles.js';
import Loading from '../../../../../../commonComponents/Loading';

const OrdersTab = ({ classes, ordersList, isLoading }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid item xs={12} sm={10} className={classes.ordersTabContainer}>
      {!isMobileView ? (
        <OrdersTable ordersList={ordersList} />
      ) : (
        <Box>
          {ordersList.map((order) => (
            <MobileOrderItem key={uuid()} order={order} />
          ))}
        </Box>
      )}
      <Loading isLoading={isLoading} />
    </Grid>
  );
};

OrdersTab.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  ordersList: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default React.memo(withStyles(styles)(OrdersTab));
