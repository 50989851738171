const styles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  closeButton: {
    minWidth: 20,
    maxWidth: 20,
    height: 40,
    position: 'absolute',
    top: 11,
    right: 15,
    padding: 0,
    '& span': {
      margin: 0,
    },
  },
});

export default styles;
