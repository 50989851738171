import React from 'react';
// import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

import styles from './styles.js';

const StyledCell = ({ children, style, classes, withBottomBorder, withTopBorder }) => {
  return (
    <TableCell
      classes={{
        root:
          withBottomBorder || withTopBorder
            ? clsx(
              classes.root,
              withBottomBorder ? classes.withBottomBorder : classes.withTopBorder
            )
            : classes.root,
        body: classes.body,
        head: classes.head,
      }}
      className={style}
    >
      {children}
    </TableCell>
  );
};

StyledCell.defaultProps = {
  withBottomBorder: false,
  withTopBorder: false,
};

StyledCell.propTypes = {};

export default React.memo(withStyles(styles)(StyledCell));
