const styles = (theme) => ({
  totalsContainer: {
    maxWidth: 240,
    marginBottom: 50,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'unset',
      marginBottom: 20,
    },
  },
  title: {
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
  totalItem: {
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
      width: '50%',
      '&:nth-child(even)': {
        textAlign: 'right',
      },
    },
  },
  submitButton: {
    height: 40,
    marginTop: 10,
  },
  additionalInfo: {
    marginTop: 20,
    '& > *': {
      marginBottom: 5,
    },
  },
  serviceLinks: {
    color: theme.palette.text.primary,
  },
});

export default styles;
