const styles = (theme) => ({
  container: {
    '& button': {
      backgroundColor: theme.palette.text.primary,
    },
  },
  gridWrapper: {
    maxWidth: 700,
  },
  divider: {
    margin: '25px 0',
  },
  areaTitle: {
    textAlign: 'center',
    marginBottom: 25,
  },
  videoAreaWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 40,
  },
  removeButton: {
    fontSize: 25,
    fontWeight: 500,
    padding: '5px 10px',
    marginBottom: 5,
    borderRadius: 5,
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    transition: '0.3s',

    '&:hover': {
      backgroundColor: 'red',
    },
  },
  submitArea: {
    position: 'sticky',
    top: '80px',
    textAlign: 'right',
    zIndex: '100',
  },
  submitButton: {
    maxWidth: 200,
    backgroundColor: theme.palette.text.primary,
  },
  targets: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  targetsAreaTitle: {
    marginRight: 20,
    fontSize: 14,
  },
  companyDescription: {
    marginTop: 20,
    marginBottom: 20,
    '& .MuiInputLabel-formControl': {
      top: -17,
    },
    '& .MuiFormHelperText-root': {
      bottom: -37,
    },
  },
  removeContactButton: {
    '& span': {
      marginLeft: 4,
    },
  },
});

export default styles;
