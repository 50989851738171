import { createAction } from 'redux-actions';

export const GET_COMPANY_INFO = createAction('GET_COMPANY_INFO_REQUEST');
export const GET_COMPANY_INFO_SUCCESS = createAction('GET_COMPANY_INFO_SUCCESS');
export const GET_COMPANY_INFO_FAIL = createAction('GET_COMPANY_INFO_FAIL');

export const UPDATE_MERCHANT_COMPANY = createAction('UPDATE_MERCHANT_COMPANY_REQUEST');
export const UPDATE_MERCHANT_COMPANY_SUCCESS = createAction('UPDATE_MERCHANT_COMPANY_SUCCESS');
export const UPDATE_MERCHANT_COMPANY_FAIL = createAction('UPDATE_MERCHANT_COMPANY_FAIL');

export const CHANGE_FORM_FIELD = createAction('CHANGE_FORM_FIELD');
export const CHANGE_IMAGES = createAction('CHANGE_IMAGES');
export const CHANGE_VIDEOS = createAction('CHANGE_VIDEOS');
export const CHANGE_TARGETS = createAction('CHANGE_TARGETS');

export const SET_TO_REMOVE_LIST = createAction('SET_TP_REMOVE_LIST');
export const CLEAR_COMPANY_TAB_MESSAGE = createAction('CLEAR_COMPANY_TAB_MESSAGE');
