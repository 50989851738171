import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Box, Typography, Checkbox, FormControlLabel } from '@material-ui/core';

import { PEOPLE_CATEGORIES } from 'constants/peopleCategories';

import styles from './style';

const GenderTarget = ({ classes, handleTargetsSet, targetsFor }) => {
  return (
    <Box className={classes.targets}>
      <Typography className={classes.targetsAreaTitle}>Targets:</Typography>
      <FormControlLabel
        control={
          <Checkbox
            value={PEOPLE_CATEGORIES.WOMEN}
            checked={!!targetsFor.find((target) => target === PEOPLE_CATEGORIES.WOMEN)}
            onChange={(event) => handleTargetsSet(event.target.value)}
            name="checkedB"
            color="primary"
          />
        }
        label="Women"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={!!targetsFor.find((target) => target === PEOPLE_CATEGORIES.MEN)}
            value={PEOPLE_CATEGORIES.MEN}
            onChange={(event) => handleTargetsSet(event.target.value)}
            name="checkedB"
            color="primary"
          />
        }
        label="Men"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={
              !!targetsFor.find((target) => target === PEOPLE_CATEGORIES.MEN) &&
              !!targetsFor.find((target) => target === PEOPLE_CATEGORIES.WOMEN)
            }
            value={PEOPLE_CATEGORIES.ALL}
            onChange={(event) => handleTargetsSet(event.target.value)}
            name="checkedB"
            color="primary"
          />
        }
        label="All"
      />
    </Box>
  );
};

GenderTarget.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  targetsFor: PropTypes.string.isRequired,
  handleTargetsSet: PropTypes.bool.isRequired,
};

export default React.memo(withStyles(styles)(GenderTarget));
