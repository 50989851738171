import ROUTES from 'routes';

const footerConfig = [
  { title: 'Brands', link: ROUTES.BRANDS_LIST },
  { title: 'Categories', link: ROUTES.CATEGORIES },
  { title: 'Favorites', link: ROUTES.FAVORITES },
  { title: 'Cart', link: ROUTES.CART },
  { title: 'Profile', link: ROUTES.PROFILE_SETTIGNS_PAGE },
  { title: 'Orders', link: ROUTES.ORDERS_PAGE },
];

export default footerConfig;
