import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, Typography, withStyles } from '@material-ui/core';

import { PEOPLE_CATEGORIES } from 'constants/peopleCategories';
import LandingSection from '../LandingSection';

import styles from './styles';

const LandingTab = ({ classes, getFilteredCompanies, getFilteredConfig, onSubmit }) => {
  return (
    <Grid container justify="center" alignItems="flex-start" spacing={4}>
      <Grid item xs={12}>
        <Typography align="center" variant="h2">
          Landing page
        </Typography>
      </Grid>
      <Grid container item xs={5} spacing={4} className={classes.gridWrapper}>
        <LandingSection
          title={PEOPLE_CATEGORIES.WOMEN}
          companies={getFilteredCompanies(PEOPLE_CATEGORIES.WOMEN)}
          config={getFilteredConfig(PEOPLE_CATEGORIES.WOMEN)}
          handleSaveChanges={onSubmit}
        />
      </Grid>
      <Grid item xs={2}>
        <Divider orientation="vertical" className={classes.divider} />
      </Grid>
      <Grid container item xs={5} spacing={4} className={classes.gridWrapper}>
        <LandingSection
          title={PEOPLE_CATEGORIES.MEN}
          companies={getFilteredCompanies(PEOPLE_CATEGORIES.MEN)}
          config={getFilteredConfig(PEOPLE_CATEGORIES.MEN)}
          handleSaveChanges={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

LandingTab.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  getFilteredCompanies: PropTypes.func.isRequired,
  getFilteredConfig: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default React.memo(withStyles(styles)(LandingTab));
