import { createAction } from 'redux-actions';

export const GET_ALL_COMPANIES = createAction('GET_ALL_COMPANIES_REQUEST');
export const GET_ALL_COMPANIES_SUCCESS = createAction('GET_ALL_COMPANIES_SUCCESS');
export const GET_ALL_COMPANIES_FAIL = createAction('GET_ALL_COMPANIES_FAIL');

export const GET_ALL_MERCHANTS = createAction('GET_ALL_MERCHANTS_REQUEST');
export const GET_ALL_MERCHANTS_SUCCESS = createAction('GET_ALL_MERCHANTS_SUCCESS');
export const GET_ALL_MERCHANTS_FAIL = createAction('GET_ALL_MERCHANTS_FAIL');

export const GET_ALL_STORES = createAction('GET_ALL_STORES_REQUEST');
export const GET_ALL_STORES_SUCCESS = createAction('GET_ALL_STORES_SUCCESS');
export const GET_ALL_STORES_FAIL = createAction('GET_ALL_STORES_FAIL');

export const GET_MERCHANT_BY_ID = createAction('GET_MERCHANT_BY_ID_REQUEST');
export const GET_MERCHANT_BY_ID_SUCCESS = createAction('GET_MERCHANT_BY_ID_SUCCESS');
export const GET_MERCHANT_BY_ID_FAIL = createAction('GET_MERCHANT_BY_ID_FAIL');

export const CREATE_COMPANY = createAction('CREATE_COMPANY_REQUEST');
export const CREATE_COMPANY_SUCCESS = createAction('CREATE_COMPANY_SUCCESS');
export const CREATE_COMPANY_FAIL = createAction('CREATE_COMPANY_FAIL');

export const UPDATE_COMPANY_INFO = createAction('UPDATE_COMPANY_INFO_REQUEST');
export const UPDATE_COMPANY_INFO_SUCCESS = createAction('UPDATE_COMPANY_INFO_SUCCESS');
export const UPDATE_COMPANY_INFO_FAIL = createAction('UPDATE_COMPANY_INFO_FAIL');

export const DELETE_COMPANY = createAction('DELETE_COMPANY_REQUEST');
export const DELETE_COMPANY_SUCCESS = createAction('DELETE_COMPANY_SUCCESS');
export const DELETE_COMPANY_FAIL = createAction('DELETE_COMPANY_FAIL');

export const CLEAR_ADMIN_COMPANY_TAB_STATUS_MESSAGE = createAction(
  'CLEAR_ADMIN_COMPANY_TAB_STATUS_MESSAGE'
);

export const SET_COMPANY_ID_TO_REMOVE = createAction('SET_COMPANY_ID_TO_REMOVE');

export const REMOVE_LAST_MERCHANT_AND_STORE = createAction('REMOVE_LAST_MERCHANT_AND_STORE');

export const SELECT_COMPANY = createAction('SELECT_COMPANY');
