import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { kebabCase } from 'lodash';

import MobileBreadcrumbs from 'commonComponents/MobileBreadcrumbs';
import CommonLayoutContainer from 'commonComponents/layouts/CommonLayout/containers/CommonLayoutContainer';
import ProductCard from 'commonComponents/ProductCard/components/ProductCard';

import styles from './styles.js';

const RecentlyViewed = ({ classes, products, handleClickProductCard }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <CommonLayoutContainer>
      <Grid spacing={4} container className={classes.recentlyViewedWrapper}>
        {!isMobileView ? (
          <Grid item xs={12} className={classes.topNavigation}>
            <Typography variant="body2" color="textPrimary" className={classes.prevPage}>
              Women &#8226;
            </Typography>{' '}
            <Typography variant="body2" className={classes.prevPage}>
              Recently Viewed
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <MobileBreadcrumbs currentPage="Recently Viewed" />
          </Grid>
        )}
        {products.map((product) => (
          <Grid key={product.id} xs={6} md={4} lg={3} item>
            <ProductCard
              style={classes.item}
              image={product.image.src}
              product={product}
              handleClick={() => handleClickProductCard(kebabCase(product.title), product._id)}
            />
          </Grid>
        ))}
      </Grid>
    </CommonLayoutContainer>
  );
};

RecentlyViewed.defaultProps = {
  products: [],
};

RecentlyViewed.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  products: PropTypes.oneOfType([PropTypes.array]),
  handleClickProductCard: PropTypes.func.isRequired,
};

export default React.memo(withStyles(styles)(RecentlyViewed));
