import { combineActions, handleActions } from 'redux-actions';

import { GET_CATEGORIES, GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAIL } from 'config/globalActions';
import * as actions from '../actions/index';

const defaultState = {
  companyInfo: {},
  categories: [],
  filteredProducts: [],
  productsByCategories: [],
  selectedCategory: '',
  pageCount: 0,
  errors: '',
  isLoading: false,
};

const company = handleActions(
  {
    [combineActions(
      actions.GET_COMPANY,
      actions.GET_FILTERED_COMPANY_PRODUCTS,
      actions.GET_PRODUCTS_GROUPED_BY_CATEGORIES,
      GET_CATEGORIES
    )](state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    [actions.GET_COMPANY_SUCCESS](state, { payload }) {
      return {
        ...state,
        companyInfo: payload.response,
        isLoading: false,
        errors: null,
      };
    },
    [actions.SELECT_COMPANY_CATEGORY](state, { payload = '' }) {
      return {
        ...state,
        selectedCategory: payload,
        isLoading: false,
        errors: null,
      };
    },
    [actions.UPDATE_COMPANY_FILTERED_PRODUCTS](state, { payload }) {
      return {
        ...state,
        filteredProducts: payload,
        isLoading: false,
        errors: null,
      };
    },
    [GET_CATEGORIES_SUCCESS](state, { payload }) {
      return {
        ...state,
        categories: payload.response.data,
        isLoading: false,
        errors: null,
      };
    },
    [actions.GET_FILTERED_COMPANY_PRODUCTS_SUCCESS](state, { payload = {} }) {
      const { selectedCategory } = state;

      if (selectedCategory === '') {
        return {
          ...state,
          filteredProducts: payload.response.products,
          pageCount: payload.response.pageCount,
          isLoading: false,
          errors: null,
        };
      }

      let filteredProducts = payload.response.products;

      if (filteredProducts) {
        filteredProducts = filteredProducts.filter((product) =>
          product.categories.includes(selectedCategory)
        );
      }

      return {
        ...state,
        filteredProducts,
        pageCount: payload.response.pageCount,
        isLoading: false,
        errors: null,
      };
    },
    [actions.GET_PRODUCTS_GROUPED_BY_CATEGORIES_SUCCESS](state, { payload }) {
      if (payload) {
        const productsByCategories = Object.keys(payload.response.products).map((categoryId) => {
          const currentCategory = payload.response.products[categoryId];
          const categoryObj = state.categories.find((category) => category._id === categoryId);

          return {
            ...categoryObj,
            products: currentCategory.map((category) => ({
              ...category,
              companyName: payload.response.companyName,
              companyStripeId: payload.response.companyStripeId,
            })),
          };
        });

        return {
          ...state,
          productsByCategories,
          isLoading: false,
          errors: null,
        };
      }

      return {
        ...state,
        isLoading: false,
        errors: null,
      };
    },
    [combineActions(
      actions.GET_COMPANY_FAIL,
      actions.GET_FILTERED_COMPANY_PRODUCTS_FAIL,
      actions.GET_PRODUCTS_GROUPED_BY_CATEGORIES_FAIL,
      GET_CATEGORIES_FAIL
    )](state, { payload = {} }) {
      return {
        ...state,
        isLoading: false,
        errors: payload.response,
      };
    },
  },
  defaultState
);

export default company;
