import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Menu,
  TextField,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import CommonButton from 'commonComponents/CommonButton';
import styles from './style';

const OrdersFilterPopup = ({
  classes,
  anchorEl,
  handleClosePopup,
  formValues,
  handleFormValuesChange,
  handleSetDefaultFilters,
  tableRef,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClosePopup}
      classes={{ paper: classes.menuContainer, list: classes.menuList }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Box className={classes.dateRangeFilter}>
        <TextField
          label="From"
          type="date"
          name="dateFrom"
          value={formValues.dateFrom}
          onChange={handleFormValuesChange}
          className={classes.rangePicker}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="To"
          type="date"
          name="dateTo"
          value={formValues.dateTo}
          onChange={handleFormValuesChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box className={classes.statusContainer}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Financial Status</FormLabel>
          <RadioGroup
            name="financialStatus"
            value={formValues.financialStatus}
            onChange={handleFormValuesChange}
            className={classes.radioContainer}
          >
            <FormControlLabel
              value="paid"
              control={<Radio classes={{ colorSecondary: classes.colorSecondary }} />}
              label="Paid"
            />
            <FormControlLabel
              value="unpaid"
              control={<Radio classes={{ colorSecondary: classes.colorSecondary }} />}
              label="Unpaid"
            />
          </RadioGroup>
        </FormControl>
        <FormControl component="fieldset" classes={{ root: classes.radioContainer }}>
          <FormLabel component="legend">Fulfillment Status</FormLabel>
          <RadioGroup
            name="fulfillmentStatus"
            value={formValues.fulfillmentStatus}
            onChange={handleFormValuesChange}
            className={classes.radioContainer}
          >
            <FormControlLabel
              value="fulfilled"
              control={<Radio classes={{ colorSecondary: classes.colorSecondary }} />}
              label="Fulfilled"
            />
            <FormControlLabel
              value="partial"
              control={<Radio classes={{ colorSecondary: classes.colorSecondary }} />}
              label="Partial"
            />
            <FormControlLabel
              value="null"
              control={<Radio classes={{ colorSecondary: classes.colorSecondary }} />}
              label="Unfulfilled"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box className={classes.filterActions}>
        <CommonButton
          className={classes.cancelButton}
          label="Clear"
          variant="text"
          color="secondary"
          onClick={() => {
            handleSetDefaultFilters();
            handleClosePopup();
            tableRef.current && tableRef.current.onQueryChange();
          }}
        />
        <CommonButton
          label="Apply"
          variant="contained"
          onClick={() => {
            handleClosePopup();
            tableRef.current && tableRef.current.onQueryChange();
          }}
        />
      </Box>
    </Menu>
  );
};

OrdersFilterPopup.defaultProps = {
  anchorEl: null,
  tableRef: null,
};

OrdersFilterPopup.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  formValues: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleClosePopup: PropTypes.func.isRequired,
  handleFormValuesChange: PropTypes.func.isRequired,
  handleSetDefaultFilters: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
  tableRef: PropTypes.oneOfType([PropTypes.object]),
};

export default React.memo(withStyles(styles)(OrdersFilterPopup));
