import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, CardMedia, Box, RadioGroup, withStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';

import CommonSizePicker from 'commonComponents/CommonSizePicker';
import CommonColorPicker from 'commonComponents/CommonColorPicker';
import { ReactComponent as AddIcon } from 'static/images/add-to-cart.svg';
import CommonButton from 'commonComponents/CommonButton';

import styles from './styles.js';

const ProductPopup = ({
  classes,
  handleClick,
  formData,
  onChange,
  productInfo,
  currentProductVariant,
  availableProductSizes,
  cardPosition,
  showPopup,
  currentProductImage,
  handleAddToCart,
}) => {
  const { colorRadio, sizeRadio } = formData;
  const { options = [] } = productInfo;

  return (
    <Paper
      className={classes.productDetails}
      style={{
        top: cardPosition.top,
        left: cardPosition.left,
        display: showPopup ? 'block' : 'none',
      }}
    >
      <CardMedia
        component="img"
        draggable={false}
        alt="Banner"
        image={currentProductImage}
        className={classes.largeProductImage}
        onClick={handleClick}
      />
      <Box className={classes.additionalInfo}>
        <Box className={classes.brandInfo}>
          <Typography variant="body2">{productInfo.companyName}</Typography>
          <Box className={classes.priceInfo}>
            {(currentProductVariant.compareAtPrice > currentProductVariant.mortarPrice ||
              currentProductVariant.marketPrice > currentProductVariant.mortarPrice) && (
              <Typography className={classes.oldPrice} variant="h4">
                ${currentProductVariant.compareAtPrice || currentProductVariant.marketPrice}
              </Typography>
            )}
            {(currentProductVariant.mortarPrice || currentProductVariant.marketPrice) && (
              <Typography className={classes.newPrice} variant="h4" color="textPrimary">
                ${currentProductVariant.mortarPrice || currentProductVariant.marketPrice}
              </Typography>
            )}
          </Box>
        </Box>
        <Typography className={classes.description} variant="h4">
          {productInfo.title}
        </Typography>
        {productInfo.isFinalSale && (
          <Typography className={classes.finalSale} variant="h4" color="error">
            Final Sale
          </Typography>
        )}
        <Box className={classes.colorPickerContainer}>
          <RadioGroup
            className={classes.colorPicker}
            aria-label="color"
            name="colorRadio"
            value="Black"
            onChange={onChange}
          >
            {options.find((option) => option.name === 'Color') &&
              options
                .find((option) => option.name === 'Color')
                .values.map((color) => (
                  <CommonColorPicker key={color} value={color} selectedValue={colorRadio} />
                ))}
          </RadioGroup>
        </Box>
        <Box className={classes.sizePickerContainer}>
          <RadioGroup
            className={classes.colorPicker}
            aria-label="size"
            name="sizeRadio"
            value="XS"
            onChange={onChange}
          >
            {options.find((option) => option.name === 'Size') &&
              options
                .find((option) => option.name === 'Size')
                .values.map((size) => {
                  if (availableProductSizes.includes(size)) {
                    return <CommonSizePicker key={size} value={size} selectedValue={sizeRadio} />;
                  }
                  return (
                    <CommonSizePicker key={size} disabled value={size} selectedValue={sizeRadio} />
                  );
                })}
          </RadioGroup>
        </Box>
        <CommonButton
          label="Add to Cart"
          className={classes.addToCartButton}
          startIcon={<AddIcon />}
          variant="contained"
          isDisabled={
            isEmpty(currentProductVariant) || currentProductVariant.inventoryQuantity === 0
          }
          onClick={handleAddToCart}
        />
      </Box>
    </Paper>
  );
};

ProductPopup.defaultProps = {
  productInfo: {},
  currentProductVariant: {},
  availableProductSizes: [],
  showPopup: false,
};

ProductPopup.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  cardPosition: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleClick: PropTypes.func.isRequired,
  formData: PropTypes.oneOfType([PropTypes.object]).isRequired,
  currentProductVariant: PropTypes.oneOfType([PropTypes.object]),
  productInfo: PropTypes.oneOfType([PropTypes.object]),
  availableProductSizes: PropTypes.oneOfType([PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  currentProductImage: PropTypes.string.isRequired,
  showPopup: PropTypes.bool,
};

export default React.memo(withStyles(styles)(ProductPopup));
