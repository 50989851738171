const styles = (theme) => ({
  products: {
    minWidth: 120,
  },
  tableContainer: {
    overflowX: 'unset',
  },
});

export default styles;
