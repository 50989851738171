const styles = (theme) => ({
  root: {
    borderBottom: 'none',
    '&:first-child': {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    '&:last-child': {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  withBottomBorder: {
    '&:first-child': {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 5,
    },
    '&:last-child': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 5,
    },
  },
  withTopBorder: {
    '&:first-child': {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 0,
    },
    '&:last-child': {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 0,
    },
  },
  body: {
    color: 'inherit',
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    lineHeight: theme.typography.h6.lineHeight,
  },
  head: {
    color: theme.palette.black,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    lineHeight: theme.typography.h3.lineHeight,
  },
});

export default styles;
