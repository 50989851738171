import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ROUTES from 'routes';
import { UPDATE_USER_PROFILE } from 'modules/ProfilePage/components/Tabs/ProfileTab/actions';
import { getStringQuery } from 'helpers/getStringQuery';
import FavoritesPage from '../components/FavoritesPage';
import {
  GET_COMPANIES_BY_IDS,
  GET_COMPANY_PRODUCTS,
  UNFOLLOW_COMPANY,
  UPDATE_DEFAULT_FOLLOWS,
} from '../actions';

const FavoritesPageContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { _id, follows, isAuth } = useSelector((state) => state.user);
  const { companies, companyProducts, isLoading } = useSelector((state) => state.favorites);

  useEffect(() => {
    if (follows.length > 0) {
      dispatch(GET_COMPANIES_BY_IDS(getStringQuery(follows)));
    } else {
      dispatch(UPDATE_DEFAULT_FOLLOWS());
    }
  }, [dispatch, follows]);

  const getNextCompany = useCallback(() => {
    if (companies.length > Object.keys(companyProducts).length) {
      const filteredCompanies = companies.filter(
        (company) => !Object.keys(companyProducts).includes(company.shopifyId)
      );
      const nextItem = filteredCompanies.pop();

      dispatch(GET_COMPANY_PRODUCTS(nextItem && nextItem.shopifyId));
    }
  }, [companies, companyProducts, dispatch]);

  const handleClickProductCard = useCallback(
    (productName, productId) => {
      history.push(`${ROUTES.PRODUCT_PAGE}/${productName}-${productId}`);
    },
    [history]
  );

  const handleClickCompanyCard = useCallback(
    (companyId) => {
      history.push(`${ROUTES.COMPANY}/${companyId}`);
    },
    [history]
  );

  const handleFollowCompany = useCallback(
    (companyId) => {
      if (_id) {
        const companyObj = companies.find((company) => company._id === companyId);
        const newFollows = follows.slice();
        const companyIndex = newFollows.indexOf(companyId);

        newFollows.splice(companyIndex, 1);

        dispatch(UPDATE_USER_PROFILE({ userId: _id, follows: newFollows }));
        dispatch(UNFOLLOW_COMPANY({ id: companyObj._id, shopifyId: companyObj.shopifyId }));
      }
    },
    [follows, companies, dispatch, _id]
  );

  return (
    <FavoritesPage
      companies={companies}
      companyProducts={companyProducts}
      getNextCompany={getNextCompany}
      isLoading={isLoading}
      handleClickProductCard={handleClickProductCard}
      handleFollowCompany={handleFollowCompany}
      handleClickCompanyCard={handleClickCompanyCard}
      isAuth={isAuth}
    />
  );
};

export default React.memo(FavoritesPageContainer);
