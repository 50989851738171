const styles = (theme) => ({
  brandInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap-reverse',
      marginBottom: 3,
      '& .MuiTypography-body2': {
        width: '100%',
      },
    },
  },
  newPrice: {
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginBottom: 10,
      display: 'block',
      width: '100%',
    },
  },
  productPropertyContainer: {
    display: 'flex',
    marginTop: 10,
    '&:last-of-type': {
      alignItems: 'baseline',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 9,
    },
  },
  productPropertyTitle: {
    marginRight: 10,
  },
  removeButton: {
    marginLeft: 'auto',
    '& .MuiButton-startIcon': {
      marginBottom: 1,
      marginRight: 3,
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      right: 0,
      top: -10,
    },
  },
});

export default styles;
