import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Divider, Link, Typography, withStyles } from '@material-ui/core';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

import { validateBaseInput, validatePhone, validateEmail } from 'helpers/validations';
import CommonButton from 'commonComponents/CommonButton';
import { wpLinks } from 'constants/links';
import { STEPS } from '../../config';

import styles from './styles';

const CheckoutStepper = ({ classes, handleCloseModal }) => {
  const [activeStep, setActiveStep] = useState(0);

  const { state, address, city, zip, firstName, phone, lastName, email } = useSelector(
    (reduxState) => reduxState.checkout
  );

  const steps = useMemo(() => STEPS, []);

  const isValidForm = useMemo(
    () =>
      validateBaseInput(firstName) &&
      validateBaseInput(lastName) &&
      validateEmail(email) &&
      validatePhone(phone) &&
      validateBaseInput(state) &&
      validateBaseInput(zip) &&
      validateBaseInput(address) &&
      validateBaseInput(city),
    [state, address, city, zip, firstName, phone, lastName, email]
  );

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleSetActiveStep = useCallback((step) => {
    setActiveStep(step);
  }, []);

  const getStepContent = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return {
            ...steps[step],
            breadCrumbs: 'Cart > Contact',
            backButtonLabel: 'Return to Cart',
            nextButtonLabel: 'Continue to Shipping',
          };
        case 1:
          return {
            ...steps[step],
            breadCrumbs: 'Cart > Contact > Shipping',
            backButtonLabel: 'Return to Information',
            nextButtonLabel: 'Continue to Payment',
          };
        case 2:
          return {
            ...steps[step],
            breadCrumbs: 'Cart > Contact > Shipping > Payment',
            backButtonLabel: 'Return to Shipping',
            nextButtonLabel: 'Pay now',
          };
        default:
          return 'Unknown step';
      }
    },
    [steps]
  );

  return (
    <>
      <Typography variant="body1" color="primary" className={classes.breadcrumbs}>
        {getStepContent(activeStep).breadCrumbs}
      </Typography>
      <Typography variant="h2" color="primary" className={classes.breadcrumbs}>
        {getStepContent(activeStep).stepTitle}
      </Typography>
      {getStepContent(activeStep).Component(handleSetActiveStep, handleCloseModal)}
      <Box className={classes.actions}>
        <CommonButton
          startIcon={<ArrowBackIosRoundedIcon />}
          variant="text"
          label={getStepContent(activeStep).backButtonLabel}
          size="small"
          onClick={activeStep === 0 ? handleCloseModal : handleBack}
        />
        {activeStep !== steps.length - 1 && (
          <CommonButton
            variant="contained"
            color="secondary"
            label={getStepContent(activeStep).nextButtonLabel}
            className={classes.nextStepButton}
            isDisabled={!isValidForm}
            onClick={handleNext}
          />
        )}
      </Box>
      <Divider className={classes.divider} />
      <Box>
        <Typography variant="body2" display="inline" color="textPrimary">
          <Link target="_blank" href={wpLinks.privacyPolicy}>
            Privacy Policy
          </Link>
        </Typography>
        <Typography
          variant="body2"
          display="inline"
          color="textPrimary"
          className={classes.termsConditions}
        >
          <Link target="_blank" href={wpLinks.termsConditions}>
            Terms + Conditions
          </Link>
        </Typography>
      </Box>
    </>
  );
};

CheckoutStepper.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default React.memo(withStyles(styles)(CheckoutStepper));
