const styles = (theme) => ({
  breadcrumbContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  currentPage: {
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '19px',
  },
  emptyStub: {
    width: 70,
  },
});

export default styles;
