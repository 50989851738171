import { OrdersTabContainer, UserSettingsTabContainer } from './components/Tabs';

export const TAB_ROUTES = [
  {
    path: '/settings',
    title: 'Profile',
    Component: UserSettingsTabContainer,
  },
  {
    path: '/orders',
    title: 'Orders',
    Component: OrdersTabContainer,
  },
];
