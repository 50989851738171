const styles = () => ({
  clientListWrapper: {
    minHeight: 600,
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
});

export default styles;
