import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { withStyles } from '@material-ui/core/styles';
import { TableContainer, Table, TableBody, TableRow, TableHead } from '@material-ui/core';

import OrderRow from '../OrderRow';
import StyledCell from '../StyledCell';

import styles from './styles.js';

const OrdersTable = ({ classes, ordersList }) => {
  return (
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <StyledCell>Status</StyledCell>
            <StyledCell>Date</StyledCell>
            <StyledCell>Order Information</StyledCell>
            <StyledCell>Brand Information</StyledCell>
            <StyledCell style={classes.products}>Products</StyledCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ordersList.map((order) => (
            <OrderRow key={uuid()} order={order} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

OrdersTable.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.object]).isRequired,
  ordersList: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default React.memo(withStyles(styles)(OrdersTable));
