import { takeEvery, put, select } from 'redux-saga/effects';

import * as selectors from './selectors';
import * as actions from '../actions';
import { requestQueryCreatorFromCart } from '../utils/requestQueryCreatorFromCart';

function* synchronizeCartItemsWorker() {
  try {
    const orderList = yield select(selectors.orderList);
    const requestQuery = requestQueryCreatorFromCart(orderList);

    yield put(actions.SYNCHRONIZE_CART_ITEMS(requestQuery));
  } catch (error) {
    console.log(error);
  }
}

function* synchronizeCartItemsWatcher() {
  yield takeEvery(
    [actions.CHANGE_ITEM_QUANTITY, actions.REMOVE_FROM_CART, actions.ADD_TO_CART],
    synchronizeCartItemsWorker
  );
}

export default synchronizeCartItemsWatcher;
