import { handleActions, combineActions } from 'redux-actions';
import { camelCase } from 'lodash';

import * as actions from '../actions/index';
import { CREATE_PAYMENT_INTENT_SUCCESS } from '../../CheckoutModule/actions';

const defaultState = {
  orderList: {},
  isLoading: false,
  errors: '',
};

const cart = handleActions(
  {
    [actions.ADD_TO_CART]: (state, { payload }) => {
      const orderListCopy = { ...state.orderList };
      const transformedVendorName = camelCase(payload.vendor);
      const isVendorExists = !!orderListCopy[transformedVendorName];

      if (!isVendorExists) {
        orderListCopy[transformedVendorName] = {
          products: [payload],
          storeId: payload.storeId,
          stripeId: payload.companyStripeId,
        };
      } else {
        const existedVendorProducts = [...orderListCopy[transformedVendorName].products];

        const existedProductVariant = existedVendorProducts.find(
          (existedProduct) =>
            existedProduct.productId === payload.productId &&
            existedProduct.variantId === payload.variantId
        );

        if (existedProductVariant) {
          existedProductVariant.quantity += 1;

          orderListCopy[transformedVendorName].products = [...existedVendorProducts];

          return {
            ...state,
            orderList: orderListCopy,
          };
        }

        orderListCopy[transformedVendorName].products = [...existedVendorProducts, payload];
      }

      return {
        ...state,
        orderList: orderListCopy,
      };
    },
    [actions.REMOVE_FROM_CART]: (state, { payload = {} }) => {
      const { variantId, vendor } = payload;

      const orderListCopy = { ...state.orderList };

      const foundVendorProducts = orderListCopy[vendor].products;

      if (foundVendorProducts.length === 1) {
        delete orderListCopy[vendor];

        return {
          ...state,
          orderList: orderListCopy,
        };
      }

      const itemIndexToRemove = foundVendorProducts.findIndex(
        (product) => product.variantId === variantId
      );

      foundVendorProducts.splice(itemIndexToRemove, 1);

      return {
        ...state,
        orderList: orderListCopy,
      };
    },
    [actions.CHANGE_ITEM_QUANTITY]: (state, { payload = {} }) => {
      const { variantId, value, vendor } = payload;

      const orderListCopy = { ...state.orderList };

      const foundVendorProducts = orderListCopy[vendor].products;

      foundVendorProducts.find((product) => product.variantId === variantId).quantity = value;

      return {
        ...state,
        orderList: orderListCopy,
      };
    },
    [combineActions(actions.SYNCHRONIZE_CART_ITEMS, actions.SYNCHRONIZE_COMPANY_INFO)]: (
      state
    ) => ({
      ...state,
      isLoading: true,
    }),
    [actions.SYNCHRONIZE_CART_ITEMS_SUCCESS]: (state, { payload }) => {
      const actualProductInfo = payload.response.data;

      const orderListCopy = { ...state.orderList };

      const arrayedOrderList = Object.entries(orderListCopy);

      let errors = '';

      arrayedOrderList.forEach((orderListSubArray) => {
        orderListSubArray[1].products = orderListSubArray[1].products
          .filter(({ variantId }) => actualProductInfo.some(({ id }) => id === variantId))
          .map((product) => {
            const foundProduct = actualProductInfo.find(
              (latestProductInfo) => product.variantId === latestProductInfo.id
            );

            if (foundProduct.inventoryQuantity < product.quantity) {
              errors = 'Selected quantity is not available.';
            }

            return {
              ...product,
              price: foundProduct.mortarPrice || foundProduct.marketPrice,
              disabled: foundProduct.inventoryQuantity < product.quantity,
            };
          });
      });

      const filteredOrderList = arrayedOrderList.filter(
        (orderList) => orderList[1].products.length > 0
      );

      return {
        ...state,
        errors,
        isLoading: false,
        orderList: Object.fromEntries(filteredOrderList),
      };
    },
    [actions.SYNCHRONIZE_COMPANY_INFO_SUCCESS]: (state, { payload }) => {
      const actualCompaniesInfo = payload.response;

      const orderListCopy = { ...state.orderList };

      const arrayedOrderList = Object.entries(orderListCopy);

      arrayedOrderList.forEach((orderListSubArray) => {
        const companyInfo = actualCompaniesInfo.find(
          ({ shopifyId }) => orderListSubArray[1].storeId === shopifyId
        );

        orderListSubArray[1].serviceLinks = companyInfo.serviceLinks;
        orderListSubArray[1].contacts = companyInfo.contacts;
        orderListSubArray[1].companyName = companyInfo.title;
      });

      return {
        ...state,
        isLoading: false,
        orderList: Object.fromEntries(arrayedOrderList),
      };
    },
    [combineActions(actions.SYNCHRONIZE_CART_ITEMS_FAIL, actions.SYNCHRONIZE_COMPANY_INFO_FAIL)]: (
      state,
      { payload }
    ) => ({
      ...state,
      isLoading: false,
      errors: payload.response,
    }),
    [CREATE_PAYMENT_INTENT_SUCCESS]: (state) => ({
      ...state,
      orderList: {},
    }),
    [actions.CLEAR_CART_ERRORS]: (state) => ({
      ...state,
      errors: '',
    }),
  },
  defaultState
);

export default cart;
